import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }
  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    'requestType',
    'requestCategory',
    'sourceSystem',
    'location',
    'dateType',
    'dateFrom',
    'dateTo'
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const initialFilterState = {
  requestType: null,
  requestCategory: null,
  sourceSystem: null,
  location: null,
  dateType: null,
  dateFrom: '',
  dateTo: '',
  managedBy: null
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'RequestID',
  sortDirection: SortDirection.Desc,
};

const switchMode = 'myWork';
const hover = '';

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  isAlmostPast: false,
  stats: [],
  requests: [],
  myWorkRequests: {},
  myWorkActions: [],
  requestsTableSearch: [],
  totalTableRequestCount: 0,
  totalRequestCount: 0,
  dashboardFilterData: [],
  barGraphData: [],
  pieChartData: [],
  statsPastDue: '',
  statsPendingApproval: 0,
  statsActionsCompleted: 0,
  totalFilterCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  page: 1,
  sort: null,
  isFilterVisible: false,
  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,
  isSavedFilterLoaded: false,
  savedFilterList: [],
  loadingFilterSettingtatus: LoadingStatus.Idle,
  switch: switchMode,
  statsHover: hover,
  currentStatusID: 0,
  pendingApprovalByMe: 0,
  totalRecords: [],
};

const dashboardSlice = createSlice({
  name: 'dashboardSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      state.filter[action.payload.propName] = action.payload.propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchRequest(state, action) {
      state.requests = action.payload.requestsSearch;
      state.totalRequestCount = action.payload.totalRequestCount;
    },
    searchMyWorkRequest(state, action) {
      state.myWorkRequests = action.payload.requestsSearch;
      state.myWorkActions = action.payload.myWorkActions;
    },
    searchPastDue(state, action) {
      state.statsPastDue = action.payload;
    },
    searchPendingApproval(state, action) {
      state.statsPendingApproval = action.payload;
    },
    searchActionsCompleted(state, action) {
      state.statsActionsCompleted = action.payload;
    },
    searchTableRequest(state, action) {
      state.requestsTableSearch = action.payload.requestsTableSearch;
      state.totalTableRequestCount = action.payload.totalTableRequestCount;
    },
    dashboardSearchFilter(state, action) {
      state.dashboardFilterData = action.payload;
    },
    switchMode(state, action) {
      state.switch = action.payload;
    },
    updateMyWorkAction(state, action) {
      const requestAction = action.payload;
      const { RequestActionID } = requestAction;

      const requestActionIndex = state.myWorkActions?.findIndex(
        (x) => x.RequestActionID === RequestActionID
      );
      state.myWorkActions?.splice(requestActionIndex, 1, requestAction);
    },
    updateActionComment(state, action) {
      const newComment = action.payload;
      const cardID = newComment.RecordID;

      const requestActionIndex = state.myWorkActions?.findIndex(
        (x) => x.RequestActionID === cardID
      );
      const requestAction = state.myWorkActions?.filter((x) => x.RequestActionID === cardID);
      requestAction.Comments.unshift(newComment);

      state.myWorkActions[requestActionIndex].Comments = requestAction.Comments;
    },

    updateHover(state, action) {
      state.statsHover = action.payload;
    },

    updateFilterShowHideSettings(state, action) {
      state.dashboardFilterData = action.payload;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingtatus = action.payload;
    },
    getBarGraphData(state, action) {
      state.barGraphData = action.payload;
    },
    getPieChartData(state, action) {
      state.pieChartData = action.payload.pieChartSearch;
    },
    updateCurrentStatus(state, action) {
      state.currentStatusID = action.payload;
    },
    updateIsAlmostPast(state, action) {
      state.isAlmostPast = action.payload;
    },
    updatePendingApprovalMe(state, action) {
      state.pendingApprovalByMe = action.payload;
    },
    setAllRecords(state, action) {
      state.totalRecords = action.payload;
    },
  },
});

export const filterFields = {
  requestType: 'requestType',
  requestCategory: 'requestCategory',
  sourceSystem: 'sourceSystem',
  location: 'location',
  dateType: 'dateType',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  managedBy: 'managedBy'
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const dashboardSearchActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
