import React, { useRef, useState } from 'react';
import {
  Box, Button, Dialog, DialogContent,
  DialogTitle, Divider, Grid, IconButton, TextField, Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DIALOG_RESPONSE, MODAL_TYPES, useGlobalDialogContext } from './GlobalDialog';
import InfoIcon from '@mui/icons-material/Info';
import IF from '../IF';

const ConfirmationDialog = ({
  isOpen,
  title,
  message,
  subMessage,
  modalType,
  maxWidth = 'xs',
  showCancel = false,
  responseCallback,
  isReasonRequired = false,
}) => {
  const { t } = useTranslation();
  const reasonRef = useRef();
  const { hideModal } = useGlobalDialogContext();

  const [err, setErr] = useState({ error: false, errMsg: '' });

  const closeDialog = () => {
    hideModal();
  };

  const dialogResponseHandler = async (response) => {
    if (response === 'YES' && isReasonRequired) {
      if (reasonRef.current.value) {
        setErr({ error: false, errMsg: null });
        if (responseCallback) {
          await responseCallback(response, reasonRef.current.value);
        }
        hideModal();
      } else {
        setErr({ error: true, errMsg: 'Comment is required' });
      }
    } else {
      const willCloseDialog = responseCallback ? (await responseCallback(response)) : true;
      if (willCloseDialog !== false) {
        hideModal();
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth={maxWidth}
    >
      <DialogTitle>
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Typography color="textPrimary" variant="h6">
            {title}
          </Typography>
          <IconButton size="small" onClick={closeDialog}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row' }}>
        <Grid container>
          <Grid item xs={4} md={3} lg={2}>
            <IF condition={modalType === MODAL_TYPES.CONFIRM}>
              <HelpOutlineIcon color="action" fontSize="large" sx={{ height: '70px', width: '70px' }} />
            </IF>

            <IF condition={modalType !== MODAL_TYPES.CONFIRM}>
              <InfoIcon color="action" fontSize="large" sx={{ height: '70px', width: '70px' }} />
            </IF>

          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <IF condition={!!message}>
              <Typography color="textSecondary" variant="subtitle2">
                {message}
              </Typography>
            </IF>
            <IF condition={!!subMessage}>
              <Typography color="textSecondary" variant="subtitle2">
                {subMessage}
              </Typography>
            </IF>
          </Grid>
          <IF condition={isReasonRequired}>
            <Grid item sm={12} md={12}>
              <TextField
                inputRef={reasonRef}
                fullWidth
                multiline
                rows={2}
                label="Comment*"
                placeholder="Comment*"
                error={err.error}
                helperText={err.errMsg}
              />
            </Grid>
          </IF>
        </Grid>
      </DialogContent>
      <Divider />
      <Box p={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <IF condition={modalType === MODAL_TYPES.CONFIRM}>
          <Button
            size="small"
            variant="contained"
            sx={{ mr: 0.5 }}
            onClick={() => dialogResponseHandler(DIALOG_RESPONSE.YES)}
          >
            {t('Btn_Yes')}
          </Button>
          <Button
            size="small"
            variant="text"
            sx={{ mr: 0.5 }}
            onClick={() => dialogResponseHandler(DIALOG_RESPONSE.NO)}
          >
            {t('Btn_No')}
          </Button>
        </IF>

        <IF condition={modalType === MODAL_TYPES.INFO}>
          <Button
            size="small"
            variant="contained"
            sx={{ mr: 0.5 }}
            onClick={() => dialogResponseHandler(DIALOG_RESPONSE.NO)}
          >
            {t('Btn_Ok')}
          </Button>
        </IF>

        <IF condition={showCancel}>
          <Button
            size="small"
            variant="text"
            onClick={closeDialog}
          >
            {t('Btn_Cancel')}
          </Button>
        </IF>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
