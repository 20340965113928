const frTranslation = {
  NewUser: 'Nouvel utilisateur',
  Search: 'Chercher',
  All: 'Tout',
  News: 'Nouvelles',
  Overview: 'Aperçu',
  Groups: 'Groupes',
  Header_Groups: 'Groupes',
  Header_AddGroup: 'Ajouter des groupes',
  Header_EditGroup: 'Modifier les groupes',
  Header_SampleSearch: 'Exemple de recherche',
  Header_SampleDetails: "Détails de l'échantillon",
  Header_SampleInfo: "Informations sur l'échantillon",
  Header_JobInfo: "Informations sur l'emploi",
  Header_InPrep: 'EN PREP',
  Header_InTransit: 'EN TRANSIT',
  Header_InAnalysis: 'EN ANALYSE',
  Header_JobAge: 'ÂGE DU TRAVAIL',
  Header_SampleAge: "ÂGE DE L'ÉCHANTILLON",
  Header_In: 'DANS',
  Header_Out: 'DEHORS',
  Header_RecentNews: 'Nouvelles récentes',
  Header_JobProgress: 'Avancement du travail',
  Header_UserSearch: 'Utilisateurs',
  Header_Roles: 'Rôles',
  Header_NewsSearch: "Recherche d'actualités",
  Header_DocumentSearch: 'Recherche de documents',
  Header_Document: 'Document',
  Header_AddDocument: 'Ajouter un nouveau document',
  Header_EditDocument: 'Modifier le document',
  Header_News: 'Nouvelles',
  Header_GeneralInfo: 'Général',
  Header_Access: 'Accéder',
  Header_Info: 'Info',
  Header_RoleAndAccess: 'Rôle et accès',
  Header_RequestSearch: 'Demande de recherche',
  Header_Documents: 'Documents',
  Header_ReportSearch: 'Recherche de rapport',
  Header_RootCauseSearch: 'Recherche de cause première',
  Header_AddRootCause: 'Ajouter une cause racine',
  Header_EditRootCause: 'Modifier la cause première',
  Header_Locations: 'Emplacements',
  Header_AddLocation: 'Ajouter un emplacement',
  Header_EditLocation: "Modifier l'emplacement",
  Header_RegionSearch: 'Recherche de région',
  Header_Regions: 'Régions',
  Header_AddRegion: 'Ajouter une région',
  Header_EditRegion: 'Modifier la région',
  Header_IssueTypes: 'Types de problèmes',
  Header_AddIssueType: 'Ajouter un type de problème',
  Header_EditIssueType: 'Modifier le type de problème',
  Header_Countries: 'Des pays',
  Header_AddObjectConfiguration: "Ajouter des configurations d'objet",
  Header_EditObjectConfiguration: "Modifier la configuration de l'objet",
  Header_AddCountry: 'Ajouter des pays',
  Header_EditCountry: 'Modifier les pays',
  Header_RootCauseCategories: 'Catégories de causes profondes',
  Header_AddRootCauseCategorySearch:
        'Ajouter une recherche de catégorie de cause première',
  Header_EditRootCauseCategory: 'Modifier la catégorie de cause première',
  Header_AddRootCauseCategory: 'Ajouter une catégorie de cause première',
  Header_RequestCategorySourceSystemMapping:
        'Demander la source de la catégorie',
  Header_AddRequestCategorySourceSystemMapping:
        'Ajouter une source de catégorie de demande',
  Header_EditRequestCategorySourceSystemMapping:
        'Modifier la source de la catégorie de demande',
  Header_EmailSearch: 'Recherche par e-mail',
  Header_EditRequestCategory: 'Modifier la catégorie de demande',
  Header_AddMap: 'Ajouter un mappage',
  Header_EditMap: 'Modifier le mappage',
  Header_AddRiskRanking: 'Ajouter un classement des risques',
  Header_EditRiskRanking: 'Modifier le classement des risques',
  Header_EditRequest: 'Modifier la demande',
  Header_AddRole: 'Ajouter un rôle',
  Header_EditRole: 'Modifier le rôle',
  Header_RootCauses: 'Causes profondes',
  Header_ShowingRequests: 'Affichage de la ou des requêtes',
  Header_ShowingNRequests: 'Affichage de {{n}} demande(s)',
  Header_ShowingNActions: 'Affichage de {{n}} action(s)',
  Breadcrumb_ObjectConfigurations: "Configuration d'objet",
  Breadcrumb_Groups: 'Groupes',

  AppTitlePrefix: 'iEval',

  Nav_Menu_Home: 'Maison',
  Nav_Menu_Dashboard: 'Tableau de bord',
  Nav_Menu_Requests: 'Demandes',
  Nav_Menu_Create_Request: 'Créer des demandes',
  Nav_Menu_Sent_Emails: 'E-mails envoyés',
  Nav_Menu_Verification: 'Vérification',
  Nav_Menu_Ation_Center: "Centre d'action",
  Nav_Menu_Actions: 'Actions',
  Nav_Menu_List: 'Liste',
  Nav_Menu_My_Actions: 'Mes actions',
  Nav_Menu_Master_Data: 'Données de base',
  Nav_Menu_Companies: 'Entreprises',
  Nav_Menu_Countries: 'Des pays',
  Nav_Menu_Issue_Types: 'Types de problèmes',
  Nav_Menu_Locations: 'Emplacements',
  Nav_Menu_Probability_Rankings: 'Classements de probabilité',
  Nav_Menu_Regions: 'Régions',
  Nav_Menu_Request_Categories: 'Catégories de demande',
  Nav_Menu_Request_Category_Source: 'Demander la source de la catégorie',
  Nav_Menu_Risk_Rankings: 'Classement des risques',
  Nav_Menu_Root_Causes: 'Causes profondes',
  Nav_Menu_Root_Cause_Categories: 'Catégories de causes profondes',
  Nav_Menu_Severity_Rankings: 'Classements de gravité',
  Nav_Menu_Administrator: 'Administrateur',
  Nav_Menu_Request_Workflow: 'Flux de travail de demande',
  Nav_Menu_Roles: 'Les rôles',
  Nav_Menu_Users: 'Utilisateurs',
  Nav_Menu_ObjectConfiguration: 'Configuration d objet',

  Breadcrumb_Regions: 'Régions',
  Breadcrumb_UserManagement: 'Gestion des utilisateurs',
  Breadcrumb_EmailSearch: 'Recherche par e-mail',
  Breadcrumb_RiskRanking: 'Classement des risques',
  Breadcrumb_Home: 'Maison',
  Breadcrumb_IssueTypes: 'Types de problèmes',
  Breadcrumb_RequestCategorySourceSystemMapping:
        'Demander la source de la catégorie',
  Breadcrumb_RequestManagement: ' Gestion des demandes',
  Breadcrumb_SentEmails: 'E-mails envoyés',
  Header_SentEmails: 'E-mails envoyés',
  Breadcrumb_Role: 'Rôle',
  Breadcrumb_RootCauses: 'Causes profondes',
  Breadcrumb_MyActions: 'Mes actions',
  Text_AddGroup: 'Ajouter un groupe',
  Text_EditGroup: 'Modifier le groupe',
  Text_AddRegion: 'Ajouter une région',
  Text_EditRegion: 'Modifier la région',
  Text_AddUser: 'Ajouter un utilisateur',
  Text_EditUser: "Modifier l'utilisateur",
  Text_RegionID: 'ID de région',
  Text_RegionName: 'Nom de la région',
  Text_Region: 'Région',
  Text_Countries: 'Des pays',
  Text_AddIssueType: 'Ajouter un type de problème',
  Text_EditIssueType: 'Modifier le type de problème',
  Text_IssueTypeID: 'Identifiant du type de problème',
  Text_IssueType: 'Type de probleme',
  Text_Description: 'La description',
  Text_Active: 'Actif',
  Text_UserName: "Nom d'utilisateur",
  Text_FirstName: 'Prénom',
  Text_LastName: 'Nom de famille',
  Text_CreatedBy: 'Créé par',
  Text_CreatedOn: 'Créé sur',
  Text_UpdatedBy: 'Mis à jour par',
  Text_UpdatedOn: 'Mis à jour le',
  Text_SentOn: 'Envoyé sur',
  Text_CountryName: 'Nom du pays',
  Text_IssueName: 'Nom du problème',
  Text_IssueDescription: 'Description du problème',
  Text_IssueCategory: 'Catégorie de problème',
  Text_DueDays: "Jours d'échéance",
  Text_SourceFilter: 'Filtre source',
  Text_RoleName: 'Nom de rôle',
  Text_Status: 'Statut',
  Text_Request: 'Demande',
  Text_RequestTitle: 'Titre de la demande',
  Text_EmailSubject: 'Sujet du courriel',
  Text_LocationAbbreviation: "Abréviation d'emplacement",
  Text_ID: 'IDENTIFIANT',
  Text_RequestManager: 'Gestionnaire de demandes',
  Text_AddCompanies: 'Ajouter des entreprises',
  Text_EditCompanies: 'Modifier les sociétés',
  Text_RequestCategory: 'Catégorie de demande',
  Text_Country: 'Pays',
  Table_NoRegionfound: 'Aucune région trouvée!',
  Table_NoUserfound: 'Aucun utilisateur trouvé!',
  Table_NoIssueTypefound: 'Aucun type de problème trouvé!',
  Table_NoItemsFound: 'Aucun élément trouvé!',
  Text_RequestCategoryID: 'ID de catégorie de demande',
  Text_SourceSystem: 'Système source',
  Text_EditCountries: 'Modifier les pays',
  Text_AddCountries: 'Ajouter des pays',
  Table_NoCountryFound: 'Aucun pays trouvé!',
  Text_AddRootCauseCategory: 'Ajouter une catégorie de cause première',
  Text_RootCauseCategoryManagement:
        'Gestion des catégories de causes profondes',
  Text_RootCauseCategoryID: 'ID de catégorie de cause première',
  Text_RootCauseCategory: 'Catégorie de cause première',
  Text_AddRequestCategorySourceSystemMapping:
        'Ajouter une source de catégorie de demande',
  Text_EditRequestCategorySourceSystemMapping:
        'Modifier la source de la catégorie de demande',
  Text_Email: 'E-mail',
  Text_ManagerType: 'Type de gestionnaire',
  Text_DropFileFromMachine: 'Déposez le <1>Parcourir</1> de fichiers sur votre ordinateur.',
  Text_SelectFile: 'Sélectionnez le fichier{{text}}',
  Text_FileMaxSize: 'Taille de fichier maximale : {{fileSize}}',
  Table_NoRequestCategoryfound: 'Aucune catégorie de demande trouvée!',
  Text_ActionMissingOwner: "L'action {{requestActionID}} manque au propriétaire",
  Text_ActionMissingDueDate: "L'action {{requestActionID}} n'a pas de date d'échéance",
  Text_EmailID: 'Identifiant de messagerie',
  Text_SentTo: 'Envoyé à',
  Text_EmailSubjectKeyword: "Mot-clé de l'objet de l'e-mail",
  Text_RequestNumber: 'Numéro de demande',
  Text_Location: 'Emplacement',
  Text_EmailType: "Type d'e-mail",
  Text_RequestTitleKeyword: 'Demander le mot-clé du titre',
  Text_RequestStatus: 'État de la demande',
  Text_RequestManagingGroup: 'Groupe de gestion des demandes',
  Text_SentBy: 'Envoyée par',
  Text_DateType: 'Type de dates',
  Text_From: 'De',
  Text_To: 'À',
  Text_AddRiskRanking: 'Ajouter un classement des risques',
  Text_EditRiskRanking: 'Modifier le classement des risques',
  Text_RiskID: 'ID de risque',
  Text_EditRequest: 'Modifier la demande',
  Text_RiskRanking: 'Classement des risques',
  Text_Probability: 'Probabilité',
  Text_Severity: 'Gravité',
  Table_NoRiskfound: 'Aucun risque trouvé !',
  Table_NoEmailfound: 'Aucun e-mail trouvé !',
  Text_No_Items: "Pas d'objet !",
  Text_NoRequestFound: 'Aucune demande trouvée!',
  Text_NoColumsFound: 'Aucune colonne trouvée!',
  Text_LoadingComponent: 'Composant de chargement',
  Header_RootCauseNameManagement: 'Gestion des noms de cause première',
  Header_AddRootCauseSearch: 'Ajouter une recherche de cause racine',
  Header_EditRootCauseSearch: 'Modifier la recherche de la cause première',
  Header_AddRootCauseName: 'Ajouter le nom de la cause racine',
  Header_EditRootCauseName: 'Modifier le nom de la cause première',
  Header_RequestCategories: 'Catégories de demande',
  Header_AddRequestCategory: 'Ajouter une catégorie de demande',
  Text_AddRootCauseName: 'Ajouter le nom de la cause racine',
  Text_RootCauseNameSearch: 'Recherche de nom de cause racine',
  Text_EditRootCauseName: 'Modifier le nom de la cause première',
  Text_RootCauseNameID: 'ID du nom de la cause première',
  Text_RootCauseName: 'Nom de la cause première',
  Header_AddProbability: 'Ajouter un classement de probabilité',
  Header_EditProbability: 'Modifier le classement de probabilité',
  Header_ProbabilityRanking: 'Classements de probabilité',
  Header_SeverityRanking: 'Classements de gravité',
  Header_AddSeverity: 'Ajouter la gravité',
  Header_EditSeverity: 'Modifier la gravité',
  Header_CreateUser: 'Créer un utilisateur',
  Header_EditUser: "Modifier l'utilisateur",
  Info_Message_ExportSuccess: "Succès de l'exportation",
  Text_SeverityID: 'ID de gravité',
  Text_SeverityName: 'Nom de gravité',
  Text_AddSeverity: 'Ajouter la gravité',
  Text_EditSeverity: 'Modifier la gravité',
  Text_EditRequestCategory: 'Modifier la catégorie de demande',
  Text_EditRisk: 'Modifier le risque',
  Text_EditRootCauseCategory: 'Modifier la catégorie de cause racine',
  Text_AddRootCauses: 'Ajouter la cause première',
  Tooltip_AddGroup: 'iEval | Ajouter un groupe',
  Tooltip_EditGroup: 'iEval | Modifier le groupe',
  Tooltip_UserLDAPSearch: "Cliquez sur l'icône pour ouvrir la recherche LDAP",
  Tooltip_AddObjectConfiguration: 'Ajouter un objet Configuration',
  Tooltip_EditObjectConfiguration: "Modifier la configuration de l'objet",
  Tooltip_SeverityRanking: 'iEval | Classements de gravité',
  Tooltip_AddSeverity: 'iEval | Ajouter la gravité',
  Tooltip_EditSeverity: 'iEval | Modifier la gravité',
  Tooltip_IssueTypes: 'iEval | Types de problèmes',
  Tooltip_AddIssueType: 'iEval | Ajouter un type de problème',
  Tooltip_EditIssueType: 'iEval | Modifier le type de problème',
  Tooltip_RootCauseCategories: 'iEval | Catégories de causes profondes',
  Tooltip_AddRootCauseCategory:
        'iEval | Ajouter une catégorie de cause première',
  Tooltip_EditRootCauseCategory:
        'iEval | Modifier la catégorie de cause première',
  Tooltip_Countries: 'iEval | Des pays',
  Tooltip_AddCountry: 'iEval | Ajouter des pays',
  Tooltip_EditCountry: 'iEval | Modifier les pays',
  Tooltip_RequestCategorySourceSystemMapping:
        'iEval | Demander la source de la catégorie',
  Tooltip_AddRequestCategorySourceSystemMapping:
        'Ajouter une source de catégorie de demande',
  Tooltip_EditRequestCategorySourceSystemMapping:
        'Modifier la source de la catégorie de demande',
  Tooltip_SentEmails: 'iEval | E-mails envoyés',
  Tooltip_EmailSearch: 'iEval | Recherche par e-mail',
  Tooltip_RequestCategories: 'iEval | Catégories de demande',
  Tooltip_AddMap: 'iEval | Ajouter une carte',
  Tooltip_EditMap: 'iEval | Modifier le mappage',
  Tooltip_RiskRanking: 'iEval | Classement des risques',
  Tooltip_AddRiskRanking: 'iEval | Ajouter un classement des risques',
  Tooltip_EditRiskRanking: 'iEval | Modifier le classement des risques',
  Tooltip_EditRootCauses: 'iEval | Modifier la cause première',
  Tooltip_AddRootCauses: 'iEval | Ajouter la cause première',
  Tooltip_AddRole: 'Ajouter un rôle',
  Tooltip_EditRole: 'Modifier le rôle',
  Tooltip_RootCauses: 'iEval | Causes profondes',
  Table_NoSeverityfound: 'Aucune gravité trouvée !',
  Header_Companies: 'Entreprises',
  Header_AddCompany: 'Ajouter une entreprise',
  Header_EditCompany: "Modifier l'entreprise",
  Header_MappingManagement: 'Demander la gestion du flux de travail',
  Header_AddMapping: 'Ajouter un mappage',
  Header_EditMapping: 'Modifier le mappage',
  Header_RiskRanking: 'Classement des risques',
  Header_AddRootCauses: 'Ajouter la cause première',
  Header_EditRootCauses: 'Modifier la cause première',
  Header_Action_Column_New: 'To do',
  Header_Action_Column_Created: 'Établie',
  Header_Action_Column_Started: 'En cours',
  Header_Action_Column_InProgress: 'En cours',
  Header_Action_Column_Completed: 'Completed',
  Header_MyActions: 'Mes actions',
  Header_Attachments: 'Pièces jointes',
  Header_Actions: 'Actions',
  Label_JobStatus: 'Statut du travail',
  Label_SampleName: "Nom de l'échantillon",
  Label_SampleDescription: "Description de l'échantillon",
  Label_SampleSource: "Source de l'échantillon",
  Label_SamplingDateFrom: "Date d'échantillonnage du",
  Label_SamplingDateTo: "Date d'échantillonnage au",
  Label_DateCreatedFrom: 'Date de création',
  Label_DateCreatedTo: 'Date de création',
  Label_SampleStatus: "État de l'échantillon",
  Label_Client: 'Client',
  Label_Project: 'Projet',
  Label_Name: 'Nom',
  Label_Job: 'Emploi',
  Label_AvgDays: 'Jours moyens',
  Label_Last3Months: '3 derniers mois',
  Label_Jobs: 'Travaux',
  Label_Samples: 'Échantillons',
  Label_TurnaroundTime: "Délai d'exécution",
  Label_Received: 'Reçu',
  Label_PrepStarted: 'La préparation a commencé',
  Label_InAnalysis: 'En Analyse',
  Label_Due: 'Exigible',
  Label_Reported: 'Signalé',
  Label_Invoiced: 'Facturé',
  Label_RoleName: 'Nom de rôle',
  Label_Active: 'Actif',
  Label_Display: 'Affichage',
  Label_Title: 'Titre',
  Label_Zone: 'Zone',
  Label_DocumentType: 'Type de document',
  Label_Role: 'Rôle',
  Label_Roles: 'Les rôles',
  Label_AssignedLocations: 'Emplacements attribués',
  Label_AssignedDivisions: 'Divisions assignées',
  Label_SourceSystems: 'Systèmes sources',
  Label_Description: 'La description',
  Label_Summary: 'Sommaire',
  Label_ActionTobeimplemented: 'Action à mettre en œuvre',
  Label_CommentAction_Implemented: 'Commentaire/Action mise en œuvre',
  Label_Details: 'Détails',
  Label_User: 'Utilisateur',
  Label_ImpersonateUser: "Usurper l'identité de l'utilisateur",
  Label_Sort: 'Trier',
  Label_Direction: 'Direction',
  Label_FromAToZ: 'De A à Z',
  Label_FromZToA: 'De Z à A',
  Label_ReportName: 'Nom du rapport',
  Label_JobNumber: 'Numéro de tâche',
  Label_DateGeneratedFrom: 'Date de génération à partir de',
  Label_DateGeneratedTo: 'Date de création',
  Label_JobName: 'Nom du travail',
  Label_ReportType: 'Type de rapport',
  Label_LocationName: 'Nom de la localisation',
  Label_LocationCode: "Le code d'emplacement",
  Label_LocationType: 'Type de lieu',
  Label_LocationAlias: "Alias d'emplacement",
  Label_Region: 'Région',
  Label_RegionName: 'Nom de la région',
  Label_Regions: 'Régions',
  Label_ProbabilityName: 'Nom de probabilité',
  Label_Probability: 'Probabilité',
  Label_CountryName: 'Nom du pays',
  Label_CountryCode: 'Code postal',
  Label_Tenant: 'Locataire',
  Label_State: 'État',
  Label_City: 'Ville',
  Label_PostalCode: 'code postal',
  Label_AddressLine2: 'Adresse Ligne 2',
  Label_AddressLine1: 'Adresse 1',
  Label_SentTo: 'Envoyé à',
  Label_EmailSubjectKeyword: "Mot-clé de l'objet de l'e-mail",
  Label_EmailType: "Type d'e-mail",
  Label_SentBy: 'Envoyée par',
  Label_DateType: 'Type de dates',
  Label_From: 'De',
  Label_To: 'À',
  Label_Trigger: 'Gâchette',
  Label_Frequency: 'La fréquence',
  Label_DayCount: 'Nombre de jours',
  Label_DayType: 'Type de jour',
  Label_ScheduleType: "Type d'horaire",
  Label_RegionCode: 'Indicatif régional',
  Label_Alias: 'Alias',
  Label_LocationAbbreviation: "Abréviation d'emplacement",
  Label_Timezone: 'Fuseau horaire',
  Label_UserName: "Nom d'utilisateur",
  Label_FirstName: 'Prénom',
  Label_LastName: 'Nom de famille',
  Label_Email: 'E-mail',
  Label_Edit: 'modifier',
  Label_Phone: 'Téléphoner',
  Label_RequestCategory: 'Catégorie de demande',
  Label_Functionality: 'Fonctionnalité',
  Label_CustomFunction: 'Fonction personnalisée',
  Label_CustomFunctionality: 'Fonctionnalité personnalisée',
  Label_JobProgress: 'Avancement du travail',
  Label_Add: 'Ajouter',
  Label_RootCauses: 'Causes profondes',
  Label_Workflow: 'Flux de travail',
  Label_Reminder: 'Rappels',
  Label_ToRequestMangerUponAutoRequestcreation:
        'Au gestionnaire de demandes lors de la création de la demande automatique',
  Label_ToRequestManageruponRequestManagerchange:
        "Au gestionnaire de demandes lors d'un changement de gestionnaire de demandes",
  Label_ToActionOwneruponActioncreation:
        "Au propriétaire de l'action lors de la création de l'action",
  Label_ToRequestApproveruponRequestsubmittedforApproval:
        "Pour demander un approbateur lors d'une demande soumise pour approbation",
  Label_ToRequestMangeruponRequestbeingRejected:
        'Au gestionnaire de demandes lorsque la demande est rejetée',
  Label_ToRequestMangeruponRequestbeingApproved:
        "Au gestionnaire de demandes lors de l'approbation de la demande",
  Label_ToRequestMangeruponVerificationsbeingcreated:
        'Pour demander le gestionnaire lors de la création des vérifications',
  Label_ToVerificationOwneruponVerificationbeingassigned:
        "Au propriétaire de la vérification lors de l'attribution de la vérification",
  Label_Severity: 'Gravité',
  Label_SearchUser: 'Rechercher un utilisateur',
  Label_RequestDetail: 'Détail de la demande',
  Label_ManagerType: 'Type de gestionnaire',
  Label_DefaultAuthorizer: 'Autorisateur par défaut',
  Label_DefaultManagingGroup: 'Groupe de gestion par défaut',
  Label_ActionDateType: "Type de date d'intervention",
  Label_Filter_Settings: 'Paramètres de filtre',
  Label_ExportData: 'Exporter des données',
  Label_Approved_On: 'Approuvé le',
  Label_Last_Updated_By: 'Dernière mise à jour par',
  Label_Last_Updated_On: 'Dernière mise à jour le',
  Label_SubjectName: 'Nom du sujet',
  Label_Subject_Type: 'Type de sujet',
  Label_Subject_Contact: 'Sujet Contact',
  Label_LTO_Keeper: 'Gardien OLT',
  Label_Performing_Office: "Bureau d'exécution",
  Label_Contracting_Office: 'Entités contractantes',
  Label_CriticalLocationCompany: 'Entités contractantes',
  Label_Receipient_Performing_County: 'Est-ce que le destinataire exécute le comté',
  Label_Owner_Performing_Office: 'Est-ce que le propriétaire exécute le bureau',
  Label_Request_Origin: 'Origine de la demande',
  Label_Receipient_User: 'Utilisateur destinataire',
  Label_Request_Decision: 'Demander une décision',
  Label_Scheme: 'Schème',
  Label_Scheme_Other: 'Régime Autre',
  Label_Accreditation_Body: "Organisme d'accréditation",
  Label_Accreditation_Body_Other: "Organisme d'accréditation Autre",
  Label_Request_Reason: 'Raison de la demande',
  Label_RequestReason: 'Raison de la demande',
  Label_Job_Name: 'Nom du travail',
  Label_Business_Unit: 'Unité commerciale',
  Label_Problem_Statement: 'Énoncé du problème',
  Label_Findings: 'Résultats',
  Label_Started: 'A débuté',
  Label_Completed: 'Complété',
  Label_Start_Action: "Commencer l'action",
  Label_Complete_Action: "Terminer l'action",
  Label_Object_Type: "Type D'objet",
  Label_BelongsTo: 'Appartient à',
  Label_RecordID: "Identifiant de l'enregistrement",
  Label_RecordId: "Identifiant de l'enregistrement",
  Label_ObjectType: "Type D'objet",
  Label_Request_Title: 'Titre de la demande',
  Label_RequestTitle: 'Titre de la demande',
  Label_Request_Type: 'Type de demande',
  Label_Request_Reported_By: 'Demande signalée par',
  Label_RequestReportedBy: 'Demande signalée par',
  Label_Probability_Ranking: 'Classement de probabilité',
  Label_Severity_Ranking: 'Classement de gravité',
  Text_AddProbability: 'Ajouter une probabilité',
  Text_EditProbability: 'Modifier la probabilité',
  Text_ProbabilityID: 'ID de probabilité',
  Text_ProbabilityName: 'Nom de probabilité',
  Text_ProbabilityRankings: 'Classements de probabilité',
  Table_NoProbabilityfound: 'Aucune probabilité trouvée !',
  Text_RequestCategories: 'Catégories de demande',
  Text_EditRootCauses: 'Modifier la cause première',
  Text_AddRequestCategory: 'Ajouter une catégorie de demande',
  Label_SeverityName: 'Nom de gravité',
  Button_Save: 'sauvegarder',
  Button_SaveFilter: 'Enregistrer le filtre',
  Button_SavedFilter: 'Filtre enregistré',
  Btn_ShowFilter: 'Afficher le filtre',
  Btn_HideFilter: 'Masquer le filtre',
  Button_Back: 'Retour',
  Btn_Beautify: 'Embellir',
  Btn_Minify: 'Minifier',
  Button_Delete: 'Effacer',
  Btn_Apply: 'Appliquer',
  Btn_Reset: 'Réinitialiser',
  Btn_ClearFilters: 'Effacer les filtres',
  Btn_ManageDisplay: "Gérer l'affichage",
  Btn_StopImpersonating: 'Arrêtez de vous faire passer pour',
  Btn_Logout: 'Se déconnecter',
  Btn_Add: 'Ajouter',
  Btn_Save: 'Sauvegarder',
  Btn_Cancel: 'Annuler',
  Btn_Ok: "D'accord",
  Btn_Yes: 'Oui',
  Btn_No: 'Non',
  Btn_Print: 'Imprimer',
  Btn_Upload: 'Télécharger',
  Btn_RemoveAll: 'Enlever tout',
  Btn_NewDocument: 'Document',
  Btn_AddNews: 'Nouvelles',
  Btn_Submit: 'Soumettre',
  Btn_Start: 'Commencer',
  Btn_Settings: 'Réglages',
  Button_Update: 'Mise à jour',
  Btn_AddNew: 'Ajouter nouveau',
  Btn_Next: 'Prochain',
  Btn_Previous: 'Précédent',
  Btn_Export: 'Exporter',
  Btn_ExportCSV: 'Exporter CSV',
  Btn_ExportAll: 'Exporter tout',
  Btn_ChangeMyPassword: 'Changer mon mot de passe',
  Btn_ExportToCSV: 'Exporter vers CSV',
  Btn_Load5MoreLastCompletedActions:
        'Charger 5 dernières actions terminées',
  Btn_ShowMoreComments: 'Suite',
  Btn_Approve: 'Approuver',
  Btn_Reject: 'Rejeter',
  Btn_TextReOpen: 'Rouvrir',
  Btn_Cancel_Request: "Demande d'annulation",
  Btn_SubmitForApproval: 'Soumettre pour approbation',
  Column_ObjectType: "Type d'objet",
  Column_GroupName: 'Nom du groupe',
  Column_IssueType: 'type de probleme',
  Column_BelongsTo: 'Appartient à',
  Column_RecordID: "Identifiant de l'enregistrement",
  Column_UpdatedOn: 'Mis à jour le',
  'Column_Updated On': 'Mis à jour le',
  'Column_Updated By': 'Mis à jour par',
  Column_UpdatedBy: 'Mis à jour par',
  Column_Action: 'Action',
  Column_Title: 'Titre',
  Btn_ReOpen: 'Rouvrir',
  Btn_Comment: 'Commentaire',
  Column_Filename: 'Nom de fichier',
  Column_GeneratedOn: 'Généré le',
  Column_Date: 'Date',
  Column_Headline: 'Gros titre',
  Column_Select: 'Sélectionner',
  Column_FirstName: 'Prénom',
  Column_LastName: 'Nom de famille',
  Column_EmailAddress: 'E-mail',
  Column_Controls: 'Les contrôles',
  'Column_Action ID': "Identifiant de l'action",
  'Column_Action Owner': "Propriétaire de l'action",
  Column_Description: 'La description',
  Column_Type: 'Taper',
  Column_Status: 'Statut',
  'Column_Severity ID': 'ID de gravité',
  'Column_Severity Name': 'Nom de la gravité',
  'Column_Created On': 'Créé sur',
  'Column_Started On': 'Commencé sur',
  'Column_Completed On': 'Terminé le',
  Column_Comment: 'Commentaire',
  'Column_Verification Owner': 'Propriétaire de la vérification',
  'Column_Verification ID': 'ID de vérification',
  Column_Details: 'Détails',
  Column_Comments: 'commentaires',
  'Column_Target Date': 'Date cible',
  'Column_Document Type': 'Type de document',
  'Column_File Name': 'Nom de fichier',
  'Column_Uploaded On': 'Téléchargé le',
  'Column_Uploaded By': 'Telechargé par',
  'Column_Last Updated On': 'Dernière mise à jour le',
  'Column_Last Updated By': 'Dernière mise à jour par',
  'Column_Request ID': 'Identifiant de la demande',
  'Column_Request Title': 'Titre de la demande',
  'Column_Request Status': 'État de la demande',
  Column_Location: 'Emplacement',
  'Column_Due Date': "Date d'échéance",
  Column_PastDue: 'Passé échu',
  'Column_Reminder Count': 'Nombre de rappels',
  'Column_Last Reminder Date': 'Date du dernier rappel',
  'Column_Managed By': 'Dirigé par',
  'Column_Approved By': 'Approuvé par',
  'Column_Approved On': 'Approuvé le',
  'Column_Request Category': 'Catégorie de demande',
  'Column_Source System': 'Système source',
  'Column_Risk Ranking': 'Classement des risques',
  'Column_Request Type': 'Type de demande',
  'Column_Region ID': 'IDENTIFIANT',
  Column_Region: 'Région',
  'Column_Root Cause Category ID': 'ID de catégorie de cause première',
  'Column_Root Cause Category': 'Catégorie de cause fondamentale',
  'Column_Root Cause Name': 'Nom de la cause première',
  'Column_Action Status': 'État des actions',
  'Column_Risk Rankings': 'Classements des risques',
  Column_Active: 'Actif',
  'Column_Date Type': 'Type de date',
  'Column_Due Days': "Jours d'échéance",
  'Column_E-mail Type': 'Type de courrier électronique',
  'Column_Company ID': 'IDENTIFIANT',
  "Column_ID de l'entreprise": 'Identifiant',
  'Column_ID de catégorie de cause première': 'Identifiant',
  Column_Id: 'Identifiant',
  Column_ID: 'Identifiant',
  Column_IDENTIFIANT: 'Identifiant',
  'Column_Classement des risques': 'Classement des risques',
  Column_Probabilité: 'Probabilité',
  Column_Gravité: 'Gravité',
  'Column_ID de gravité': 'Identifiant',
  'Column_Nom de gravité': 'Nom de gravité',
  'Column_ID de la cause racine': 'Identifiant',
  'Column_Système source': 'Système source',
  'Column_Nom de la cause première': 'Nom de la cause première',
  'Column_Catégorie de cause première': 'Catégorie de cause première',
  'Column_ID de catégorie de demande': 'Identifiant',
  'Column_La description': 'La description',
  'Column_Type de dates': 'Type de dates',
  "Column_Jours d'échéance": "Jours d'échéance",
  "Column_Type d'e-mail": "Type d'e-mail",
  'Column_ID de région': 'Identifiant',
  "Column_Identifiant de l'emplacement": 'Identifiant',
  'Column_Nom de la localisation': 'Nom de la localisation',
  "Column_Abréviation d'emplacement": 'Emplacement Abréviation',
  "Column_Alias d'emplacement": 'Alias ​​de localisation',
  Column_Région: 'Région',
  'Column_ID de probabilité': 'Identifiant',
  'Column_Nom de probabilité': 'Nom de probabilité',
  'Column_Catégorie de demande': 'Catégorie de demande',
  'Column_Type de lieu': 'Type de lieu',
  Column_Name: 'Nom',
  'Column_Type de probleme': 'Type de probleme',
  'Column_Description du problème': 'Description du problème',
  'Column_Créé sur': 'Créé sur',
  'Column_Créé par': 'Créé par',
  "Column_Nom de l'entreprise": "Nom de l'entreprise",
  'Column_Company Name': "Nom de l'entreprise",
  'Column_Company Code': "Code de l'entreprise",
  'Column_Mis à jour le': 'Mis à jour le',
  'Column_Mis à jour par': 'Mis à jour par',
  Column_Actif: 'Actif',
  'Column_Code postal': 'Code postal',
  'Column_Nom du pays': 'Nom du pays',
  "Column_Code de l'entreprise": "Code de l'entreprise",
  'Column_Registration No': "N ° d'enregistrementNo",
  'Column_Registration No.': "N ° d'enregistrementNo",
  "Column_N ° d'enregistrement": "N ° d'enregistrementNo",
  'Column_Country Code': 'Code postal',
  'Column_Created By': 'Créée par',
  'Column_Country Name': 'Nom du pays',
  Column_Pays: 'pays',
  'Column_Country Names': 'Noms de pays',
  'Column_Issue Description': 'Description du problème',
  'Column_Location ID': 'IDENTIFIANT',
  'Column_Location Name': 'Nom de la localisation',
  'Column_Location Type': 'Type de lieu',
  'Column_Location Alias': 'Alias ​​de localisation',
  'Column_Location Abbreviation': 'Emplacement Abréviation',
  Column_Zone: 'Zone',
  Column_Probability: 'Probabilidad',
  'Column_Probability ID': 'IDENTIFIANT',
  'Column_Probability Name': 'Nom de probabilité',
  'Column_Action Type': "type d'action",
  'Column_Issue Type': 'Type de probleme',
  Column_TPU: 'Paramètre',
  Column_Mean: 'MeanMoyenne',
  'Column_Std Dev': 'Dév Std',
  Column_LCL: 'LCL',
  Column_UCL: 'UCL',
  'Column_Lab Result': 'Résultat de laboratoire',
  Column_Delta: 'Delta',
  'Column_Z Score': 'Score Z',
  'Column_Recheck Value': 'Revérifier la valeur',
  'Column_Test Method': 'Méthode',
  Column_Parameter: 'Paramètre',
  'Column_Spec Comparison': 'Comparaison des spécifications',
  Column_Result: 'Résultat',
  'Column_Spec Limit': 'Limite de spécification',
  'Column_Result Limit': 'Limite de résultat',
  'Column_Reported Date': 'Date du rapport',
  'Column_Reviewed By': 'Revue par',
  'Column_Reviewed On': 'Révisé le',
  'Column_Request Date Time': 'Date de la demande Heure',
  'Column_Request Managed By': 'Demande gérée par',
  'Column_Request Approved By': 'Demande approuvée par',
  'Column_Action ID / Verification ID': "ID d'action/ID de vérification",
  'Column_Action Date / Verification Date': "Date d'action / Date de vérification",
  'Column_Action Status / Verification Status': "État de l'action/état de la vérification",
  'Column_Action Owner / Verification Owner': "Propriétaire de l'action / Propriétaire de la vérification",
  'Column_Action Description / Verification Description': "Description de l'action / Description de la vérification",
  'Column_Request Manager': 'Gestionnaire de demandes',
  'Column_Request Approver': 'Approbateur de demande',
  'Column_Request#': 'Demander#',
  'Column_Action Id': "Identifiant de l'action",
  'Column_Request Number': 'Numéro de demande',
  'Column_Request Title Keyword': 'Demander le mot-clé du titre',
  'Column_Action Date Type': "Type de date d'intervention",
  Column_From: 'De',
  Column_To: 'À',
  'Column_Business Line': "Secteur d'activité",
  Column_Company: 'Compagnie',
  Column_Country: 'De campagne',
  'Column_Verified On': 'Vérifié le',
  'Column_Creation Mode': 'En mode Création',
  'Column_Request Reporter': 'En mode Création',
  Column_Findings: 'Résultats',
  'Column_Performing Office': "Bureau d'exécution",
  'Column_Reported On': 'Signalé le',
  'Column_Request Action Description': 'Requête Action Description',
  Column_Severity: 'Gravité',
  Column_Subject: 'Matière',
  'Column_Subject Type': 'Type de sujet',
  'Column_Subject Contact': 'Sujet Contact',
  'Column_LTO Keeper': 'Gardien LTO',
  'Column_Contracting Office': 'Entités contractantes',
  'Column_Is Receipient Performing County': 'Est-ce que le destinataire exécute le comté',
  'Column_Is Owner Performing Office': 'Est-ce que le propriétaire exécute le bureau',
  'Column_Request Origin': 'Origine de la demande',
  'Column_Receipient User': 'Utilisateur destinataire',
  Column_Decision: 'Décision',
  Column_Scheme: 'Schème',
  'Column_Accreditation Body': "Organisme d'accréditation",
  'Column_Request Reason': 'Raison de la demande',
  'Column_Job Name': 'Nom du travail',
  'Column_Business Unit': 'Unité commerciale',
  Column_RequestStatus: 'État de la demande',
  'Column_Due On': 'Dû le',
  'Column_Past Due': 'En souffrance',
  Column_Remind: 'Rappeler',
  'Column_Problem Statement': 'Énoncé du problème',
  'Column_Country of Performing Office': "Pays du bureau d'exécution",
  'Column_Contracting Entity': 'Entité contractante',
  'Column_Country of Contracting Entity': "Pays de l'entité adjudicatrice",
  'Column_List Name': 'Liste de noms',
  Column_Method: 'Méthode',
  Column_Program_ID: 'Identifiant du programme',
  'Column_E-mail Subject': 'Objet de l e-mail',
  'Column_Sent To': 'Envoyé à',
  'Column_Sent On': 'Envoyé le',
  'Column_Sent By': 'Envoyé par',
  'Column_Verification Status': 'Statut de vérification',
  'Column_Role Name': 'Nom du rôle',

  Label_ListName: 'Liste de noms',
  Confirm_Title_ArchiveDocument: " Document d'archive :",
  Confirm_Message_ArchiveDocument:
        'Voulez-vous vraiment archiver ce document ?',
  Confirm_Title_UnarchiveDocument: ' Désarchiver le document :',
  Confirm_Message_UnarchiveDocument:
        'Voulez-vous vraiment désarchiver ce document ?',
  Tab_General: 'Général',
  Tab_Notifications: 'Avis',
  Tab_Title_AppName: 'iÉvaluer',
  Tooltip_Download: 'Télécharger',
  Tooltip_Archive: 'Archive',
  Tooltip_Unarchive: 'Désarchiver',
  Tooltip_Default: 'Défaut',
  Tooltip_DocumentSearch: 'Accès BV | Recherche de documents',
  Tooltip_RequestSearch: 'Accès BV | Demande de recherche',
  Tooltip_ReportSearch: 'Accès BV | Recherche de rapport',
  Tooltip_RootCauseSearch: 'iEval | Recherche de cause profonde',
  Tooltip_EditRootCause: 'iEval | Modifier la cause racine',
  Tooltip_AddRootCause: 'iEval | Ajouter une cause fondamentale',
  Tooltip_Locations: 'iEval | Emplacements',
  Tooltip_AddLocation: 'iEval | Ajouter un emplacement',
  Tooltip_EditLocation: "iEval | Modifier l'emplacement",
  Tooltip_Regions: 'iEval | Régions',
  Tooltip_AddRegion: 'iEval | Ajouter une région',
  Tooltip_EditRegion: 'iEval | Modifier la région',
  Tooltip_RootCauseNameSearch: 'iEval | Recherche de nom de cause racine',
  Tooltip_AddRootCauseName: 'iEval | Ajouter le nom de la cause racine',
  Tooltip_EditRootCauseName: 'iEval | Modifier le nom de la cause première',
  Tooltip_AddProbability: 'iEval | Ajouter une probabilité',
  Tooltip_EditProbability: 'iEval | Modifier la probabilité',
  Tooltip_ProbabilityRanking: 'iEval | Classements de probabilité',
  Tooltip_Companies: 'iEval | Entreprises',
  Tooltip_AddCompany: 'iEval | Ajouter une entreprise',
  Tooltip_EditCompany: "iEval | Modifier l'entreprise",
  Tooltip_AddRequestCategory: 'iEval | Ajouter une catégorie de demande',
  Tooltip_EditRequestCategory: 'iEval | Modifier la catégorie de demande',
  Tooltip_MappingManagement: 'iEval | Gestion de la cartographie',
  Tooltip_AddMapping: 'iEval | Ajouter un mappage',
  Tooltip_Editmapping: 'iEval | Modifier le mappage',
  Tooltip_EditRequest: 'iEval | Modifier la demande',
  Tooltip_UserSearch: "iEval | Recherche d'utilisateurs",
  Tooltip_Export_To_CSV: 'Exporter vers CSV',
  Tab_AllDocuments: "Documents et manuels d'instructions",
  Tab_UserDocuments: 'Documents utilisateur',
  Tab_LatestNews: 'Dernières nouvelles',
  Tab_NewsArchived: 'Nouvelles archivées',
  Axis_TurnaroundTime: "Délai d'exécution (jours)",
  Label_RequestNumber: 'Numéro de demande',
  Label_RequestTitleKeyword: 'Demander le mot-clé du titre',
  Label_RequestType: 'Type de demande',
  Label_IssueType: 'Type de probleme',
  Label_IssueDescription: 'Description du problème',
  Label_Location: 'Emplacement',
  Label_RequestStatus: 'État de la demande',
  Label_RootCauseCategory: 'Catégorie de cause première',
  Label_RootCauseName: 'Nom de la cause première',
  Label_SourceSystem: 'Système source',
  Label_CreationMode: 'En mode Création',
  Label_RiskRanking: 'Classement des risques',
  Label_RequestReporter: 'Demander un rapporteur',
  Label_RequestManagingGroup: 'Groupe de gestion des demandes',
  Label_RequestApprover: 'Approbateur de demande',
  Label_ActionOwner: "Propriétaire de l'action",
  Label_Company: 'Compagnie',
  Label_Comment: 'Commentaire',
  Label_Comments: 'Commentaires',
  Label_NoComments: 'Sans commentaires',
  Label_Country: 'Pays',
  Label_BusinessLine: "Secteur d'activité",
  Label_ProductName: 'Nom du produit',
  Label_ClientName: 'Nom du client',
  Label_Sponsor: 'Parrainer',
  Label_Program: 'Programme',
  Label_Cycle: 'Cycle',
  Label_Sample: 'Goûter',
  Label_Test: 'Test',
  Label_ActionId: "Identifiant de l'action",
  Label_ActionStatus: 'État des actions',
  Label_ActionType: "type d'action",
  Label_ActionDate: "Date d'intervention",
  Label_RequestTypeDate: 'Type de demande Date',
  Label_Generic: 'Générique',
  Label_FromDate: 'Partir de la date',
  Label_FromTo: 'À ce jour',
  Label_Dashboard: 'Tableau de bord | Générique',
  Label_New: 'Nouveau',
  Label_In_Progress: 'En cours',
  Label_Pending_Approval: 'En attente de validation',
  Label_Rejected: 'Rejeté',
  Label_Created_Actions: 'Actions créées',
  Label_Started_Actions: 'Actions commencées',
  Label_Almost_Past_Due: 'Presque/en retard',
  Label_Dashboard_User: 'Tableau de bord | Utilisateur',
  Label_RootCauseSearch: 'Recherche de cause première',
  Label_DefaultRequestManager: 'Gestionnaire de demandes par défaut',
  Label_RequestManagerGroup: 'Groupe de gestionnaires de demandes',
  Label_MappingAddTable: 'Mappage Ajouter une table',
  Label_RequestManager: 'Gestionnaire de demandes',
  Label_RequestManagedBy: 'Request Manager',
  Label_SampleNumber: "Numéro d'échantillon",
  Label_Non_Conformance: 'Détails de la non-conformité',
  Label_Import_Non_Conformance_Data:
        'Importer des données de non-conformité',
  Label_Add_PTP_Details: 'Ajouter des détails CPMS',
  Label_Add_Non_Conformace_Details: 'Ajouter des détails de non-conformité',
  Label_ForgotPassword: 'Mot de passe oublié',
  Label_NewPassword: 'nouveau mot de passe',
  Label_ConfirmNewPassword: 'Confirmer le nouveau mot de passe',
  Label_Countries: 'Des pays',
  Label_Locations: 'Emplacements',
  Label_RequestCategories: 'Catégories de demande',
  Label_BusinessLines: 'Lignes directives',
  Breadcrumb_RootCauseSearch: 'Recherche de cause première',
  Breadcrumb_RootCauseNameManagement: 'Gestion des noms de cause première',
  Breadcrumb_RequestCategories: 'Catégories de demande',
  Breadcrumb_Locations: 'Emplacements',
  Table_NoRootCausefound: 'Aucune cause racine trouvée !',
  Table_NoLocationfound: 'Aucun emplacement trouvé !',
  Breadcrumb_RootCauseCategories: 'Catégories de causes profondes',
  Breadcrumb_Countries: 'Des pays',
  Table_NoRootCauseNamefound: "Aucun nom de cause n'a été trouvé !",
  Table_NoRootCauseCategoryFound:
        'Aucune catégorie de cause fondamentale trouvée !',
  Breadcrumb_ProbabilityRanking: 'Classements de probabilité',
  Breadcrumb_SeverityRanking: 'Classements de gravité',
  Breadcrumb_Companies: 'Entreprises',
  Breadcrumb_MappingManagment: 'Gestion de la cartographie',
  Table_NoCompanyfound: 'Aucune entreprise trouvée !',
  Table_NorequestCategoryfound: 'Aucune demande Catégorie trouvée',
  Table_NoMappingfound: 'Aucun mappage trouvé !',
  Placeholder_Selectanoption: 'Sélectionner une option',
  PlaceHolder_Comments: 'Votre commentaire ici...',
  Text_RootCauseSearch: 'Recherche de cause première',
  Text_AddRootCauseSearch: 'Ajouter une recherche de cause racine',
  Text_EditRootCauseSearch: 'Modifier la recherche de la cause première',
  Text_RootCauseID: 'ID de la cause racine',
  Text_Showing: 'Montrant',
  Text_RootCause: 'Cause première',
  Text_LocationID: "Identifiant de l'emplacement",
  Text_LocationName: 'Nom de la localisation',
  Text_LocationCode: "Le code d'emplacement",
  Text_LocationType: 'Type de lieu',
  Text_LocationAlias: "Alias d'emplacement",
  Text_BusinessLine: "Secteur d'activité",
  Text_Zone: 'Zone',
  Text_Company: 'Compagnie',
  Text_AddLocation: 'Ajouter un emplacement',
  Text_EditLocation: "Modifier l'emplacement",
  Text_AddCompany: 'Ajouter une entreprise',
  Text_EditCompany: "Modifier l'entreprise",
  Text_CompanyID: "ID de l'entreprise",
  Text_CompanyName: "Nom de l'entreprise",
  Text_CompanyCode: "Code de l'entreprise",
  Text_CompanyCurrency: "Devise de l'entreprise",
  Text_RegistrationNo: "N ° d'enregistrement",
  Text_EditMap: 'Modifier le mappage',
  Text_UserID: "Identifiant d'utilisateur",
  Text_AddMap: 'Ajouter un mappage',
  Text_CancelRequest_Popup:
        'Cela annulera la demande et empêchera la poursuite du flux de travail. Voulez-vous poursuivre?',
  Text_EditRole: 'Modifier le rôle',
  Text_AddRole: 'Ajouter un rôle',
  Text_MappingID: 'ID de la carte',
  Text_DefaultRequestManager: 'Gestionnaire de demandes par défaut',
  Text_RequestManagerGroup: 'Groupe de gestionnaires de demandes',
  Text_CountryID: 'Identifiant du pays',
  Text_CountryCode: 'Code postal',
  Text_NoFilterFound: 'Aucun filtre trouvé!',
  Text_NoPresetFound: 'Aucun préréglage trouvé',
  Text_RequestIsRejected: 'La demande est rejetée',
  Text_ActivationInstruction: 'Pour activer la demande, veuillez utiliser le bouton Réouvrir en bas de la page.',
  Toast_CreatedSuccessfully: 'Créé avec succès',
  Toast_RequestCreatedSuccessfully: 'La demande est créée avec succès !',
  Toast_CreateFailed: 'Échec de la création',
  Toast_UpdatedSuccessfully: 'Mis à jour avec succés',
  Toast_UpdateFailed: 'Mise à jour a échoué',
  Toast_ChangeStatusSuccessfull: 'Changement de statut réussi',
  Toast_FileDownloadedSuccessfully: 'Fichier téléchargé avec succès !',
  Toast_RequestManagerAllowedToDelete: 'Seul le gestionnaire de demandes est autorisé à supprimer',
  Toast_ActionStatusUpdatedSuccessfully: "État de l'action mis à jour avec succès !",
  Toast_Update_Action_Successfully: "Mettre à jour l'action avec succès!",
  Toast_FileUploadedSuccessfully: 'Fichier(s) téléchargé(s) avec succès',
  Toast_FillMandatoryFields: 'Veuillez remplir tous les champs obligatoires',
  Toast_FilterItemsDisplayUpdateFailed: "Échec de la mise à jour de l'affichage des éléments du filtre!",
  Toast_FilterItemsDisplayUpdateSuccess: "Filtrer l'affichage des éléments mis à jour avec succès",
  Toast_ReminderMailScheduled: 'E-mail de rappel programmé!',

  Error_RootCauseNameIsRequired: 'Le nom de la cause racine est requis',
  Error_SomethingWentWrong: "Quelque chose s'est mal passé !",
  Error_MandatoryFieldMissing: 'Champs obligatoires manquants!',
  Error_MaxLengthIs100: 'La longueur maximale est de 100',
  Error_RequestCategoryIsRequired:
        'La catégorie de demande est obligatoire',
  Error_LocationAliasIsRequired: "L'alias d'emplacement est requis",
  Error_LocationNameIsRequired: "Le nom de l'emplacement est requis",
  Error_LocationCodeIsRequired: "Le code d'emplacement est requis",
  Error_LocationTypeIsRequired: "Le type d'emplacement est requis",
  Error_ZoneIsRequired: 'La zone est requise',
  Error_CountryIsRequired: 'Le pays est requis',
  Error_RegionIsRequired: 'La région est requise',
  Error_CountryRegionLocationIsRequired: 'Pays/Région/Localisation est requis',
  Error_CompanyIsRequired: 'La société est requise',
  Error_BusinessLineIsRequired: "La ligne d'affaires est requise",
  Error_RootCauseCategoryIsRequired:
        'La catégorie de cause racine est obligatoire',
  Error_ProbabilityNameIsRequired: 'Le nom de la probabilité est requis',
  Error_SeverityNameIsRequired: 'Le nom de la gravité est requis',
  Error_CompanyNameIsRequired: "Le nom de l'entreprise est requis",
  Error_IssueTypeIsRequired: 'Le type de problème est requis',
  Error_IssueDescriptionIsRequired:
        'La description du problème est requise',
  Error_AddressLine2IsRequired: "La ligne d'adresse 2 est requise",
  Error_AddressLine1IsRequired: "La ligne d'adresse 1 est obligatoire",
  Error_PostalCodeIsRequired: 'Le code postal est requis',
  Error_CityIsRequired: 'La ville est obligatoire',
  Error_StateIsRequired: "L'état est requis",
  Error_RiskRankingIsRequired: 'Un classement des risques est requis',
  Error_SourceSystemIsRequired: 'Le système source est requis',
  Error_RegionCodeIsRequired: 'Le code de région est requis',
  Error_UsernameIsRequired: "Nom d'utilisateur est nécessaire",
  Error_FirstNameIsRequired: 'Le prénom est requis',
  Error_LastNameIsRequired: 'Le nom de famille est obligatoire',
  Error_EmailIsRequired: "L'e-mail est obligatoire",
  Error_TimezoneIsRequired: 'Le fuseau horaire est obligatoire',
  Error_EmailIsInvalid: 'Le courriel est invalide',
  Error_MaxLengthIs5: 'La longueur maximale est de 5',
  Error_MaxLengthIs50: 'La longueur maximale est de 50',
  Error_RoleIsRequired: 'Le rôle est requis',
  Error_CustomFunctionalityIsRequired:
        'Une fonctionnalité personnalisée est requise',
  Error_Message_ExportFailed: "Échec de l'exportation",
  Error_DefaultRequestManagerIsRequired:
        'Le gestionnaire de demandes par défaut est requis',
  Error_RequestCategoryCodeIsRequired:
        'La demande de code de catégorie est requise',
  Error_RegistrationNoIsRequired: "Le numéro d'enregistrement est requis",
  Error_CompanyCurrencyIsRequired:
        "La devise de l'entreprise est obligatoire",
  Error_CompanyCodeIsRequired: "Le code d'entreprise est requis",
  Error_DateTypeIsRequired: 'Le type de date est obligatoire',
  Error_CountryCodeIsRequired: 'Le code de pays est requis',
  Error_RequestCategoryGroupIsRequired:
        'Le groupe de catégories de demande est requis',
  Error_LocationIsRequired: "L'emplacement est requis",
  Error_DefaultManagerIsRequired: 'Le gestionnaire par défaut est requis',
  Error_ProbabilityIsRequired: 'La probabilité est requise',
  Error_SeverityIsRequired: 'La gravité est requise',
  Error_RequestTitleIsRequired: 'Le titre de la demande est requis',
  Error_RequestTypeIsRequired: 'Le type de requête est requis',
  Error_requestManagedBy: 'demande gérée par est obligatoire',
  Error_reportedOn: 'Signalé est obligatoire',
  Error_dueOn: 'Due le est requis',
  Error_requestReportedBy: 'La demande signalée par est obligatoire',
  Error_requestApprovedBy: 'La demande approuvée par est requise',
  Error_required: 'obligatoire',
  Error_LoadingDataError: 'Erreur de chargement des données',
  Error_RequestCategoryNameIsRequired:
        'Le nom de la catégorie de demande est obligatoire',
  Error_DescriptionIsRequired: 'La description est obligatoire',
  Error_ApprovedOnIsRequired: 'Approuvé le est requis',
  Error_createdOn: 'Créé le est requis',
  Error_ManagerTypeIsRequired: 'Le type de gestionnaire est requis',
  Error_RequestManagingGroupIsRequired:
        'Le groupe de gestion des demandes est requis',
  Error_AccessLevelIsRequired: "Le niveau d'accès est requis",
  Error_AccessLevelValueIsRequired:
        "La valeur du niveau d'accès est requise",
  Error_MaxLengthIs2: 'La longueur maximale est de 2',
  Error_MaxLengthIs3: 'La longueur maximale est de 3',
  Error_ScheduleIsRequired: "Le type d'horaire est requis",
  Error_DayCountIsRequired: 'Le nombre de jours est requis',
  Error_FrequencyIsRequired: 'La fréquence est requise',
  Error_ActionIsRequired: 'Une action est requise',
  Error_TriggerIsRequired: 'Le déclencheur est requis',
  Error_DayTypeIsRequired: 'Le type de jour est requis',
  Error_DueDaysIsRequired: "Les jours d'échéance sont requis",
  Error_CompleteCannotBeDone:
        "L'action complète ne peut pas être effectuée",
  Error_StartCannotBeDone:
        "Cette opération ne peut être effectuée que par le propriétaire de l'action",
  Error_parameterNameIsRequired: 'Le nom du paramètre est requis',
  Error_ResultValueIsRequired: 'La valeur du résultat est requise',
  Error_LocationAbbreviationIsRequired:
        "L'abréviation de l'emplacement est requise",
  Error_TPUIsRequired: 'Le paramètre est obligatoire',
  Error_LabResultIsRequired: 'Le résultat du laboratoire est requis',
  Error_TestMethodNameIsRequired: 'Le nom de la méthode de test est requis',
  Error_OnlyUpperCaseLettersAllowed:
        'Seules les lettres majuscules sont autorisées',
  Error_ActionTypeRequired: "Le type d'action est requis",
  Error_AssignedToRequired: 'Attribué à est requis',
  Error_TitleRequired: 'Le titre est requis',
  Error_ActionRequired: 'Une action est requise',
  Error_DueDateRequired: "La date d'échéance est requise",
  Error_FieldIsRequired: '{{fieldName}} est requis',
  Error_SelectRequestDecisionRequired: 'Sélectionnez "Demander une décision" pour continuer',
  Error_MissingEmail: "L'e-mail est manquant !",
  Error_BelongsToIsRequired: 'Appartient à est obligatoire',
  Error_RecordIDIsRequired: "L'ID d'enregistrement est obligatoire",
  Error_ObjectTypeIsRequired: "Le type d'objet est obligatoire",
  Label_Create_Request: 'Créer une demande',
  Label_Home: 'Maison',
  Label_Action: 'Action',
  Label_Add_Action: 'Ajouter une action',
  Label_Edit_Action: "Modifier l'action",
  Label_Close: 'proche',
  Label_Import_PTP_Data: 'Importer des données CPMS',
  Label_Connect: 'Relier',
  Label_Refresh: 'Rafraîchir',
  Label_Load: 'Charger',
  Label_Attachment: 'pièces jointes',
  Label_AssignedTo: 'Assigné à',
  Label_ActionTitle: "Titre de l'action",
  Label_Drop_Your_File_Here: 'Déposez votre dossier ici',
  Label_Attachment_Details: 'Ajouter des détails sur la pièce jointe',
  Label_Save: 'sauvegarder',
  Label_DueDate: "Date d'échéance",
  Label_Audit_Trail: "Piste d'audit",
  Label_Effectiveness_Verification: "Vérification de l'efficacité",
  Label_Add_Verification: 'Ajouter une vérification',
  Label_Add_Effectiveness_Verification:
        "Ajouter une vérification d'efficacité",
  Label_Heading: 'Titre',
  Label_Issue_Identification: 'Identification du problème',
  Label_Linked_Requests: 'Demandes liées',
  Label_PTP_Details: 'Détails CPMS',
  Label_PTP_Change_History: 'Historique des modifications CPMS',
  Label_Review: 'Examen',
  Label_Reported_On: 'Signalé le',
  Label_ReportedOn: 'Signalé le',
  Label_Created_On: 'Créé sur',
  Label_Due_On: 'Dû le',
  Label_DueOn: 'Dû le',
  Label_CompanyName: "Nom de l'entreprise",
  Label_CompanyCode: "Code de l'entreprise",
  Label_CompanyCurrency: "Devise de l'entreprise",
  Label_RegistrationNo: "N ° d'enregistrement",
  Label_SearchRequests: 'Demandes de recherche',
  Label_DragDrop:
        'Faites glisser et déposez le fichier ou cliquez ici pour sélectionner',
  Label_AccessLevel: "Niveau d'accès",
  Label_AccessLevelValue: "Valeur du niveau d'accès",
  Label_SeverityEditTable: 'Tableau de modification de la gravité',
  Label_ProbabilityEditTable: 'Table de modification de probabilité',
  Label_Duedays: "Jours d'échéance",
  Label_EnterReason: 'Entrez la raison',
  Label_RequestCategoryCode: 'Demander le code de catégorie',
  Label_RequestCategoryName: 'Nom de la catégorie de demande',
  Label_ApprovedByName: 'Approbateur de la demande',
  Label_Confirm_Reject: 'Confirmer le rejet',
  Label_Provide_Reject_Reason: 'Fournir la raison du rejet',
  Label_Filters: 'Filtres',
  Header_SearchUserViaLDAP: 'Rechercher un utilisateur via LDAP',
};

export default frTranslation;
