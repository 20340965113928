import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const requestCategorySourceSliceName = 'requestCategorySource';

export const filterFields = {
  RequestCategory: 'RequestCategory',
  SourceSystem: 'SourceSystem',
  Active: 'Active',
};

const requestCategorySourceSlice = createSearchPageSlice({
  sliceName: requestCategorySourceSliceName,
  filterFields,
  initialFilterState: {
    RequestCategory: null,
    SourceSystem: null,
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RequestCategoryName',
    sortDirection: SortDirection.Asc,
  },
  extraInitialStates: {
    tableData: []
  },
  extraReducers: {
    updateActionValue(state, action) {
      state.actionsData[action.payload.propName] = action.payload.propValue;
    },

    updateActionValueFromAPI(state, action) {
      const fields = action.payload.actionData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.actionsData[keys] = _data;
        } else {
          state.actionsData[keys] = fields[keys];
        }
        return '';
      });
    },

    saveActionTableItems(state, action) {
      state.actionsData['tableData'] = [...state.actionsData.tableData, action.payload.postArray];
    },
    updateAction(state, action) {
      state.actionsData['tableData'] = action.payload;
    },
    updateActionTableItems(state, action) {
      state.actionsData['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },

  },
});

export const requestCategorySourceActions = requestCategorySourceSlice.actions;
export default requestCategorySourceSlice.reducer;
