import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  ObjectType: 'ObjectType',
  BelongsTo: 'BelongsTo',
  RecordId: 'RecordId'
};

export const objectConfigSearchSliceName = 'objectConfigSearch';

const objectConfigurationSlice = createSearchPageSlice({
  sliceName: objectConfigSearchSliceName,
  filterFields,
  initialFilterState: {
    ObjectType: '',
    BelongsTo: '',
    RecordId: '',
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'objectConfigurationID',
    sortDirection: SortDirection.Asc,
  },
});

export const objectConfigurationActions = objectConfigurationSlice.actions;
export default objectConfigurationSlice.reducer;
