import { equalsIgnoringCase } from '../stringUtils';

export const SortDirection = {
  Asc: 'asc',
  Desc: 'desc',

  combineSortBy: (col, direction) => {
    if (equalsIgnoringCase(direction, SortDirection.Asc)) {
      return col;
    }

    return `${col}Descending`;
  },
};
