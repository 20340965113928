import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';
import { SortDirection } from '../../../../utils/constants';

const initialFilterState = {
  CountryName: '',
  CountryCode: '',
  Zone: null,
  Active: true,
};

export const filterFields = {
  CountryName: 'CountryName',
  CountryCode: 'CountryCode',
  Zone: 'Zone',
  Active: 'Active',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CountryCode',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'countrySearch';

const countrySearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const countrySearchActions = countrySearchSlice.actions;
export default countrySearchSlice.reducer;
