import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  actionId: 'ActionId',
  actionTitle: 'ActionTitle',
  actionOwner: 'ActionOwner',
  requestNumber: 'RequestNumber',
  requestTitleKeyword: 'RequestTitleKeyword',
  requestManager: 'RequestManager',
  company: 'Company',
  country: 'Country',
  active: 'Active',
  actionStatus: 'ActionStatus',
  actionType: 'ActionType',
  location: 'Location',
  requestStatus: 'RequestStatus',
  riskRanking: 'RiskRanking',
  requestTypeDate: 'RequestTypeDate',
  requestCategory: 'RequestCategoryName',
  requestType: 'RequestType',
  issueType: 'IssueType',
  rootCauseCategory: 'RootCauseCategory',
  rootCauseName: 'RootCauseName',
  businessLine: 'BusinessLine',
  actionDateType: 'ActionDateType',
  dateFrom: 'From',
  dateTo: 'To',
  sourceSystem: 'SourceSystem',
  requestApprover: 'ApprovedByName',
};

export const actionSliceName = 'actionSearch';

const actionSearchSlice = createSearchPageSlice({
  sliceName: actionSliceName,
  filterFields,
  initialFilterState: {
    ActionId: '',
    ActionTitle: '',
    ActionOwner: null,
    ActionStatus: null,
    ActionType: null,
    Location: null,
    RequestNumber: '',
    RequestTitleKeyword: '',
    RequestStatus: null,
    RequestManager: null,
    RiskRanking: null,
    RequestTypeDate: null,
    RequestCategoryName: null,
    RequestType: null,
    IssueType: null,
    RootCauseCategory: null,
    RootCauseName: null,
    BusinessLine: null,
    Company: null,
    Country: null,
    Active: true,
    ActionDateType: null,
    From: '',
    To: '',
    SourceSystem: null,
    ApprovedByName: null,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RequestActionID',
    sortDirection: SortDirection.Desc,
  },
});

export const actionSearchActions = actionSearchSlice.actions;
export default actionSearchSlice.reducer;
