const spanishTranslation = {
  Error_FieldIsRequired: '{{fieldName}} es necesario',
  Header_Access: 'Acceso',
  Groups: 'Grupos',
  Tooltip_DocumentSearch: 'Access BV | Búsqueda de documentos',
  Tooltip_ReportSearch: 'Access BV | Búsqueda de reportes',
  Tooltip_RequestSearch: 'Access BV | Búsqueda de solicitudes',
  Label_AccessLevel: 'Nivel de acceso',
  Error_AccessLevelIsRequired: 'El nivel de acceso es obligatorio',
  Label_AccessLevelValue: 'Valor del nivel de acceso',
  Error_AccessLevelValueIsRequired: 'El valor del nivel de acceso es obligatorio',
  Column_Accreditation: 'Body Organismo de Acreditación',
  Label_Accreditation_Body: 'Organismo de Acreditación',
  Label_Accreditation_Body_Other: 'Otro organismo de acreditación',
  Column_ObjectType: 'Tipo de objeto',
  Column_IssueType: 'Tipo de problema',
  Column_BelongsTo: 'Pertenece a',
  Column_RecordID: 'ID de registro',
  Column_UpdatedOn: 'Actualizado en',
  Column_UpdatedBy: 'Actualizado por',
  Column_Action: 'Acción',
  Label_Action: 'Acción',
  Text_ActionMissingDueDate: 'Acción {{requestActionID}} No se ha especificado la fecha límite',
  Text_ActionMissingOwner: 'Acción {{requestActionID}} No se ha especificado el propietario',
  Nav_Menu_Ation_Center: 'Centro de Acción',
  Label_ActionDate: 'Fecha de Acción',
  'Column_Action Date / Verification Date': 'Fecha de Acción / Fecha de Verificación',
  Label_ActionDateType: 'Tipo de Fecha de Acción',
  'Column_Action Date Type': 'Tipo de Fecha de Acción',
  'Column_Action Description / Verification Description': 'Descripción de Acción / Descripción de Verificación',
  'Column_Action ID': 'ID de Acción',
  'Column_Action Id': 'ID de Acción',
  Label_ActionId: 'ID de Acción',
  'Column_Action ID / Verification ID': 'ID de Acción / ID de Verificación',
  Error_ActionIsRequired: 'La acción es obligatoria',
  'Column_Action Owner': 'Propietario de la Acción',
  Label_ActionOwner: 'Propietario de la Acción',
  'Column_Action Owner / Verification Owner': 'Propietario de la Acción / Propietario de la Verificación',
  'Column_Action Status': 'Estado de Acción',
  Label_ActionStatus: 'Estado de Acción',
  'Column_Action Status / Verification Status': 'Estado de Acción / Estado de Verificación',
  Toast_ActionStatusUpdatedSuccessfully: '¡El estado de la acción se ha actualizado correctamente!',
  Label_ActionTitle: 'Título de Acción',
  'Column_Action Type': 'Tipo de Acción',
  Label_ActionType: 'Tipo de Acción',
  Error_ActionTypeRequired: 'El tipo de acción es obligatorio',
  Nav_Menu_Actions: 'Acciones',
  Nav_Menu_List: 'Lista',
  Header_Actions: 'Acciones',
  Text_Active: 'Activo',
  Label_Active: 'Activo',
  Column_Active: 'Activo',
  Label_Add: 'Agregar',
  Btn_Add: 'Agregar',
  Label_Add_Action: 'Agregar Acción',
  Label_Attachment_Details: 'Agregar Detalles del Archivo Adjunto',
  Text_AddCompanies: 'Agregar Empresas',
  Header_AddCompany: 'Agregar Empresa',
  Text_AddCompany: 'Agregar Empresa',
  Header_AddCountry: 'Agregar Países',
  Text_AddCountries: 'Agregar Países',
  Label_Add_Effectiveness_Verification: 'Agregar Verificación de Efectividad',
  Header_AddIssueType: 'Agregar Tipo de Problema',
  Text_AddIssueType: 'Agregar Tipo de Problema',
  Header_AddLocation: 'Agregar Ubicación',
  Text_AddLocation: 'Agregar Ubicación',
  Header_AddMap: 'Agregar Mapeo',
  Header_AddMapping: 'Agregar Mapeo',
  Text_AddMap: 'Agregar Mapeo',
  Btn_AddNew: 'Agregar Nuevo',
  Header_AddDocument: 'Agregar Nuevo Documento',
  Label_Add_Non_Conformace_Details: 'Agregar Detalles de No Conformidad',
  Header_AddProbability: 'Agregar clasificación de probabilidad',
  Text_AddProbability: 'Agregar Probabilidad',
  Label_Add_PTP_Details: 'Agregar Detalles de CPMS',
  Header_AddRegion: 'Agregar Región',
  Text_AddRegion: 'Agregar Región',
  Header_AddRequestCategory: 'Agregar Categoría de Solicitud',
  Text_AddRequestCategory: 'Agregar Categoría de Solicitud',
  Header_AddRequestCategorySourceSystemMapping: 'Agregar Fuente de Categoría de Solicitud',
  Text_AddRequestCategorySourceSystemMapping: 'Agregar Fuente de Categoría de Solicitud',
  Tooltip_AddRequestCategorySourceSystemMapping: 'Agregar Fuente de Categoría de Solicitud',
  Header_AddRiskRanking: 'Agregar Clasificación de Riesgo',
  Text_AddRiskRanking: 'Agregar Clasificación de Riesgo',
  Header_AddRole: 'Agregar Rol',
  Tooltip_AddRole: 'Agregar Rol',
  Text_AddRole: 'Agregar Rol',
  Header_AddRootCause: 'Agregar Causa Raíz',
  Header_AddRootCauseCategory: 'Agregar Categoría de Causa Raíz',
  Text_AddRootCauseCategory: 'Agregar Categoría de Causa Raíz',
  Header_AddRootCauseCategorySearch: 'Agregar Búsqueda de Categoría de Causa Raíz',
  Header_AddRootCauseName: 'Agregar Nombre de Causa Raíz',
  Text_AddRootCauseName: 'Agregar Nombre de Causa Raíz',
  Header_AddRootCauseSearch: 'Agregar Búsqueda de Causa Raíz',
  Text_AddRootCauseSearch: 'Agregar Búsqueda de Causa Raíz',
  Text_AddRootCauses: 'Agregar causa raíz',
  Header_AddRootCauses: 'Agregar causa Raíz',
  Header_AddSeverity: 'Agregar Severidad',
  Text_AddSeverity: 'Agregar Severidad',
  Text_AddUser: 'Agregar Usuario',
  Label_Add_Verification: 'Agregar Verificación',
  Label_AddressLine1: 'Línea de Dirección 1',
  Error_AddressLine1IsRequired: 'Se requiere la Línea de Dirección 1',
  Label_AddressLine2: 'Línea de Dirección 2',
  Error_AddressLine2IsRequired: 'Se requiere la Línea de Dirección 2',
  Nav_Menu_Administrator: 'Administrador',
  Label_Alias: 'Alias',
  All: 'Todos',
  Label_Almost_Past_Due: 'Casi/Vencido',
  Btn_Apply: 'Aplicar',
  Btn_Approve: 'Aprobar',
  'Column_Approved By': 'Aprobado Por',
  'Column_Approved On': 'Aprobado En',
  Label_Approved_On: 'Aprobado En',
  Error_ApprovedOnIsRequired: 'Se requiere Aprobado En',
  Tooltip_Archive: 'Archivo',
  Confirm_Title_ArchiveDocument: 'Archivar documento:',
  Confirm_Message_ArchiveDocument: '¿Está seguro/a de que desea archivar este documento?',
  Confirm_Message_UnarchiveDocument: '¿Está seguro/a de que desea desarchivar este documento?',
  Label_AssignedDivisions: 'Divisiones Asignadas',
  Label_AssignedLocations: 'Ubicaciones Asignadas',
  Label_AssignedTo: 'Asignado A',
  Error_AssignedToRequired: 'Se requiere Asignado a',
  Label_Attachment: 'Adjunto',
  Header_Attachments: 'Adjuntos',
  Label_Audit_Trail: 'Registro de Auditoría',
  Label_AvgDays: 'Días Promedio',
  Button_Back: 'Atrás',
  'Column_Business Line': 'Línea de Negocio',
  Label_BusinessLine: 'Línea de Negocio',
  Text_BusinessLine: 'Línea de Negocio',
  Error_BusinessLineIsRequired: 'Se requiere Línea de Negocio',
  Label_BusinessLines: 'Líneas de Negocio',
  'Column_Business Unit': 'Unidad de Negocio',
  Label_Business_Unit: 'Unidad de Negocio',
  Btn_Beautify: 'Embellecer',
  Btn_Minify: 'Minimizar',
  Btn_Cancel: 'Cancelar',
  Btn_Cancel_Request: 'Cancelar Solicitud',
  Btn_ChangeMyPassword: 'Cambiar mi Contraseña',
  Toast_ChangeStatusSuccessfull: 'Cambio de estado exitoso',
  Label_City: 'Ciudad',
  Error_CityIsRequired: 'Se requiere Ciudad',
  Error_BelongsToIsRequired: 'Appartient à est obligatoire',
  Error_RecordIDIsRequired: "L'ID d'enregistrement est obligatoire",
  Error_ObjectTypeIsRequired: "Le type d'objet est obligatoire",
  Btn_ClearFilters: 'Limpiar Filtros',
  Label_Client: 'Cliente',
  Label_ClientName: 'Nombre del Cliente',
  Label_Close: 'Cerrar',
  Column_Parameter: 'Parámetro',
  Btn_Comment: 'Comentario',
  Column_Comment: 'Comentario',
  Label_Comment: 'Comentario',
  Column_Comments: 'Comentarios',
  Label_Comments: 'Comentarios',
  Nav_Menu_Companies: 'Empresas',
  Header_Companies: 'Empresas',
  Breadcrumb_Companies: 'Empresas',
  Breadcrumb_Groups: 'Grupos',
  Column_Company: 'Empresa',
  Label_Company: 'Empresa',
  Label_General: 'General',
  Text_Company: 'Empresa',
  Text_AddGroup: 'Agregar grupo',
  Text_EditGroup: 'Editar grupo',
  Tooltip_AddGroup: 'iEval | Agregar grupo',
  Tooltip_Groups: 'iEval | Grupos',
  Tooltip_EditGroup: 'iEval | Editar grupo',
  Text_CompanyCode: 'Código de Empresa',
  Label_CompanyCode: 'Código de Empresa',
  Error_CompanyCodeIsRequired: 'Se requiere Código de Empresa',
  Error_TypeIsRequired: 'El tipo es obligatorio',
  Text_CompanyCurrency: 'Moneda de la Empresa',
  Label_CompanyCurrency: 'Moneda de la Empresa',
  Error_CompanyCurrencyIsRequired: 'Se requiere Moneda de la Empresa',
  Text_CompanyID: 'ID de Empresa',
  Error_CompanyIsRequired: 'Se requiere Empresa',
  Text_CompanyName: 'Nombre de la Empresa',
  Label_CompanyName: 'Nombre de la Empresa',
  Error_CompanyNameIsRequired: 'Se requiere Nombre de la Empresa',
  Btn_Complete: 'Completado',
  Label_Complete_Action: 'Completar Acción',
  Label_Object_Type: 'Tipo de objeto',
  Label_BelongsTo: 'Pertenece a',
  Label_RecordID: 'ID de registro',
  Label_RecordId: 'ID de registro',
  Label_ObjectType: 'Tipo de objeto',
  Error_CompleteCannotBeDone: 'No se puede completar la acción',
  Header_Action_Column_Completed: 'Completado',
  Label_Completed: 'Completado',
  'Column_Completed On': 'Completado En',
  Label_ConfirmNewPassword: 'Confirmar Nueva Contraseña',
  Label_Confirm_Reject: 'Confirmar Rechazo',
  Label_Connect: 'Conectar',
  'Column_Contracting Office': 'Entidades Contratantes',
  Label_Contracting_Office: 'Entidades Contratantes',
  Label_CriticalLocationCompany: 'Entidades Contratantes',
  Column_Controls: 'Controles',
  Header_Countries: 'Países',
  Nav_Menu_Countries: 'Países',
  Text_Countries: 'Países',
  Label_Countries: 'Países',
  Breadcrumb_Countries: 'Países',
  Text_Country: 'País',
  Column_Country: 'País',
  Label_Country: 'País',
  Label_CountryCode: 'Código de País',
  Label_Code: 'Código',
  Label_Edit: 'editar',
  Text_CountryCode: 'Código de País',
  Error_CountryCodeIsRequired: 'Se requiere Código de País',
  Text_CountryID: 'ID de País',
  Error_CountryIsRequired: 'Se requiere País',
  Text_CountryName: 'Nombre de País',
  Label_CountryName: 'Nombre de País',
  Toast_CreateFailed: 'Creación Fallida',
  Toast_CreateFailedDueToMessage: 'Creación Fallida debido a código de país duplicado',
  Label_Create_Request: 'Crear Solicitud',
  Nav_Menu_Create_Request: 'Crear Solicitudes',
  Header_CreateUser: 'Crear Usuario',
  Header_Action_Column_Created: 'Creado',
  Label_Created_Actions: 'Acciones Creadas',
  Text_CreatedBy: 'Creado Por',
  Text_CreatedOn: 'Creado En',
  'Column_Created On': 'Creado En',
  'Column_Created By': 'Creado Por',
  'Column_Updated On': 'Actualizado en',
  'Column_Updated By': 'Actualizado por',
  'Column_Actualizado en': 'Actualizado en',
  'Column_Actualizado por': 'Actualizado por',
  'Column_Severity ID': 'ID de gravedad',
  'Column_Severity Name': 'Nombre de gravedad',
  Label_Created_On: 'Creado En',
  Error_createdOn: 'Creado En es Requierido',
  Toast_CreatedSuccessfully: 'Creado Exitosamente',
  'Column_Creation Mode': 'Modo de Creación',
  Label_CreationMode: 'Modo de Creación',
  Label_CustomFunctionality: 'Funcionalidad Personalizada',
  Error_CustomFunctionalityIsRequired: 'Se requiere Funcionalidad Personalizada',
  Label_Cycle: 'Ciclo',
  Nav_Menu_Dashboard: 'Tablero',
  Label_Dashboard: 'Tablero Genérico',
  Label_Dashboard_User: 'Tablero de Usuario',
  Column_Date: 'Fecha',
  Label_DateCreatedFrom: 'Fecha de Creación Desde',
  Label_DateCreatedTo: 'Fecha de Creación Hasta',
  Label_DateGeneratedFrom: 'Fecha Generada Desde',
  Label_DateGeneratedTo: 'Fecha Generada Hasta',
  Text_DateType: 'Tipo de Fecha',
  Label_DateType: 'Tipo de Fecha',
  Error_DateTypeIsRequired: 'Se requiere Tipo de Fecha',
  Label_DayCount: 'Conteo de Días',
  Error_DayCountIsRequired: 'Se requiere Conteo de Días',
  Label_DayType: 'Tipo de Día',
  Error_DayTypeIsRequired: 'Se requiere Tipo de Día',
  Column_Decision: 'Decisión',
  Tooltip_Default: 'Predeterminado',
  Label_DefaultAuthorizer: 'Autorizador Predeterminado',
  Error_DefaultManagerIsRequired: 'Se requiere Gerente Predeterminado',
  Label_DefaultManagingGroup: 'Grupo de Gestión Predeterminado',
  Label_DefaultRequestManager: 'Administrador de Solicitud Predeterminado',
  Text_DefaultRequestManager: 'Administrador de Solicitud Predeterminado',
  Error_DefaultRequestManagerIsRequired: 'Se requiere Administrador de Solicitud Predeterminado',
  Button_Delete: 'Eliminar',
  Column_Delta: 'Delta',
  Text_Description: 'Descripción',
  Label_Description: 'Descripción',
  Column_Description: 'Descripción',
  Error_DescriptionIsRequired: 'Se requiere Descripción',
  Label_Details: 'Detalles',
  Column_Details: 'Detalles',
  Label_Direction: 'Dirección',
  Label_Display: 'Visualizar',
  Header_Document: 'Documento',
  Btn_NewDocument: 'Documento',
  Header_DocumentSearch: 'Búsqueda de Documentos',
  Label_DocumentType: 'Tipo de Documento',
  'Column_Document Type': 'Tipo de Documento',
  Header_Documents: 'Documentos',
  Tab_AllDocuments: 'Documentos y Manuales de Instrucción',
  Tooltip_Download: 'Descargar',
  Label_DragDrop: 'Arrastre y suelte el archivo o haga clic aquí para seleccionar',
  Text_DropFileFromMachine: 'Suelte el archivo <1>navegue</1> por su equipo.',
  Label_Drop_Your_File_Here: 'Arrastre su archivo aquí',
  Label_Due: 'Vencimiento',
  'Column_Due Date': 'Fecha de vencimiento',
  Label_DueDate: 'Fecha de vencimiento',
  Error_DueDateRequired: 'Fecha de vencimiento es requerida',
  Text_DueDays: 'Días de vencimiento',
  Label_Duedays: 'Días de vencimiento',
  Error_DueDaysIsRequired: 'Días de vencimiento son requeridos',
  'Column_Due On': 'Vence el',
  Label_Due_On: 'Vence el',
  Label_DueOn: 'Vence el',
  Error_dueOn: 'Vence el es requerido',
  Label_Edit_Action: 'Editar Acción',
  Text_EditCompanies: 'Editar Empresas',
  Header_EditCompany: 'Editar Empresa',
  Text_EditCompany: 'Editar Empresa',
  Header_EditCountry: 'Editar Países',
  Text_EditCountries: 'Editar Países',
  Header_EditDocument: 'Editar Documento',
  Header_EditIssueType: 'Editar Tipo de Problema',
  Text_EditIssueType: 'Editar Tipo de Problema',
  Header_EditLocation: 'Editar Ubicación',
  Text_EditLocation: 'Editar Ubicación',
  Header_EditMap: 'Editar Mapeo',
  Header_EditMapping: 'Editar Mapeo',
  Text_EditMap: 'Editar Mapeo',
  Header_EditProbability: 'Editar clasificación de probabilidad',
  Text_EditProbability: 'Editar Probabilidad',
  Header_EditRegion: 'Editar Región',
  Text_EditRegion: 'Editar Región',
  Header_EditRequest: 'Editar Solicitud',
  Text_EditRequest: 'Editar Solicitud',
  Header_EditRequestCategory: 'Editar Categoría de Solicitud',
  Text_EditRequestCategory: 'Editar Categoría de Solicitud',
  Header_EditRequestCategorySourceSystemMapping: 'Editar Origen de Categoría de Solicitud',
  Text_EditRequestCategorySourceSystemMapping: 'Editar Origen de Categoría de Solicitud',
  Tooltip_EditRequestCategorySourceSystemMapping: 'Editar Origen de Categoría de Solicitud',
  Text_EditRisk: 'Editar Riesgo',
  Header_EditRiskRanking: 'Editar Clasificación de Riesgo',
  Text_EditRiskRanking: 'Editar Clasificación de Riesgo',
  Header_EditRole: 'Editar Rol',
  Tooltip_EditRole: 'Editar Rol',
  Text_EditRole: 'Editar Rol',
  Header_EditRootCause: 'Editar Causa Raíz',
  Header_EditRootCauseCategory: 'Editar Categoría de Causa Raíz',
  Header_EditRootCauseName: 'Editar Nombre de Causa Raíz',
  Text_EditRootCauseName: 'Editar Nombre de Causa Raíz',
  Header_EditRootCauseSearch: 'Editar Búsqueda de Causa Raíz',
  Text_EditRootCauseSearch: 'Editar Búsqueda de Causa Raíz',
  Header_EditRootCauses: 'Editar Causa Raíz',
  Text_EditRootCauses: 'Editar Causa Raíz',
  Text_EditRootCauseCategory: 'Editar Categoría de Causa Raíz',
  Header_EditSeverity: 'Editar Severidad',
  Text_EditSeverity: 'Editar Severidad',
  Text_EditUser: 'Editar Usuario',
  Header_EditUser: 'Editar Usuario',
  Label_Effectiveness_Verification: 'Verificación de Efectividad',
  Text_Email: 'Correo Electrónico',
  Column_EmailAddress: 'Correo Electrónico',
  Label_Email: 'Correo Electrónico',
  Text_EmailID: 'ID de correo electrónico',
  Error_EmailIsInvalid: 'El correo electrónico es inválido',
  Error_EmailIsRequired: 'El correo electrónico es requerido',
  Header_EmailSearch: 'Buscar Correo Electrónico',
  Breadcrumb_EmailSearch: 'Buscar Correo Electrónico',
  Text_EmailSubject: 'Asunto de correo electrónico',
  Text_EmailSubjectKeyword: 'Palabra clave del asunto de correo electrónico',
  Label_EmailSubjectKeyword: 'Palabra clave del asunto de correo electrónico',
  Label_EmailType: 'Tipo de correo electrónico',
  Text_EmailType: 'Tipo de correo electrónico',
  Toast_EnterCommentForVerification: 'Ingrese comentarios para la verificación',
  Label_EnterReason: 'Ingrese el motivo',
  Btn_Export: 'Exportar',
  Btn_ExportAll: 'Exportar todo',
  Btn_ExportCSV: 'Exportar CSV',
  Label_ExportData: 'Exportar Datos',
  Error_Message_ExportFailed: 'Exportación fallida',
  Info_Message_ExportSuccess: 'Éxito en la exportación',
  Btn_ExportToCSV: 'Exportar a CSV',
  Tooltip_Export_To_CSV: 'Exportar a CSV',
  Toast_FileDownloadedSuccessfully: '¡Archivo descargado exitosamente!',
  'Column_File Name': 'Nombre de archivo',
  Toast_FileUploadedSuccessfully: 'Archivo(s) subido(s) exitosamente',
  Column_Filename: 'Nombre de archivo',
  Toast_FilterItemsDisplayUpdateFailed: '¡La actualización de visualización de filtros de elementos ha fallado!',
  Toast_FilterItemsDisplayUpdateSuccess: '¡La actualización de visualización de filtros de elementos se ha realizado con éxito!',
  Label_Filter_Settings: 'Configuración de filtros',
  Column_Findings: 'Hallazgos',
  Label_Findings: 'Hallazgos',
  Text_FirstName: 'Nombre de Pila',
  Label_FirstName: 'Nombre de Pila',
  Column_FirstName: 'Nombre de Pila',
  Error_FirstNameIsRequired: 'Se requiere el Nombre de Pila',
  Label_ForgotPassword: '¿Olvidaste tu contraseña?',
  Label_Frequency: 'Frecuencia',
  Error_FrequencyIsRequired: 'Se requiere frecuencia',
  Text_From: 'Desde',
  Label_From: 'Desde',
  Column_From: 'Desde',
  Column_GroupName: 'Nombre del grupo',
  Label_FromAToZ: 'De la A a la Z',
  Label_FromDate: 'Desde la fecha',
  Label_FromZToA: 'De la Z a la A',
  Header_General: 'General',
  Tab_General: 'General',
  Column_GeneratedOn: 'Generado el',
  Label_Generic: 'Genérico',
  Label_Heading: 'Encabezado',
  Column_Headline: 'Titular',
  Btn_HideFilter: 'Ocultar filtro',
  Nav_Menu_Home: 'Inicio',
  Breadcrumb_Home: 'Inicio',
  Label_Home: 'Inicio',
  Text_ID: 'ID',
  AppTitlePrefix: 'iEval',
  Tooltip_AddObjectConfiguration: 'Agregar configuración de objeto',
  Tooltip_EditObjectConfiguration: 'Editar configuración de objeto',
  Tooltip_AddCompany: 'iEval | Agregar compañía',
  Tooltip_AddCountry: 'iEval | Agregar países',
  Tooltip_AddIssueType: 'iEval | Agregar tipo de problema',
  Tooltip_AddLocation: 'iEval | Agregar ubicación',
  Tooltip_AddMap: 'iEval | Agregar mapa',
  Tooltip_AddMapping: 'iEval | Agregar mapeo',
  Tooltip_AddProbability: 'iEval | Agregar probabilidad',
  Tooltip_AddRegion: 'iEval | Agregar región',
  Tooltip_AddRequestCategory: 'iEval | Agregar categoría de solicitud',
  Tooltip_AddRiskRanking: 'iEval | Agregar clasificación de riesgo',
  Tooltip_AddRootCause: 'iEval | Agregar causa raíz',
  Tooltip_AddRootCauseCategory: 'iEval | Agregar categoría de causa raíz',
  Tooltip_AddRootCauseName: 'iEval | Agregar nombre de causa raíz',
  Tooltip_AddSeverity: 'iEval | Agregar severidad',
  Tooltip_Companies: 'iEval | Compañías',
  Tooltip_Countries: 'iEval | Países',
  Tooltip_EditCompany: 'iEval | Editar compañía',
  Tooltip_EditCountry: 'iEval | Editar países',
  Tooltip_EditIssueType: 'iEval | Editar tipo de problema',
  Tooltip_EditLocation: 'iEval | Editar ubicación',
  Tooltip_EditMap: 'iEval | Editar mapeo',
  Tooltip_Editmapping: 'iEval | Editar mapeo',
  Tooltip_EditProbability: 'iEval | Editar probabilidad',
  Tooltip_EditRegion: 'iEval | Editar región',
  Tooltip_EditRequest: 'iEval | Editar solicitud',
  Tooltip_EditRequestCategory: 'iEval | Editar categoría de solicitud',
  Tooltip_EditRiskRanking: 'iEval | Editar clasificación de riesgo',
  Tooltip_EditRootCause: 'iEval | Editar causa raíz',
  Tooltip_EditRootCauseCategory: 'iEval | Editar categoría de causa raíz',
  Tooltip_EditRootCauseName: 'iEval | Editar nombre de causa raíz',
  Tooltip_EditRootCauses: 'iEval | Editar causa raíz',
  Tooltip_AddRootCauses: 'iEval | Agregar causa raíz',
  Tooltip_EditSeverity: 'iEval | Editar severidad',
  Tooltip_EmailSearch: 'iEval | Búsqueda de correo electrónico',
  Tooltip_IssueTypes: 'iEval | Tipos de problema',
  Tooltip_Locations: 'iEval | Ubicaciones',
  Tooltip_MappingManagement: 'iEval | Gestión de mapeo',
  Tooltip_ProbabilityRanking: 'iEval | Clasificaciones de probabilidad',
  Tooltip_Regions: 'iEval | Regiones',
  Tooltip_RequestCategories: 'iEval | Categorías de solicitud',
  Tooltip_RequestCategorySourceSystemMapping: 'iEval | Fuente de categoría de solicitud',
  Tooltip_RiskRanking: 'iEval | Clasificaciones de riesgo',
  Tooltip_RootCauseCategories: 'iEval | Categorías de causa raíz',
  Tooltip_RootCauseNameSearch: 'iEval | Búsqueda de nombre de causa raíz',
  Tooltip_RootCauseSearch: 'iEval | Búsqueda de causa raíz',
  Tooltip_RootCauses: 'iEval | Causas raíz',
  Tooltip_SentEmails: 'iEval | Correos electrónicos enviados',
  Tooltip_SeverityRanking: 'iEval | Clasificaciones de severidad',
  Tooltip_UserSearch: 'iEval | Búsqueda de usuario',
  Tab_Title_AppName: 'iEvaluate',
  Label_ImpersonateUser: 'Suplantar usuario',
  Label_Import_Non_Conformance_Data: 'Importar datos de no conformidad',
  Label_Import_PTP_Data: 'Importar datos de CPMS',
  Header_In: 'EN',
  Header_InAnalysis: 'EN ANÁLISIS',
  Label_InAnalysis: 'En Análisis',
  Header_InPrep: 'EN PREPARACIÓN',
  Header_Action_Column_Started: 'En progreso',
  Header_Action_Column_InProgress: 'En progreso',
  Label_In_Progress: 'En progreso',
  Header_InTransit: 'EN TRÁNSITO',
  Header_Info: 'Información',
  Label_Invoiced: 'Facturado',
  'Column_Is Owner Performing Office': '¿Es el propietario la oficina ejecutante?',
  Label_Owner_Performing_Office: '¿Es el propietario la oficina ejecutante?',
  'Column_Is Receipient Performing County': '¿Es el receptor de la entidad ejecutante?',
  Label_Receipient_Performing_County: '¿Es el receptor de la entidad ejecutante?',
  Text_IssueCategory: 'Categoría de problema',
  Text_IssueDescription: 'Descripción del problema',
  Label_IssueDescription: 'Descripción del problema',
  Error_IssueDescriptionIsRequired: 'Se requiere descripción del problema',
  Label_Issue_Identification: 'Identificación del problema',
  Text_IssueName: 'Nombre del problema',
  Text_IssueType: 'Tipo de problema',
  'Column_Issue Type': 'Tipo de problema',
  Label_IssueType: 'Tipo de problema',
  Text_IssueTypeID: 'ID de tipo de problema',
  Error_IssueTypeIsRequired: 'Se requiere el tipo de problema',
  Header_IssueTypes: 'Tipos de problema',
  Nav_Menu_Issue_Types: 'Tipos de problema',
  Breadcrumb_IssueTypes: 'Tipos de problema',
  Breadcrumb_ObjectConfigurations: 'Configuraciones de objetos',
  Label_Job: 'Trabajo',
  Header_JobAge: 'EDAD DEL TRABAJO',
  Header_JobInfo: 'Información del trabajo',
  Label_JobName: 'Nombre del trabajo',
  'Column_Job Name': 'Nombre del trabajo',
  Label_Job_Name: 'Nombre del trabajo',
  Label_JobNumber: 'Número de trabajo',
  Header_JobProgress: 'Progreso del trabajo',
  Label_JobProgress: 'Progreso del trabajo',
  Label_JobStatus: 'Estado del trabajo',
  Label_Jobs: 'Trabajos',
  'Column_Lab Result': 'Resultado del laboratorio',
  Error_LabResultIsRequired: 'Se requiere resultado del laboratorio',
  Label_Last3Months: 'Últimos 3 meses',
  Text_LastName: 'Apellido',
  Label_LastName: 'Apellido',
  Column_LastName: 'Apellido',
  Error_LastNameIsRequired: 'Se requiere Apellido',
  'Column_Last Reminder Date': 'Fecha del último recordatorio',
  'Column_Last Updated By': 'Última actualización por',
  Label_Last_Updated_By: 'Última actualización por',
  'Column_Last Updated On': 'Última actualización en',
  Label_Last_Updated_On: 'Última actualización en',
  Tab_LatestNews: 'Últimas noticias',
  Column_LCL: 'LCL',
  Label_Linked_Requests: 'Solicitudes vinculadas',
  Label_Load: 'Carga',
  Btn_Load5MoreLastCompletedActions: 'Cargar 5 últimas acciones completadas',
  Text_LoadingComponent: 'Cargando componente',
  Error_LoadingDataError: 'Error al cargar datos',
  Text_Location: 'Ubicación',
  Column_Location: 'Ubicación',
  Label_Location: 'Ubicación',
  Text_LocationAbbreviation: 'Abreviatura de la ubicación',
  Label_LocationAbbreviation: 'Abreviatura de la ubicación',
  Error_LocationAbbreviationIsRequired: 'Se requiere abreviatura de la ubicación',
  Label_LocationAlias: 'Alias de la ubicación',
  Text_LocationAlias: 'Alias de la ubicación',
  Error_LocationAliasIsRequired: 'Se requiere Alias de la ubicación',
  Label_LocationCode: 'Código de la ubicación',
  Text_LocationCode: 'Código de la ubicación',
  Error_LocationCodeIsRequired: 'Se requiere código de la ubicación',
  Text_LocationID: 'ID de ubicación',
  Error_LocationIsRequired: 'Se requiere ID de ubicación',
  Label_LocationName: 'Nombre de la ubicación',
  Text_LocationName: 'Nombre de la ubicación',
  Error_LocationNameIsRequired: 'Se requiere nombre de la ubicación',
  Label_LocationType: 'Tipo de ubicación',
  Text_LocationType: 'Tipo de ubicación',
  Error_LocationTypeIsRequired: 'Se requiere tipo de ubicación',
  Header_Locations: 'Ubicaciones',
  Nav_Menu_Locations: 'Ubicaciones',
  Label_Locations: 'Ubicaciones',
  Breadcrumb_Locations: 'Ubicaciones',
  Btn_Logout: 'Cerrar sesión',
  'Column_LTO Keeper': 'Responsable de LTO',
  Label_LTO_Keeper: 'Responsable de LTO',
  Btn_ManageDisplay: 'Gestionar pantalla',
  'Column_Managed By': 'Gestionado por',
  Text_ManagerType: 'Tipo de gerente',
  Label_ManagerType: 'Tipo de gerente',
  Error_ManagerTypeIsRequired: 'Se requiere tipo de gerente',
  Error_MandatoryFieldMissing: '¡Faltan Campos Obligatorios!',
  Text_MappingID: 'ID del mapa',
  Label_MappingAddTable: 'Agregar tabla de mapeo',
  Breadcrumb_MappingManagment: 'Gestión de mapeo',
  Nav_Menu_Master_Data: 'Datos Maestros',
  Error_MaxLengthIs100: 'Longitud máxima es de 100',
  Error_MaxLengthIs2: 'Longitud máxima es de 2',
  Error_MaxLengthIs3: 'Longitud máxima es de 3',
  Error_MaxLengthIs5: 'Longitud máxima es de 5',
  Error_MaxLengthIs50: 'Longitud máxima es de 50',
  Column_Mean: 'Media',
  Btn_ShowMoreComments: 'Más',
  Nav_Menu_My_Actions: 'Mis acciones',
  Breadcrumb_MyActions: 'Mis acciones',
  Header_MyActions: 'Mis acciones',
  Label_Name: 'Nombre',
  Label_New: 'Nuevo',
  Label_NewPassword: 'Nueva contraseña',
  NewUser: 'Nuevo usuario',
  News: 'Noticias',
  Header_News: 'Noticias',
  Btn_AddNews: 'Noticias',
  Tab_NewsArchived: 'Noticias archivadas',
  Header_NewsSearch: 'Buscar noticias',
  Btn_Next: 'Siguiente',
  Btn_No: 'No',
  Text_NoColumsFound: '¡No se encontraron columnas!',
  Label_NoComments: 'Sin comentarios',
  Table_NoCompanyfound: '¡No se encontró la compañía!',
  Table_NoCountryFound: '¡No se encontró el país!',
  Table_NoEmailfound: '¡No se encontró el correo electrónico!',
  Text_NoFilterFound: '¡No se encontró el filtro!',
  Table_NoIssueTypefound: '¡No se encontró el tipo de problema!',
  Table_NoItemsFound: '¡No se encontraron elementos!',
  Text_No_Items: '¡Sin elementos!',
  Table_NoLocationfound: '¡No se encontró la ubicación!',
  Table_NoMappingfound: '¡No se encontró el mapeo!',
  Text_NoPresetFound: '¡No se encontró la configuración preestablecida!',
  Table_NoProbabilityfound: '¡No se encontró la probabilidad!',
  Table_NoRegionfound: '¡No se encontró la región!',
  Table_NorequestCategoryfound: '¡No se encontró la categoría de solicitud!',
  Table_NoRequestCategoryfound: '¡No se encontró la categoría de solicitud!',
  Text_NoRequestFound: '¡No se encontró la solicitud!',
  Text_RequestIsRejected: 'La solicitud es rechazada',
  Text_ActivationInstruction: 'Para activar la solicitud, utilice el botón Reabrir en la parte inferior de la página.',
  Table_NoRiskfound: '¡No se encontró el riesgo!',
  Table_NoRootCauseCategoryFound: '¡No se encontró la categoría de causa raíz!',
  Table_NoRootCausefound: '¡No se encontró la causa raíz!',
  Table_NoRootCauseNamefound: '¡No se encontró el nombre de la causa raíz!',
  Table_NoSeverityfound: '¡No se encontró la severidad!',
  Table_NoUserfound: '¡No se encontró el usuario!',
  Label_Non_Conformance: 'Detalles de la no conformidad',
  Tab_Notifications: 'Notificaciones',
  Toast_RequestManagerAllowedToDelete: 'Solo al Responsable de la Solicitud se le permite eliminar',
  Error_OnlyUpperCaseLettersAllowed: 'Solo se permiten letras mayúsculas',
  Header_Out: 'SALIDA',
  Overview: 'Resumen',
  Error_parameterNameIsRequired: 'Se requiere nombre del parámetro',
  'Column_Past Due': 'Vencido',
  Column_PastDue: 'Vencido',
  Label_Pending_Approval: 'Aprobación pendiente',
  'Column_Performing Office': 'Oficina ejecutante',
  Label_Performing_Office: 'Oficina ejecutante',
  Label_Phone: 'Teléfono',
  Toast_FillMandatoryFields: 'Por favor, complete todos los campos obligatorios',
  Label_PostalCode: 'Código postal',
  Error_PostalCodeIsRequired: 'Código postal es requerido',
  Label_PrepStarted: 'Preparación iniciada',
  Btn_Previous: 'Anterior',
  Btn_Print: 'Imprimir',
  Text_Probability: 'Probabilidad',
  Label_Probability: 'Probabilidad',
  Label_ProbabilityEditTable: 'Editar tabla de Probabilidad',
  Text_ProbabilityID: 'ID de Probabilidad',
  Error_ProbabilityIsRequired: 'Probabilidad es requerida',
  Label_ProbabilityName: 'Nombre de Probabilidad',
  Text_ProbabilityName: 'Nombre de Probabilidad',
  Error_ProbabilityNameIsRequired: 'Nombre de Probabilidad es requerido',
  Label_Probability_Ranking: 'Ranking de Probabilidad',
  Nav_Menu_Probability_Rankings: 'Rankings de Probabilidad',
  Header_ProbabilityRanking: 'Rankings de Probabilidad',
  Text_ProbabilityRankings: 'Rankings de Probabilidad',
  Breadcrumb_ProbabilityRanking: 'Rankings de Probabilidad',
  Label_Problem_Statement: 'Declaración del problema',
  Label_ProductName: 'Nombre del producto',
  Header_Profile: 'Perfil',
  Btn_Profile: 'Perfil',
  Tab_Profile: 'Perfil',
  Label_Program: 'Programa',
  Label_Project: 'Proyecto',
  Label_Provide_Reject_Reason: 'Proporcionar motivo de rechazo',
  Label_PTP_Change_History: 'Historial de cambios CPMS',
  Label_PTP_Details: 'Detalles de CPMS',
  Label_Received: 'Recibido',
  Header_RecentNews: 'Noticias recientes',
  'Column_Recheck Value': 'Volver a Comprobar el Valor',
  'Column_Receipient User': 'Usuario Receptor',
  Label_Receipient_User: 'Usuario Receptor',
  Label_Refresh: 'Actualizar',
  Text_Region: 'Región',
  Label_Region: 'Región',
  Label_RegionCode: 'Código de Región',
  Error_RegionCodeIsRequired: 'Código de Región es requerido',
  Text_RegionID: 'ID de Región',
  Error_RegionIsRequired: 'Región es requerida',
  Error_CountryRegionLocationIsRequired: 'Se requiere país/región/ubicación',
  Text_RegionName: 'Nombre de Región',
  Label_RegionName: 'Nombre de Región',
  Header_RegionSearch: 'Buscar Región',
  Header_Regions: 'Regiones',
  Nav_Menu_Regions: 'Regiones',
  Breadcrumb_Regions: 'Regiones',
  Label_Regions: 'Regiones',
  Text_RegistrationNo: 'Número de registro',
  Label_RegistrationNo: 'Número de registro',
  Error_RegistrationNoIsRequired: 'Número de registro es requerido',
  Btn_Reject: 'Rechazar',
  Label_Rejected: 'Rechazado',
  Column_Remind: 'Recordar',
  'Column_Problem Statement': 'Declaración del problema',
  'Column_Reminder Count': 'Recuento de recordatorios',
  'Column_List Name': 'Lista de nombres',
  Toast_ReminderMailScheduled: '¡Recordatorio de correo electrónico programado!',
  Label_Reminder: 'Recordatorios',
  Btn_RemoveAll: 'Remover todo',
  Btn_TextReOpen: 'Reabrir',
  Btn_ReOpen: 'Reabrir',
  Label_ReportName: 'Nombre de informe',
  Header_ReportSearch: 'Buscar informe',
  Label_ReportType: 'Tipo de informe',
  Label_Reported: 'Reportado',
  'Column_Reported Date': 'Fecha de reporte',
  'Column_Reported On': 'Reportado en',
  Label_Reported_On: 'Reportado en',
  Label_ReportedOn: 'Reportado en',
  Error_reportedOn: 'Reportado en es requerido',
  Text_Request: 'Solicitud',
  'Column_Request Action Description': 'Descripción de acción de solicitud',
  'Column_Request Approved By': 'Solicitud aprobada por',
  Error_requestApprovedBy: 'Solicitud aprobada por es requerida',
  'Column_Request Approver': 'Aprobador de solicitud',
  Label_RequestApprover: 'Aprobador de solicitud',
  Nav_Menu_Request_Categories: 'Categorías de solicitud',
  Header_RequestCategories: 'Categorías de solicitud',
  Text_RequestCategories: 'Categorías de solicitud',
  Label_RequestCategories: 'Categorías de solicitud',
  Breadcrumb_RequestCategories: 'Categorías de solicitud',
  Text_RequestCategory: 'Categoría de solicitud',
  Label_RequestCategory: 'Categoría de solicitud',
  Label_Functionality: 'Funcionalidad',
  Label_CustomFunction: 'Función personalizada',
  'Column_Request Category': 'Categoría de solicitud',
  Label_RequestCategoryCode: 'Código de categoría de solicitud es requerido',
  Label_RequestCategoryName: 'Solicitar nombre de categoría',
  Label_ApprovedByName: 'Solicitar aprobador',
  Error_RequestCategoryGroupIsRequired: 'Grupo de categoría de solicitud es requerido',
  Text_RequestCategoryID: 'ID de categoría de solicitud',
  Error_RequestCategoryIsRequired: 'Categoría de solicitud es requerida',
  Error_RequestCategoryNameIsRequired: 'Nombre de categoría de solicitud es requerido',
  Header_RequestCategorySourceSystemMapping: 'Fuente de categoría de solicitud',
  Nav_Menu_Request_Category_Source: 'Fuente de categoría de solicitud',
  Breadcrumb_RequestCategorySourceSystemMapping: 'Fuente de categoría de solicitud',
  Error_RequestCategoryCodeIsRequired: 'Código de categoría de solicitud es requerido',
  'Column_Request Date Time': 'Fecha y hora de solicitud',
  Label_Request_Decision: 'Decisión de solicitud',
  Label_RequestDetail: 'Detalle de solicitud',
  'Column_Request ID': 'ID de solicitud',
  Toast_RequestCreatedSuccessfully: '¡Solicitud creada exitosamente!',
  'Column_Request Managed By': 'Solicitud gestionada por',
  Error_requestManagedBy: 'Solicitud gestionada por es requerido',
  Breadcrumb_RequestManagement: 'Gestión de solicitudes',
  Text_RequestManager: 'Administrador de solicitudes',
  'Column_Request Manager': 'Administrador de solicitudes',
  Label_RequestManager: 'Administrador de solicitudes',
  Label_RequestManagedBy: 'Administrador de solicitudes',
  Label_RequestManagerGroup: 'Grupo de administración de solicitudes',
  Text_RequestManagerGroup: 'Grupo de administración de solicitudes',
  Text_RequestManagingGroup: 'Grupo de gestión de solicitudes',
  Label_RequestManagingGroup: 'Grupo de gestión de solicitudes',
  Error_RequestManagingGroupIsRequired: 'Grupo de gestión de solicitudes es requerido',
  Text_RequestNumber: 'Número de solicitud',
  'Column_Request Number': 'Número de solicitud',
  Label_RequestNumber: 'Número de solicitud',
  'Column_Request Origin': 'Origen de solicitud',
  Label_Request_Origin: 'Origen de solicitud',
  'Column_Request Reason': 'Motivo de solicitud',
  Label_Request_Reason: 'Motivo de solicitud',
  Label_RequestReason: 'Motivo de solicitud',
  Label_Request_Reported_By: 'Solicitud reportada por',
  Label_RequestReportedBy: 'Solicitud reportada por',
  Error_requestReportedBy: 'Solicitud reportada por es requerido',
  'Column_Request Reporter': 'Reportador de solicitud',
  Label_RequestReporter: 'Reportador de solicitud',
  Header_RequestSearch: 'Buscar solicitud',
  Text_RequestStatus: 'Estatus de la solicitud',
  'Column_Request Status': 'Estatus de la solicitud',
  Column_RequestStatus: 'Estatus de la solicitud',
  Label_RequestStatus: 'Estatus de la solicitud',
  Text_RequestTitle: 'Título de solicitud',
  Label_Request_Title: 'Título de solicitud',
  Label_RequestTitle: 'Título de solicitud',
  'Column_Request Title': 'Título de solicitud',
  Error_RequestTitleIsRequired: 'Título de solicitud es requerido',
  Text_RequestTitleKeyword: 'Palabra clave del título de solicitud',
  'Column_Request Title Keyword': 'Palabra clave del título de solicitud',
  Label_RequestTitleKeyword: 'Palabra clave del título de solicitud',
  Label_Request_Type: 'Tipo de solicitud',
  'Column_Request Type': 'Tipo de solicitud',
  Label_RequestType: 'Tipo de solicitud',
  Label_RequestTypeDate: 'Fecha del tipo de solicitud',
  Nav_Menu_Request_Workflow: 'Flujo de trabajo de solicitud',
  Header_MappingManagement: 'Gestión de flujo de trabajo de solicitud',
  'Column_Request#': 'Solicitud#',
  Nav_Menu_Requests: 'Solicitudes',
  Error_required: 'Requerido',
  Btn_Reset: 'Reiniciar',
  Column_Result: 'Resultado',
  'Column_Result Limit': 'Límite de resultado',
  Error_ResultValueIsRequired: 'El valor del resultado es requerido',
  Error_RequestTypeIsRequired: 'Tipo de solicitud es requerido',
  Label_Review: 'Revisión',
  'Column_Reviewed On': 'Revisado en',
  'Column_Reviewed By': 'Revisado por',
  Text_RiskID: 'ID de riesgo',
  'Column_Risk Ranking': 'Clasificación de riesgo',
  Label_RiskRanking: 'Clasificación de riesgo',
  Error_RiskRankingIsRequired: 'La clasificación de riesgo es obligatoria',
  Nav_Menu_Risk_Rankings: 'Clasificaciones de riesgo',
  Breadcrumb_RiskRanking: 'Clasificaciones de riesgo',
  Text_RiskRanking: 'Clasificaciones de riesgo',
  Header_RiskRanking: 'Clasificaciones de riesgo',
  Breadcrumb_Role: 'Rol',
  Label_Role: 'Rol',
  Header_RoleAndAccess: 'Rol y acceso',
  Error_RoleIsRequired: 'El rol es obligatorio',
  Text_RoleName: 'Nombre del rol',
  Label_RoleName: 'Nombre del rol',
  Header_Roles: 'Roles',
  Nav_Menu_Roles: 'Roles',
  Label_Roles: 'Roles',
  Label_ListName: 'Lista de nombres',
  Text_RootCause: 'Causa raíz',
  'Column_Issu Description': '',
  Header_RootCauseCategories: 'Categorías de causa raíz',
  Nav_Menu_Root_Cause_Categories: 'Categorías de causa raíz',
  Breadcrumb_RootCauseCategories: 'Categorías de causa raíz',
  Text_RootCauseCategory: 'Categoría de causa raíz',
  'Column_Risk Rankings': 'Clasificaciones de riesgo',
  'Column_Root Cause Category ID': 'ID de categoría de causa raíz',
  Column_Probability: 'Probabilité',
  'Column_Probability ID': 'IDENTIFICACIÓN',
  'Column_Region ID': 'IDENTIFICACIÓN',
  'Column_Location ID': 'IDENTIFICACIÓN',
  'Column_Location Name': 'Nombre del lugar',
  'Column_Location Type': 'Tipo de ubicacion',
  'Column_Location Alias': 'Alias ​​de ubicación',
  'Column_Location Abbreviation': 'Abreviatura de ubicación',
  'Column_Tipo de problema': 'Tipo de problema',
  'Column_Descripción del problema': 'Descripción del problema',
  'Column_Código de País': 'Código de País',
  'Column_Nombre de País': 'Nombre de País',
  Column_Zona: 'Zona',
  Column_Zone: 'Zona',
  'Column_Company ID': 'IDENTIFICACIÓN',
  Column_Id: 'Identificación',
  Column_Name: 'Nombre',
  Column_ID: 'Identificación',
  'Column_Date Type': 'Tipos de fecha',
  'Column_Due Days': 'Días de vencimiento',
  'Column_E-mail Type': 'Tipo de correo electrónico',
  'Column_ID de Empresa': 'Identificación',
  'Column_ID de Probabilidad': 'Identificación',
  'Column_ID de ubicación': 'Identificación',
  'Column_ID de causa raíz': 'Identificación',
  'Column_Nombre de la Empresa': 'Nombre de la Empresa',
  'Column_Número de registro': 'Número de registro',
  'Column_Código de Empresa': 'Código de Empresa',
  'Column_Nombre de la ubicación': 'Nombre de la ubicación',
  'Column_Abreviatura de la ubicación': 'Abreviatura de la ubicación',
  'Column_Tipo de ubicación': 'Tipo de ubicación',
  'Column_Alias de la ubicación': 'Alias de la ubicación',
  'Column_Nombre de causa raíz': 'Nombre de causa raíz',
  'Column_ID de categoría de causa raíz': 'Identificación',
  'Column_Categoría de causa raíz': 'Categoría de causa raíz',
  'Column_Clasificaciones de riesgo': 'Clasificaciones de riesgo',
  Column_Probabilidad: 'Probabilidad',
  'Column_Nombre de Probabilidad': 'Nombre de Probabilidad',
  Column_Severidad: 'Severidad',
  'Column_Sistema de origen': 'Sistema de origen',
  'Column_ID de categoría de solicitud': 'Identificación',
  Column_Descripción: 'Descripción',
  'Column_Tipo de Fecha': 'Tipo de Fecha',
  'Column_ID de Región': 'Identificación',
  Column_Región: 'Región',
  Column_País: 'País',
  'Column_Días de vencimiento': 'Días de vencimiento',
  'Column_Tipo de correo electrónico': 'Tipo de correo electrónico',
  'Column_ID de severidad': 'Identificación',
  'Column_Nombre de severidad': 'Nombre de severidad',
  'Column_Categoría de solicitud': 'Categoría de solicitud',
  Column_Activo: 'Activo',
  'Column_Creado En': 'Creado En',
  'Column_Creado Por': 'Creado Por',
  'Column_Company Name': 'nombre de empresa',
  'Column_Company Code': 'Codigo de compañia',
  'Column_Registration No': 'Número de registro',
  'Column_Issue Description': 'descripcion del problema',
  'Column_Country Code': 'Código de país',
  'Column_Country Name': 'Nombre del país',
  'Column_Country Names': 'Nombres de países',
  'Column_Probability Name': 'Nombre de probabilidad',
  'Column_Root Cause Category': 'Categoría de causa raíz',
  Column_Method: 'Método',
  Column_Program_ID: 'Identificación del programa',
  'Column_E-mail Subject': 'Asunto del correo electrónico',
  'Column_Sent To': 'Enviado a',
  'Column_Sent On': 'Enviado',
  'Column_Sent By': 'Enviado por',
  'Column_Verification Status': 'Estado de verificación',
  'Column_Role Name': 'Nombre del rol',
  Label_RootCauseCategory: 'Categoría de causa raíz',
  Text_RootCauseCategoryID: 'ID de categoría de causa raíz',
  Error_RootCauseCategoryIsRequired: 'La categoría de causa raíz es obligatoria',
  Text_RootCauseCategoryManagement: 'Gestión de categorías de causa raíz',
  Text_RootCauseID: 'ID de causa raíz',
  Text_RootCauseName: 'Nombre de causa raíz',
  'Column_Root Cause Name': 'Nombre de causa raíz',
  Label_RootCauseName: 'Nombre de causa raíz',
  Text_RootCauseNameID: 'ID de nombre de causa raíz',
  Error_RootCauseNameIsRequired: 'El nombre de causa raíz es obligatorio',
  Header_RootCauseNameManagement: 'Gestión de nombres de causa raíz',
  Breadcrumb_RootCauseNameManagement: 'Gestión de nombres de causa raíz',
  Text_RootCauseNameSearch: 'Búsqueda de nombre de causa raíz',
  Header_RootCauseSearch: 'Búsqueda de causa raíz',
  Label_RootCauseSearch: 'Búsqueda de causa raíz',
  Breadcrumb_RootCauseSearch: 'Búsqueda de causa raíz',
  Text_RootCauseSearch: 'Búsqueda de causa raíz',
  Nav_Menu_Root_Causes: 'Causas raíz',
  Header_RootCauses: 'Causas raíz',
  Breadcrumb_RootCauses: 'Causas raíz',
  Label_RootCauses: 'Causas raíz',
  Label_Sample: 'Muestra',
  Header_AddObjectConfiguration: 'Agregar configuraciones de objetos',
  Header_EditObjectConfiguration: 'Editar configuraciones de objetos',
  Header_SampleAge: 'EDAD DE LA MUESTRA',
  Label_SampleDescription: 'Descripción de muestra',
  Header_SampleDetails: 'Detalles de la muestra',
  Header_SampleInfo: 'Información de la muestra',
  Label_SampleName: 'Nombre de la muestra',
  Label_SampleNumber: 'Número de la muestra',
  Header_SampleSearch: 'Búsqueda de la muestra',
  Label_SampleSource: 'Fuente de la muestra',
  Label_SampleStatus: 'Estado de la muestra',
  Label_Samples: 'Muestras',
  Label_SamplingDateFrom: 'Fecha de muestreo desde',
  Label_SamplingDateTo: 'Fecha de muestreo hasta',
  Button_Save: 'Guardar',
  Btn_Save: 'Guardar',
  Label_Save: 'Guardar',
  Button_SaveFilter: 'Guardar filtro',
  Button_SavedFilter: 'Filtro guardado',
  Label_ScheduleType: 'Tipo de programación',
  Error_ScheduleIsRequired: 'El tipo de programación es obligatorio',
  Column_Scheme: 'Esquema',
  Label_Scheme: 'Esquema',
  Label_Scheme_Other: 'Otro esquema',
  Search: 'Buscar',
  Label_SearchRequests: 'Buscar solicitudes',
  Label_SearchUser: 'Buscar usuario',
  Column_Select: 'Seleccionar',
  Placeholder_Selectanoption: 'Seleccione una opción',
  Text_SelectFile: 'Seleccionar archivo{{text}}',
  Text_FileMaxSize: 'Tamaño máximo de archivo: {{fileSize}}',
  Text_SentBy: 'Enviado por',
  Label_SentBy: 'Enviado por',
  Nav_Menu_Sent_Emails: 'Correos electrónicos enviados',
  Breadcrumb_SentEmails: 'Correos electrónicos enviados',
  Header_SentEmails: 'Correos electrónicos enviados',
  Text_SentOn: 'Enviado en',
  Text_SentTo: 'Enviado a',
  Label_SentTo: 'Enviado a',
  Btn_Settings: 'Configuración',
  Text_Severity: 'Severidad',
  Label_Severity: 'Severidad',
  Column_Severity: 'Severidad',
  Label_SeverityEditTable: 'Editar tabla de severidad',
  Text_SeverityID: 'ID de severidad',
  Error_SeverityIsRequired: 'La severidad es obligatoria',
  Text_SeverityName: 'Nombre de severidad',
  Label_SeverityName: 'Nombre de severidad',
  Error_SeverityNameIsRequired: 'El nombre de severidad es obligatorio',
  Label_Severity_Ranking: 'Clasificación de severidad',
  Nav_Menu_Severity_Rankings: 'Clasificaciones de severidad',
  Header_SeverityRanking: 'Clasificaciones de severidad',
  Breadcrumb_SeverityRanking: 'Clasificaciones de severidad',
  Btn_ShowFilter: 'Mostrar filtro',
  Text_Showing: 'Mostrando',
  Header_ShowingNActions: 'Mostrando {{n}} acciones',
  Header_ShowingNRequests: 'Mostrando {{n}} solicitud(es)',
  Header_ShowingRequests: 'Mostrando solicitudes',
  Error_SomethingWentWrong: '¡Algo salió mal!',
  Label_Sort: 'Ordenar',
  Text_SourceFilter: 'Filtro de origen',
  Text_SourceSystem: 'Sistema de origen',
  'Column_Source System': 'Sistema de origen',
  Label_SourceSystem: 'Sistema de origen',
  Error_SourceSystemIsRequired: 'El sistema de origen es obligatorio',
  Label_SourceSystems: 'Sistemas de origen',
  'Column_Spec Comparison': 'Comparación de especificaciones',
  'Column_Spec Limit': 'Límite de especificación',
  Label_Sponsor: 'Patrocinador',
  Btn_Start: 'Iniciar',
  Label_Start_Action: 'Iniciar acción',
  Label_Started: 'Iniciado',
  Label_Started_Actions: 'Acciones iniciadas',
  'Column_Started On': 'Iniciado en',
  Label_State: 'Estado',
  Error_StateIsRequired: 'El estado es obligatorio',
  Text_Status: 'Estado',
  Column_Status: 'Estado',
  'Column_Std Dev': 'Desviación estándar',
  Btn_StopImpersonating: 'Detener impersonificación',
  Column_Subject: 'Asunto',
  'Column_Subject Contact': 'Contacto de asunto',
  Label_Subject_Contact: 'Contacto de asunto',
  Label_SubjectName: 'Nombre de asunto',
  'Column_Subject Type': 'Tipo de asunto',
  Label_Subject_Type: 'Tipo de asunto',
  Btn_Submit: 'Enviar',
  Btn_SubmitForApproval: 'Enviar para aprobación',
  Label_Summary: 'Resumen',
  Label_ActionTobeimplemented: 'Acción a implementar',
  Label_CommentAction_Implemented: 'Comentario/Acción implementada',
  'Column_Target Date': 'Fecha objetivo',
  Label_Tenant: 'Inquilino',
  Label_Test: 'Prueba',
  'Column_Test Method': 'Método',
  'Column_Country of Performing Office': 'País de la oficina de ejecución',
  'Column_Contracting Entity': 'Entidad contratante',
  'Column_Country of Contracting Entity': 'País de la entidad contratante',
  Error_TestMethodNameIsRequired: 'Nombre del método de prueba es obligatorio',
  Error_StartCannotBeDone: 'Esta operación solo puede ser realizada por el propietario de la acción',
  Text_CancelRequest_Popup: 'Esto cancelará la solicitud y evitará más flujo de trabajo. ¿Desea continuar?',
  Label_Timezone: 'Zona horaria',
  Error_TimezoneIsRequired: 'La zona horaria es obligatoria',
  Label_Title: 'Título',
  Column_Title: 'Título',
  Error_TitleRequired: 'El título es obligatorio',
  Error_ActionRequired: 'Se requiere acción',
  Text_To: 'Para',
  Label_To: 'Para',
  Column_To: 'Para',
  Label_ToActionOwneruponActioncreation: 'Para el propietario de la acción al crear la acción',
  Label_FromTo: 'Hasta la fecha',
  Header_Action_Column_New: 'Por hacer',
  Label_ToRequestApproveruponRequestsubmittedforApproval: 'Para solicitar el aprobador tras la solicitud enviada para su aprobación',
  Label_ToRequestManageruponRequestManagerchange: 'Al administrador de solicitudes al cambiar el administrador de solicitudes',
  Label_ToRequestMangerUponAutoRequestcreation: 'Al administrador de solicitudes en la creación automática de solicitudes',
  Label_ToRequestMangeruponRequestbeingApproved: 'Al administrador de solicitudes una vez aprobada la solicitud',
  Label_ToRequestMangeruponRequestbeingRejected: 'Al administrador de solicitudes cuando la solicitud es rechazada',
  Label_ToRequestMangeruponVerificationsbeingcreated: 'Al administrador de solicitudes cuando se crean las verificaciones',
  Label_ToVerificationOwneruponVerificationbeingassigned: 'Al propietario de la verificación al asignar la verificación',
  Column_TPU: 'Parámetro',
  Error_TPUIsRequired: 'Se requiere parámetro',
  Label_Trigger: 'Disparador',
  Error_TriggerIsRequired: 'El disparador es obligatorio',
  Label_TurnaroundTime: 'Tiempo de respuesta',
  Axis_TurnaroundTime: 'Tiempo de respuesta (días)',
  Column_Type: 'Tipo',
  Column_UCL: 'Límite de control superior (LCS)',
  Tooltip_UserLDAPSearch: 'Haga clic en el icono para abrir la búsqueda LDAP',
  Tooltip_Unarchive: 'Desarchivar',
  Confirm_Title_UnarchiveDocument: 'Desarchivar documento: ',
  Button_Update: 'Actualizar',
  Toast_Update_Action_Successfully: 'Acción de actualización exitosa',
  Toast_UpdateFailed: 'Actualización fallida',
  Text_UpdatedBy: 'Actualizado por',
  Text_UpdatedOn: 'Actualizado en',
  Toast_UpdatedSuccessfully: 'Actualizado con éxito',
  Btn_Upload: 'Subir',
  'Column_Uploaded By': 'Subido por',
  'Column_Uploaded On': 'Subido en',
  Label_User: 'Usuario',
  Tab_UserDocuments: 'Documentos del usuario',
  Text_UserID: 'ID del usuario',
  Breadcrumb_UserManagement: 'Gestión de usuarios',
  Text_UserName: 'Nombre de usuario',
  Label_UserName: 'Nombre de usuario',
  Error_UsernameIsRequired: 'El nombre de usuario es obligatorio',
  Error_SelectRequestDecisionRequired: "Seleccione 'Solicitar decisión' para continuar",
  Header_UserSearch: 'Usuarios',
  Nav_Menu_Users: 'Usuarios',
  Nav_Menu_ObjectConfiguration: 'Configuración de objetos',
  Nav_Menu_Verification: 'Verificación',
  'Column_Verification ID': 'Identificación de verificación',
  'Column_Verification Owner': 'Propietario de verificación',
  'Column_Verified On': 'Verificado en',
  Label_View_Action: 'Acción de visualización',
  Label_Workflow: 'Flujo de trabajo',
  Btn_Ok: 'De acuerdo',
  Btn_Yes: 'Sí',
  PlaceHolder_Comments: 'Tu comentario aquí...',
  'Column_Z Score': 'Puntuación Z',
  Label_Zone: 'Zona',
  Text_Zone: 'Zona',
  Error_ZoneIsRequired: 'La zona es obligatoria',
  Label_Filters: 'filtros',
  Header_SearchUserViaLDAP: 'Buscar usuario a través de LDAP',
  Error_MissingEmail: 'Buscar usuario a través de LDAP',
};
export default spanishTranslation;
