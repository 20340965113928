import { useCallback, useEffect, useRef, useState } from 'react';
// import { Waypoint } from "react-waypoint";
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

const AsynAutoComplete = (props) => {
  const {
    label,
    pageSize = 20,
    isSearchOnType,
    selectedItem,
    isOptionEqualToValue,
    getOptionLabel,
    onValueChange,
    onFetchData,
    onTransformData,
    onFilterOptions,
    multiple,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);

  const listBoxRef = useRef(null);
  const activeRef = useRef(true);

  let onInputChangeHandler;
  let filterOptions;
  if (isSearchOnType) {
    onInputChangeHandler = (event, newInputValue) => {
      setInputValue(newInputValue);
    };

    filterOptions = (x) => x;
  }

  const fetchDataCallback = useCallback(async () => {
    if (!isOpen) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    // Get scrollbar position before adding new data
    const scrollTopNew = !listBoxRef.current ? 0 : listBoxRef.current.scrollTop;
    // Fetch Data if not yet fetched

    let data = await onFetchData({
      text: inputValue,
      page,
      pageSize,
    });

    // let data = Data || [];

    // Transform Data
    if (onTransformData) {
      data = onTransformData(data);
    }

    if (activeRef.current) {
      if (page === 1) {
        let filterOptionItems = data;
        if (onFilterOptions) {
          filterOptionItems = onFilterOptions(data);
        }

        setOptions(filterOptionItems);
      } else {
        setOptions((prepOptions) => {
          if (onFilterOptions) {
            return onFilterOptions(prepOptions.concat(data));
          }

          return prepOptions.concat(data);
        });
      }
      setHasMore(data.length >= pageSize);
      setIsLoading(false);
      setScrollTop(scrollTopNew);
    }
  }, [inputValue, isOpen, onFetchData, onFilterOptions, onTransformData, page, pageSize]);

  useEffect(() => {
    if (listBoxRef.current) {
      listBoxRef.current.scrollTo({ top: scrollTop });
    }
  }, [scrollTop]);

  useEffect(() => {
    // let active = true;
    activeRef.current = true;

    fetchDataCallback();

    // eslint-disable-next-line consistent-return
    return () => {
      // active = false;
      activeRef.current = false;
    };
  }, [fetchDataCallback, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    } else {
      setPage(1);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSearchOnType) {
      setPage(1);
    }
  }, [inputValue, isSearchOnType]);

  const onChangeHandler = (event, newValue) => {
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const loadMoreResults = () => {
    if (hasMore) {
      setPage((prepVal) => prepVal + 1);
    }
  };

  const onOpenHandler = () => {
    setIsOpen(true);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <Autocomplete
      multiple={!!multiple}
      fullWidth
      open={isOpen}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
      includeInputInList
      isOptionEqualToValue={isOptionEqualToValue}
      // isOptionEqualToValue={(option, value) =>
      //   option.ClientID === value.ClientID
      // }
      getOptionLabel={getOptionLabel}
      options={options}
      loading={isLoading}
      value={selectedItem}
      onChange={onChangeHandler}
      ListboxProps={{
        onScroll: ({ target, currentTarget }) => {
          listBoxRef.current = target;
          const listboxNode = currentTarget;
          if (parseInt((listboxNode.scrollTop + listboxNode.clientHeight).toFixed(0), 10) === listboxNode.scrollHeight) {
            loadMoreResults(target, currentTarget);
          }
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      filterOptions={filterOptions}
      onInputChange={onInputChangeHandler}
    />
  );
};

export default AsynAutoComplete;
