import { getAccessToken } from '../../utils/jwt';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.AppSettingAPIUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    // 'Accept': 'application/json',
  };

  return config;
});

const searchObjectConfigs = async ({
  objectType,
  belongsTo,
  recordId,
  page,
  pageSize,
  sortBy
}) => {
  const queryParams = [];

  if (objectType && objectType.trim() !== '') {
    queryParams.push(`objectType=${encodeURIComponent(objectType.trim())}`);
  }

  if (belongsTo && belongsTo.trim() !== '') {
    queryParams.push(`belongsTo=${encodeURIComponent(belongsTo.trim())}`);
  }

  if (recordId && recordId.trim() !== '') {
    queryParams.push(`recordId=${encodeURIComponent(recordId.trim())}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/AppSetting/objectconfig/search/by?${queryParams.join('&')}`
    );
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getObjectConfigurationsByMultiTypeAndBelongsToRecordids = async (idPairs) => {
  if (!Array.isArray(idPairs) || idPairs.length === 0) {
    return null;
  }

  try {
    const response = await axiosInstance.post('/AppSetting/objectconfigs/byPair', idPairs);

    return response.data;
  } catch (e) {
    throw new Error('Error search Object Configurations');
  }
};

const insertOrUpdateObjectConfiguration = async ({
  objectType,
  belongsTo,
  recordID,
  fields,
  updatedBy,
}) => {
  try {
    const response = await axiosInstance.post('/AppSetting/objectconfigs/save', {
      ObjectType: objectType,
      BelongsTo: belongsTo,
      RecordID: `${recordID}`,
      Config: JSON.stringify(fields),
      UpdatedBy: updatedBy,
    });

    return response.data;
  } catch (e) {
    throw new Error('Error insertOrUpdateObjectConfiguration');
  }
};

const getObjectConfigByObjectConfigId = async ({ ObjectConfigurationID }) => {
  try {
    const response = await axiosInstance.get(
      `AppSetting/ObjectConfig/ObjectConfigID/${ObjectConfigurationID}/Detail`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getObjectConfigByObjectConfigId');
  }
};
const insertObjectConfig = async (requestPayload) => {
  try {
    const response = await axiosInstance.post('AppSetting/ObjectConfig/Insert', requestPayload);

    return response.data;
  } catch (error) {
    throw new Error('Error insertObjectConfig');
  }
};
const updateObjectConfig = async (requestPayload) => {
  try {
    const response = await axiosInstance.post('AppSetting/ObjectConfig/Update', requestPayload);

    return response.data;
  } catch (error) {
    throw new Error('Error updateObjectConfig');
  }
};

const deleteObjectConfig = async (objectConfigurationID) => {
  try {
    const response = await axiosInstance.post(`AppSetting/ObjectConfig/ObjectConfigID/${objectConfigurationID}/Delete`);
    return response.data;
  } catch (e) {
    throw new Error('Error');
  }
};
const AppSettingApi = {
  getObjectConfigurationsByMultiTypeAndBelongsToRecordids,
  insertOrUpdateObjectConfiguration,
  getObjectConfigByObjectConfigId,
  insertObjectConfig,
  updateObjectConfig,
  searchObjectConfigs,
  deleteObjectConfig
};

export default AppSettingApi;
