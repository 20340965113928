import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';
import { SortDirection } from '../../../../utils/constants';

const initialFilterState = {
  SentTo: '',
  SentOn: '',
  EmailSubjectKeyword: '',
  RequestNumber: '',
  RequestTitleKeyword: '',
  SentBy: '',
  From: '',
  To: '',

  Location: null,
  EmailType: null,
  RequestStatus: null,
  RequestCategory: null,
  ManagedByName: null
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'SentOn',
  sortDirection: SortDirection.Asc,
};

export const filterFields = {
  SentTo: 'SentTo',
  SentOn: 'SentOn',
  EmailSubjectKeyword: 'EmailSubjectKeyword',
  RequestNumber: 'RequestNumber',
  Location: 'Location',
  EmailType: 'EmailType',
  RequestTitleKeyword: 'RequestTitleKeyword',
  RequestStatus: 'RequestStatus',
  RequestCategory: 'RequestCategory',
  SentBy: 'SentBy',
  From: 'From',
  To: 'To',
  ManagedByName: 'ManagedByName'
};

export const sliceName = 'emailSearch';

const emailSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
},);

export const emailSearchActions = emailSearchSlice.actions;
export default emailSearchSlice.reducer;
