import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  SeverityName: 'FirstName',
  RequestCategory: 'RequestCategory',
  Active: 'Active',
};

export const severitySearchSliceName = 'severitySearch';

const severitySearchSlice = createSearchPageSlice({
  sliceName: severitySearchSliceName,
  filterFields,
  initialFilterState: {
    SeverityName: '',
    RequestCategory: null,
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'ProbabilitySeverityID',
    sortDirection: SortDirection.Asc,
  },
});

export const severitySearchActions = severitySearchSlice.actions;
export default severitySearchSlice.reducer;
