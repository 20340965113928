export const ObjectTypes = {
  RecentJobGrid: 'RecentJobGrid',
  SampleSearchGrid: 'SampleSearchGrid',
  RootCauseSearchGrid: 'RootCauseSearchGrid',
  RoleSearchGrid: 'RoleSearchGrid',
  DocumentSearchGrid: 'DocumentSearchGrid',
  RequestSearchGrid: 'RequestSearchGrid',
  ObjectConfigurationSearchGird: 'ObjectConfigurationSearchGird',
  ActionSearchGrid: 'ActionSearchGrid',
  ListSearchGrid: 'ListSearchGrid',
  IssueTypeSearchGrid: 'IssueTypeSearchGrid',
  LocationSearchGrid: 'LocationSearchGrid',
  CountrySearchGrid: 'CountrySearchGrid',
  GroupSearchGrid: 'GroupSearchGrid',
  GroupSearchUserFilterItemSetting: 'GroupSearchUserFilterItemSetting',
  GroupsSearchUserFilter: 'GroupsSearchUserFilter',
  RootCauseCategorySearchGrid: 'RootCauseCategorySearchGrid',
  RequestCategorySourceGrid: 'RequestCategorySourceGrid',
  EmailSearchGrid: 'EmailSearchGrid',
  RiskRankingGrid: 'RiskRankingGrid',
  RoleSearchUserFilter: 'RoleSearchUserFilter',
  RoleSearchUserFilterItemSetting: 'RoleSearchUserFilterItemSetting',
  NewsSearchUserFilter: 'NewsSearchUserFilter',
  DocumentSearchUserFilter: 'DocumentSearchUserFilter',
  RootCauseSearchUserFilterItemSetting: 'RootCauseSearchUserFilterItemSetting',
  RootCauseSearchUserFilter: 'RootCauseSearchUserFilter',
  LocationSearchUserFilterItemSetting: 'LocationSearchUserFilterItemSetting',
  LocationSearchUserFilter: 'LocationSearchUserFilter',
  RegionManagementUserFilterItemSetting: 'RegionManagementUserFilterItemSetting',
  RegionManagementUserFilter: 'RegionManagementUserFilter',
  RegionManagementGrid: 'RegionManagementGrid',
  RootCauseNameSearchUserFilterItemSetting: 'RootCauseNameSearchUserFilterItemSetting',
  RootCauseNameSearchUserFilter: 'RootCauseNameSearchUserFilter',
  ProbabilitySearchUserFilterItemSetting: 'ProbabilitySearchUserFilterItemSetting',
  ProbabilitySearchUserFilter: 'ProbabilitySearchUserFilter',
  SeveritySearchUserFilterItemSetting: 'SeveritySearchUserFilterItemSetting',
  SeveritySearchUserFilter: 'SeveritySearchUserFilter',
  CompanySearchUserFilterItemSetting: 'CompanySearchUserFilterItemSetting',
  CompanySearchUserFilter: 'CompanySearchUserFilter',
  IssueTypeSearchUserFilterItemSetting: 'IssueTypeSearchUserFilterItemSetting',
  IssueTypeSearchUserFilter: 'IssueTypeSearchUserFilter',
  CountrySearchUserFilterItemSetting: 'CountrySearchUserFilterItemSetting',
  CountrySearchUserFilter: 'CountrySearchUserFilter',
  RootCauseCategorySearchUserFilterItemSetting: 'RootCauseCategorySearchUserFilterItemSetting',
  RootCauseCategorySearchUserFilter: 'RootCauseNameSearchUserFilter',
  RequestCategorySourceUserFilterItemSetting: 'RequestCategorySourceUserFilterItemSetting',
  RequestCategorySourceUserFilter: 'RequestCategorySourceUserFilter',
  EmailSearchUserFilterItemSetting: 'EmailSearchUserFilterItemSetting',
  RequestUserFilterItemSetting: 'RequestUserFilterItemSetting',
  ObjectConfigurationFilterItemSetting: 'ObjectConfigurationFilterItemSetting',
  RequestUserFilter: 'RequestUserFilter',
  ObjectConfigurationSavedFilter: 'ObjectConfigurationSavedFilter',
  EmailSearchUserFilter: 'EmailSearchUserFilter',
  RequestCategorySearchGrid: 'RequestCategorySearchGrid',
  RequestCategorySearchUserFilterItemSetting: 'RequestCategorySearchUserFilterItemSetting',
  RequestCategorySearchUserFilter: 'RequestCategorySearchUserFilter',
  MappingSearchGrid: 'MappingSearchGrid',
  MappingSearchUserFilterItemSetting: 'MappingSearchUserFilterItemSetting',
  MappingSearchUserFilter: 'MappingSearchUserFilter',
  RiskRankingUserFilterItemSetting: 'RiskRankingUserFilterItemSetting',
  RiskRankingUserFilter: 'RiskRankingUserFilter',
  ActionSearchUserFilterItemSetting: 'ActionSearchUserFilterItemSetting',
  ListSearchUserFilterItemSetting: 'ListSearchUserFilterItemSetting',
  ListSearchUserFilter: 'ListSearchUserFilter',
  ActionSearchUserFilter: 'ActionSearchUserFilter',
  VerificationSearchUserFilterItemSetting: 'VerificationSearchUserFilterItemSetting',
  VerificationSearchUserFilter: 'VerificationSearchUserFilter',
  RootCauseNameSearchGrid: 'RootCauseNameSearchGrid',
  CompanySearchGrid: 'CompanySearchGrid',
  ProbabilitySearchGrid: 'ProbabilitySearchGrid',
  SeveritySearchGrid: 'SeveritySearchGrid',
  VerificationSearchGrid: 'VerificationSearchGrid',
  RoleManagementUserFilterItemSetting: 'RoleManagementUserFilterItemSetting',
  MyAnnouncementsUserFilter: 'MyAnnouncementsUserFilter',
  MyAnnouncementsUserFilterItemSetting: 'MyAnnouncementsUserFilterItemSetting',
  MyAnnouncementsSearchGrid: 'MyAnnouncementsSearchGrid',

  UserSearchGrid: 'UserSearchGrid',
  UserSearchUserFilter: 'UserSearchUserFilter',
  UserSearchUserFilterItemSetting: 'UserSearchUserFilterItemSetting',
  DashboardSearchGrid: 'DashboardSearchGrid',
  DashboardSearchUserFilterItemSetting: 'DashboardSearchUserFilterItemSetting',

  RequestCategoryHiddenFieldList: 'RequestCategoryHiddenFieldList',
  AnnouncementsUserFilter: 'AnnouncementsUserFilter',
  AnnouncementsUserFilterItemSetting: 'AnnouncementsUserFilterItemSetting',
  AnnouncementsSearchGrid: 'AnnouncementsSearchGrid',

  FieldSetting_EditRequestDetails: 'FieldSetting_EditRequestDetails',
};
