import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  AnnouncementNumber: 'AnnouncementNumber',
  Title: 'Title',
  Type: 'Type',
  Validity: 'Validity',
  Scope: 'Scope',
  BroadcastedBy: 'BroadcastedBy',
  Region: 'Region',
  Location: 'Location',
  User: 'User',
  Freereferencenumber: 'Freereferencenumber',
  BusinessLine: 'BusinessLine',
  Readreceipt: 'Readreceipt',
  Read: 'Read',
  Closed: 'Closed',
  IsTemplate: 'IsTemplate'
};
const initialFilterState = {
  AnnouncementNumber: '',
  Title: '',
  Type: null,
  Validity: null,
  Scope: null,
  BroadcastedBy: null,
  Region: null,
  Location: null,
  User: null,
  Freereferencenumber: '',
  BusinessLine: null,
  Readreceipt: 'All',
  Read: 'All',
  Closed: 'All',
  IsTemplate: 'All',
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'AnnouncementNo',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'announcementsSearch';
const announcementsSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});
export const announcementsActions = announcementsSearchSlice.actions;
export default announcementsSearchSlice.reducer;
