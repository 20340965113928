import { SortDirection } from '../../../../../utils/constants';
import { createSearchPageSlice } from '../../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  RoleName: 'RoleName',
  Description: 'Description',
  Level: 'Level',
  Active: 'Active',
};

export const roleSliceName = 'roleSearch';

const roleSearchSlice = createSearchPageSlice({
  sliceName: roleSliceName,
  filterFields,
  initialFilterState: {
    RoleName: '',
    Description: '',
    Level: 'Low',
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RoleName',
    sortDirection: SortDirection.Asc,
  },
});

export const roleSearchActions = roleSearchSlice.actions;
export default roleSearchSlice.reducer;
