import styles from './LoadingPage.module.css';
import LegalBar from '../layouts/LegalBar';
import Navbar from '../layouts/navigation/NavBar';
import Sidebar from '../layouts/navigation/Sidebar';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const LayoutRoot = styled('div')(({ theme }) => {
  const svgPath = theme.palette.mode === 'dark' ? '/static/images/wave_d.svg' : '/static/images/wave.svg';
  return {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    background: `url('${svgPath}') no-repeat bottom`,
    backgroundSize: '100% auto'
  };
});

const LayoutWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  // [theme.breakpoints.up("lg")]: {
  //   paddingLeft: "280px",
  // },
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  height: '100%',
  flexDirection: 'column',
});

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  // paddingBottom: '48px',
});

const LoadingText = styled('h1')(({ theme }) => ({
  position: 'relative',
  fontSize: '1.5em',
  color: theme.palette.text.secondary,
  // marginLeft: '-20px',
  '&::before': {
    content: "'Loading...'",
    position: 'absolute',
    overflow: 'hidden',
    maxWidth: '7em',
    whiteSpace: 'nowrap',
    animation: 'loading 4s linear',
    color: theme.palette.text.primary,
  },
  '@keyframes loading': {
    '0%': {
      maxWidth: 0,
    },
  },
}));

const LoadingPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSideBarHandler = () => {
    setIsSidebarOpen((prepValue) => !prepValue);
  };

  return (
    <LayoutRoot>
      <Navbar onSidebarMobileOpen={toggleSideBarHandler} open={isSidebarOpen} />
      <Sidebar
        onSidebarClose={() => setIsSidebarOpen(false)}
        isSidebarOpen={isSidebarOpen}
      />
      <LayoutWrapper>
        <LayoutContainer>
          <LayoutContent>
            <div className={styles.v_align}>
              <div className={styles.loader}>
                <aside
                  className={`${styles.loader__box} ${styles.loader__left}`}
                >
                  <span className={styles.loader__circle} />
                </aside>
                <aside
                  className={`${styles.loader__box} ${styles.loader__right}`}
                >
                  <span className={styles.loader__circle} />
                </aside>
              </div>

              <LoadingText>Loading...</LoadingText>
            </div>
          </LayoutContent>
          <LegalBar linkColor="text.primary" hideBottomBorder />
        </LayoutContainer>
      </LayoutWrapper>
    </LayoutRoot>
  );
};

export default LoadingPage;
