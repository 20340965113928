import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  Risk: 'Risk',
  RequestCategory: 'RequestCategory',
  Probability: 'Probability',
  Severity: 'Severity',
};

export const riskRankingSliceName = 'riskRanking';

const riskRankingSlice = createSearchPageSlice({
  sliceName: riskRankingSliceName,
  filterFields,
  initialFilterState: {
    Risk: '',
    RequestCategory: null,
    Probability: null,
    Severity: null,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'Risk',
    sortDirection: SortDirection.Asc,
  },
});

export const riskRankingActions = riskRankingSlice.actions;
export default riskRankingSlice.reducer;
