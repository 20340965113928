import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  ProbabilityName: 'ProbabilityName',
  RequestCategory: 'RequestCategory',
  Active: 'Active',
};

const initialFilterState = {
  ProbabilityName: '',
  RequestCategory: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'ProbabilitySeverityID',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'probabilitySearch';
const probabilitySearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const probabilitySearchActions = probabilitySearchSlice.actions;
export default probabilitySearchSlice.reducer;
