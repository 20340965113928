import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  RegionName: 'RegionName',
  Country: 'Country',
  Active: 'Active',
};

const initialFilterState = {
  RegionName: '',
  Country: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'RegionCode',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'regionManagement';
const regionManagementSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const regionManagementActions = regionManagementSlice.actions;
export default regionManagementSlice.reducer;
