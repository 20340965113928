import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '../../../asset/icons/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AccountPopover from '../AccountPopover/AccountPopover';
// import ContentSearch from "./ContentSearch";
import ThemeIcon from '../ThemeIcon';
import LanguagePopover from '../LanguagePopover';
// import NotificationsPopover from "./NotificationsPopover";

export const openDrawerWidth = 270;
export const closeDrawerWidth = 80;
export const navBarHeight = 64;
export const drawerWidth = 270;

const NavbarRoot = styled(AppBar)(({ theme, open }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: "none",
    // boxShadow: theme.shadows,
    boxShadow: 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px',

    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  backgroundColor: theme.palette.background.paper,
  boxShadow: open ? 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px' : 'none',
  zIndex: theme.zIndex.drawer,
  width: '100%', // Set width to 100% by default
  [theme.breakpoints.up('lg')]: {
    marginLeft: open ? openDrawerWidth : closeDrawerWidth,
    width: `calc(100% - ${open ? openDrawerWidth : closeDrawerWidth}px)`, // Adjust width based on sidebar open/close
  },
  // In mobile mode, when the sidebar is closed, set width to 100%
  [theme.breakpoints.down('lg')]: {
    width: open ? `calc(100% - ${openDrawerWidth}px)` : '100%',
  },
}));
const Navbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { open } = props;
  const menuBarIcon = (
    <IconButton color="default" onClick={onSidebarMobileOpen}>
      {open && <MenuOpenIcon sx={{ fontSize: 34 }} />}
      {!open && <MenuIcon sx={{ fontSize: 32 }} />}
      {/* <MenuIcon fontSize="medium" /> */}
    </IconButton>
  );

  return (
    <NavbarRoot {...other}>
      <Toolbar sx={{ minHeight: navBarHeight }}>
        {menuBarIcon}
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <LanguagePopover />
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}

        <Box sx={{ ml: 2 }}>
          <ThemeIcon />
        </Box>

        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </NavbarRoot>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
