import { createSlice } from '@reduxjs/toolkit';
import { objFromArray } from '../../../../utils/objFromArray';

const initialState = {
  isLoaded: false,
  selectedCard: null,
  columns: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: [],
  },
};

const myActionsSlice = createSlice({
  name: 'myActions',
  initialState,
  reducers: {
    getBoard(state, action) {
      const board = action.payload;
      state.columns.byId = objFromArray(board.Columns, 'ColumnID');
      state.columns.allIds = Object.keys(state.columns.byId);

      state.cards.byId = objFromArray(board.Cards, 'CardID');
      state.cards.allIds = Object.keys(state.cards.byId);

      state.isLoaded = true;
    },

    updateCardStatus(state, action) {
      const card = action.payload;

      const { CardID, ColumnID } = card;

      const existingCard = state.cards.byId[CardID];

      if (!existingCard) {
        state.cards.byId = { ...state.cards.byId, [CardID]: card };
        state.cards.allIds = Object.keys(state.cards.byId);
        state.columns.byId[ColumnID].CardIDs.push(CardID);
      } else {
        // Remove card from existing column
        state.cards.byId = { ...state.cards.byId, [CardID]: card };

        // Copy comment from prev state
        state.cards.byId[CardID].Comments = existingCard.Comments;
        state.columns.byId[existingCard.ColumnID].CardIDs = state.columns.byId[
          existingCard.ColumnID
        ].CardIDs.filter((_cardId) => _cardId !== CardID);

        // Add card to new column
        if (!state.columns.byId[ColumnID].CardIDs.includes(CardID)) {
          state.columns.byId[ColumnID].CardIDs.push(CardID);
        }
      }

      if (card.StatusName === 'Completed') {
        state.columns.byId[ColumnID].CardIDs.sort((c1, c2) => {
          const card1 = state.cards.byId[c1];
          const card2 = state.cards.byId[c2];
          if (!card1.CompletedOn && !card2.CompletedOn) {
            return 1;
          }

          return new Date(card2.CompletedOn) - new Date(card1.CompletedOn);
        });
      } else {
        state.columns.byId[ColumnID].CardIDs.sort((c1, c2) => {
          const card1 = state.cards.byId[c1];
          const card2 = state.cards.byId[c2];
          if (!card1.DueDate && !card2.DueDate) {
            return 1;
          }

          return new Date(card1.DueDate) - new Date(card2.DueDate);
        });
      }
    },

    selectAction(state, action) {
      state.selectedCard = action.payload;
    },
    addNewCards(state, action) {
      const cards = action.payload || [];

      if (Array.isArray(cards)) {
        cards.forEach((card) => {
          if (!state.cards.byId[card.CardID]) {
            state.cards.byId[card.CardID] = card;
            state.columns.byId[card.ColumnID].CardIDs.push(card.CardID);
          }
        });

        state.cards.allIds = Object.keys(state.cards.byId);
      }
    },
    updateCardViewWhenDragAndDrop(state, action) {
      const { columnId, cardId, destinationCol } = action.payload || [];

      if (Array.isArray(state.columns.byId[columnId]['CardIDs'])) {
        const cardList = Object.assign([], state.columns.byId[columnId]['CardIDs']);
        // To remove card from the source column
        const updatedCardList = cardList?.filter((r) => !(r === Number(cardId)));
        state.columns.byId[columnId]['CardIDs'] = updatedCardList;
        // To add card into the destination column
        state.columns.byId[destinationCol].CardIDs.push(Number(cardId));
      }
    },
    addNewComment(state, action) {
      const newComment = action.payload;

      const cardID = newComment.CardID;

      if (cardID > 0) {
        const existingCard = state.cards.byId[cardID];
        if (existingCard) {
          existingCard.Comments.unshift(newComment);
        }
      }
    },

    moveCard(state, action) {
      const { cardId, position, columnId } = action.payload;

      const sourceColumnId = state.cards.byId[cardId].ColumnID;

      // - Remove card from source column
      state.columns.byId[sourceColumnId].CardIDs = state.columns.byId[
        sourceColumnId
      ].CardIDs.filter((_cardId) => _cardId !== cardId);

      // - If columnId exists, it means that we have to add the card to the new column
      if (columnId) {
        // - Change card's columnId reference
        state.cards.byId[cardId].ColumnID = columnId;
        // - Push the cardId to the specified position
        state.columns.byId[columnId].CardIDs.splice(position, 0, cardId);
      } else {
        // - Push the cardId to the specified position
        state.columns.byId[sourceColumnId].CardIDs.splice(position, 0, cardId);
      }
    },
  },
});

export const myActionsAction = myActionsSlice.actions;
export default myActionsSlice.reducer;

export const columnNameObj = {
  Todo: 'Created',
  Inprogress: 'Started',
  Completed: 'Completed',
};
