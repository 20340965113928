import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import useMounted from '../../../../hooks/useMounted';
// import useAuth from '../../../../hooks/useAuth';
import LoginIcon from '@mui/icons-material/Login';

import { alpha, styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { login } from '../../../../store/actions/authActions';

const textColor = alpha('#f4f5f7', 0.8);
const CustomTextField = styled(TextField)({
  '& input': {
    color: textColor,
  },
  '& label.Mui-focused': {
    color: textColor,
  },
  // "& label.Mui-error": {
  //   color: "#CF6679",
  // },
  '& .MuiInput-underline:after': {
    borderBottomColor: textColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: textColor,
    },

    // "& fieldset.Mui-error": {
    //   borderColor: "#CF6679!important",
    // },
    '&:hover fieldset': {
      borderColor: '#6b778c',
    },
    '&.Mui-focused fieldset': {
      borderColor: textColor,
    },
  },
});

const LoginForm = (props) => {
  const mounted = useMounted();
  // const { login } = useAuth();
  const dispatch = useDispatch();

  const onLoginHandler = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      await dispatch(login({ username: values.username, password: values.password }));
      // await login(values.username, values.password);
      if (mounted.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required('Username is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={onLoginHandler}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} {...props} style={{ opacity: 1 }}>
          <CustomTextField
            autoFocus
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            label="Username"
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="username"
            value={values.username}
            variant="outlined"
          />

          <CustomTextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              startIcon={<LoginIcon />}
              sx={{
                color: alpha('#FFFFFF', 0.8),
                background: 'linear-gradient(to right, rgba(145, 133, 190, 0.5) 30%, rgba(44, 50, 255, 0.7) 100%)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 176, 240, 0.5)',
                  color: alpha('#E1E1E1', 0.8),
                }
              }}
            >
              Log In
            </Button>
          </Box>

          {/* <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              <div>
                Use <b>demo@devias.io</b> and password <b>Password123!</b>
              </div>
            </Alert>
          </Box> */}
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
