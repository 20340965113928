import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  RequestNumber: 'RequestNumber',
  RequestTitleKeyword: 'RequestTitleKeyword',
  RequestType: 'RequestType',
  RequestCategory: 'RquestCategory',
  IssueType: 'IssueType',
  Location: 'Location',
  RequestStatus: 'RequestStatus',
  RootCauseCategory: 'RootCauseCategory',
  RootCauseName: 'RootCauseName',
  SourceSystem: 'SourceSystem',
  CreationMode: 'CreationMode',
  RiskRanking: 'RiskRanking',
  RequestReporter: 'RequestReporter',
  RequestManager: 'RequestManager',
  RequestApprover: 'RequestApprover',
  ActionOwner: 'ActionOwner',
  Company: 'Company',
  Country: 'Country',
  BusinessLine: 'BusinessLine',
  Scheme: 'Scheme',
  Sponsor: 'Sponsor',
  Program: 'Program',
  Cycle: 'Cycle',
  Sample: 'Sample',
  Test: 'Test',
  JobName: 'JobName',
  SubjectName: 'SubjectName',
  RequestDateType: 'RequestDateType',
  From: 'From',
  To: 'To',
  Active: 'Active',
};

const initialFilterState = {
  RequestNumber: '',
  RequestTitleKeyword: '',
  RequestType: null,
  RequestCategory: [],
  IssueType: null,
  Location: null,
  RequestStatus: null,
  RootCauseCategory: [],
  RootCauseName: null,
  SourceSystem: null,
  CreationMode: null,
  RiskRanking: null,
  RequestDateType: null,
  RequestReporter: [],
  RequestManager: [],
  RequestApprover: [],
  ActionOwner: null,
  Company: null,
  Country: null,
  BusinessLine: null,
  Scheme: null,
  Sponsor: null,
  Program: '',
  Cycle: '',
  Sample: '',
  Test: '',
  JobName: '',
  SubjectName: '',
  From: '',
  To: '',
  Active: true,
};

export const requestSearchSliceName = 'requestSearch';

const requestSearchSlice = createSearchPageSlice({
  sliceName: requestSearchSliceName,
  filterFields,
  initialFilterState,
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RequestID',
    sortDirection: SortDirection.Desc,
  },
});

export const requestSearchActions = requestSearchSlice.actions;
export default requestSearchSlice.reducer;
