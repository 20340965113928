import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  SourceSystem: 'SourceSystem',
  RequestCategory: 'RequestCategory',
  BusinessLine: 'BusinessLine',
  Location: 'Location',
  Country: 'Country',
  DefaultRequestManager: 'DefaultRequestManager',
  RequestManagingGroup: 'RequestManagingGroup',
};

export const mappingSearchSliceName = 'mappingSearch';

const mappingSearchSlice = createSearchPageSlice({
  sliceName: mappingSearchSliceName,
  filterFields,
  initialFilterState: {
    FirstName: '',
    LastName: '',
    Email: '',
    Role: null,
    RequestCategory: null,
    Country: null,
    Region: null,
    Location: null,
    BusinessLine: null,
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RequestWorkflowID',
    sortDirection: SortDirection.Asc,
  },
});

export const mappingSearchActions = mappingSearchSlice.actions;
export default mappingSearchSlice.reducer;
