import useAuth from '../../../hooks/useAuth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CallToActionIcon from '@mui/icons-material/CallToAction';

import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';

import {
  PermissionCodes,
  PermissionRights,
} from '../../Guards/PermissionGuard/PermissionGuard';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SouthAmericaRoundedIcon from '@mui/icons-material/SouthAmericaRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';

const useNavSections = () => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuth();

  const [authorizedSections, setAuthorizedSections] = useState([]);

  const sections = useMemo(() => [
    {
      title: t('Nav_Menu_Home'),
      path: '/',
      icon: <HomeIcon fontSize="small" />,
      items: [
        {
          title: t('Nav_Menu_Dashboard'),
          icon: <DashboardIcon />,
          path: '/dashboard',
        },
        {
          title: t('Nav_Menu_Requests'),
          icon: <AssignmentRoundedIcon />,
          path: '/requests',
          permission: PermissionCodes.Request,
        },
        {
          title: t('Nav_Menu_Create_Request'),
          icon: <AddCircleRoundedIcon />,
          path: '/createRequest',
          permission: PermissionCodes.Request,
        },
        {
          title: t('Nav_Menu_Sent_Emails'),
          icon: <MarkEmailReadRoundedIcon />,
          path: '/sentEmails',
          permission: PermissionCodes.Email,
        },
        { title: 'My Announcements',
          icon: <CampaignIcon />,
          path: '/myannouncements',
          permission: PermissionCodes.MyAnnouncement,
        },
        {
          title: t('Nav_Menu_Verification'),
          icon: <CheckCircleOutlineIcon />,
          path: '/verification',
          permission: PermissionCodes.Verification,
        },
      ],
    },
    {
      title: t('Nav_Menu_Ation_Center'),
      path: '/',
      icon: <CallToActionIcon fontSize="small" />,
      items: [
        {
          title: t('Nav_Menu_Actions'),
          icon: <CampaignIcon />,
          path: '/actions',
          permission: PermissionCodes.Action,
        },
        {
          title: t('Nav_Menu_My_Actions'),
          icon: <AssignmentTurnedInRoundedIcon />,
          permission: PermissionCodes.MyActions,
          path: '/myactions',
        },
      ],
    },
    {
      title: t('Nav_Menu_Master_Data'),
      icon: <SettingsIcon fontSize="small" />,
      items: [
        {
          title: t('Nav_Menu_Companies'),
          icon: <BusinessRoundedIcon />,
          path: '/companies',
          permission: PermissionCodes.Company,
        },
        {
          title: t('Nav_Menu_Countries'),
          icon: <PublicRoundedIcon />,
          path: '/countries',
          permission: PermissionCodes.Country,
        },
        {
          title: t('Nav_Menu_Issue_Types'),
          icon: <BugReportRoundedIcon />,
          path: '/issueTypes',
          permission: PermissionCodes.IssueType,
        },
        {
          title: t('Nav_Menu_List'),
          icon: <FormatListBulletedIcon />,
          path: '/lists',
          permission: PermissionCodes.List,
        },
        {
          title: t('Nav_Menu_Locations'),
          icon: <LocationOnRoundedIcon />,
          path: '/locations',
          permission: PermissionCodes.Location,
        },
        {
          title: t('Nav_Menu_Probability_Rankings'),
          icon: <ErrorRoundedIcon />,
          path: '/probabilityRankings',
          permission: PermissionCodes.Probability,
        },
        {
          title: t('Nav_Menu_Regions'),
          icon: <SouthAmericaRoundedIcon />,
          path: '/regions',
          permission: PermissionCodes.Region,
        },
        {
          title: t('Nav_Menu_Request_Categories'),
          icon: <CategoryRoundedIcon />,
          path: '/RequestCategories',
          permission: PermissionCodes.RequestCategory,
        },
        {
          title: t('Nav_Menu_Request_Category_Source'),
          icon: <CategoryRoundedIcon />,
          path: '/requestCategorySourceMapping',
          permission: PermissionCodes.RequestCategorySource,
        },
        {
          title: t('Nav_Menu_Risk_Rankings'),
          icon: <ReportRoundedIcon />,
          path: '/riskRankings',
          permission: PermissionCodes.Risk,
        },
        {
          title: t('Nav_Menu_Root_Causes'),
          icon: <ImportContactsIcon />,
          path: '/rootCauses',
          permission: PermissionCodes.RootCause,
        },
        {
          title: t('Nav_Menu_Root_Cause_Categories'),
          icon: <ClassRoundedIcon />,
          path: '/rootCauseCategories',
          permission: PermissionCodes.RootCauseCategory,
        },
        {
          title: t('Nav_Menu_Severity_Rankings'),
          icon: <WarningRoundedIcon />,
          path: '/severities',
          permission: PermissionCodes.Severity,
        },
        { title: t('Groups'),
          icon: <GroupsIcon />,
          path: '/groups',
          permission: PermissionCodes.Group,
        },
        { title: 'Announcements',
          icon: <CampaignIcon />,
          path: '/announcements',
          permission: PermissionCodes.Announcement,
        },
      ],
    },
    {
      title: t('Nav_Menu_Administrator'),
      icon: <ManageAccountsIcon fontSize="small" />,
      items: [
        {
          title: t('Nav_Menu_Request_Workflow'),
          icon: <BookmarkAddedRoundedIcon />,
          path: '/mappings',
          permission: PermissionCodes.Mapping,
        },
        {
          title: t('Nav_Menu_Roles'),
          path: '/roles',
          icon: <VerifiedUserRoundedIcon fontSize="small" />,
          permission: PermissionCodes.Role,
        },
        {
          title: t('Nav_Menu_Users'),
          path: '/admin/users',
          icon: <PeopleRoundedIcon fontSize="small" />,
          permission: PermissionCodes.User,
        },
        {
          title: t('Nav_Menu_ObjectConfiguration'),
          path: '/objectConfiguration',
          icon: <PeopleRoundedIcon fontSize="small" />,
          permission: PermissionCodes.ObjectConfiguration,
        },
      ],
    },
  ], [t]);

  const updateNavStatus = useCallback(
    (navItem) => {
      if (!navItem) {
        return;
      }

      const { children } = navItem;
      if (!Array.isArray(children) || children.length === 0) {
        if (navItem.permission) {
          navItem.isAuthorized = isAuthorized({
            permissionCode: navItem.permission,
            right: navItem.right ?? PermissionRights.Search,
          });
        } else {
          navItem.isAuthorized = true;
        }

        return;
      }

      children.forEach((childNavItem) => updateNavStatus(childNavItem));

      navItem.isAuthorized = children.findIndex((x) => !!x.isAuthorized) >= 0;
    },
    [isAuthorized]
  );

  useEffect(() => {
    sections.forEach((navSection) => {
      if (!Array.isArray(navSection.items)) {
        return;
      }

      navSection.items.forEach((item) => updateNavStatus(item));
      navSection.isAuthorized = navSection.items.findIndex((x) => !!x.isAuthorized) >= 0;
    });

    setAuthorizedSections(sections);
  }, [sections, updateNavStatus]);

  return authorizedSections;
};

export default useNavSections;
