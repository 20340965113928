import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Drawer, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavSection from './NavSection';
import Scrollbar from '../../ui/Scrollbar';
import { openDrawerWidth, closeDrawerWidth, navBarHeight } from './NavBar';
import useNavSections from './SidebarConfig';
import { useEffect, useState } from 'react';
import IF from '../../ui/IF';
import { blurEffectProps } from '../../Cards/Effects/blurEffect';
import Logo from '../../ui/Logo';

const openedMixin = (theme, collapsed,) => ({
  width: collapsed ? closeDrawerWidth : openDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
});

const DrawerHeader = styled('div')(({ theme }) => {
  const styleProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.paper,
    // - Necessary for content to be below app bar
    ...theme.mixins.toolbar,
  };

  if (theme.palette.mode === 'light') {
    styleProps.boxShadow = 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px';
  }

  return styleProps;
});

const SideBarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, collapsed }) => {
  const blurEffectSxProps = { ...blurEffectProps };
  if (theme.palette.mode === 'light') {
    blurEffectSxProps.backgroundColor = 'rgba(255,255,255,0.5)';
  }

  return {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      boxShadow: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.3) 0px 4px 12px' // Light shadow for dark theme
        : 'rgba(0, 0, 0, 0.3) 0px 4px 12px',
      '& .MuiDrawer-paper': { ...openedMixin(theme, collapsed), ...blurEffectSxProps },
    }),
    // ...(!open && {
    //   ...closedMixin(theme),
    //   '& .MuiDrawer-paper': { ...closedMixin(theme, collapsed), backgroundColor: '#212225' },
    // }),
  };
});
export const AccessBVLogoLink = ({ collapsed }) => (
  <Box sx={{ width: '100%' }}>
    <RouterLink to="/">
      <Box
        sx={{
          height: navBarHeight,
          textAlign: 'center',
          '& > img': {
            maxHeight: '100%',
            width: 'auto',
          },
        }}
        p={1}
      >
        <Logo collapsed={collapsed} />
      </Box>
    </RouterLink>
  </Box>
);
const Sidebar = (props) => {
  const { onSidebarClose, isSidebarOpen } = props;
  const { pathname } = useLocation();
  const sections = useNavSections();
  const muiTheme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    setOptions(sections);
  }, [sections, isSidebarOpen]);

  const onNavigateHandler = (path) => {
    if (!lgUp && isSidebarOpen && pathname !== path) {
      onSidebarClose();
    }
  };
  const onClickHandler = () => {
    if (lgUp) {
      setIsOpen(!isOpen);
      setOptions(sections);
    }
  };
  const handleInputChange = (searchedVal) => {
    const filteredData = sections?.map((row) => ({
      ...row,
      items: row.items.filter((x) => x.title.trim().toLowerCase().includes(searchedVal.toLowerCase()))
    }));
    setOptions(filteredData);
  };
  const content = (
    <Box
      sx={{
        flexDirection: 'column',
        borderRight: `1px solid ${muiTheme.palette.divider}`,
        padding: '0px',
        margin: '0px',
        height: '100%',
        // backgroundColor: '#FF00FF00',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto',
        },
      }}
    >
      <Scrollbar>
        <Box px={1}>
          {options.filter((x) => !!x.isAuthorized).map((option) => (
            <NavSection
              collapsed={isSidebarOpen ? 0 : 1}
              key={option.title}
              pathname={pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              onNavigate={onNavigateHandler}
              {...option}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <SideBarDrawer
      open
      variant="permanent"
      collapsed={isSidebarOpen ? 0 : 1}
      sx={{
        width: isSidebarOpen ? openDrawerWidth : closeDrawerWidth,
        display: lgUp || isSidebarOpen ? 'block' : 'none',
        boxShadow: muiTheme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.4) 0px 4px 12px' // Light shadow for dark theme
          : 'rgba(0, 0, 0, 0.3) 0px 4px 12px', // Dark shadow for light theme
      }}
    >
      <DrawerHeader sx={{ borderRight: `1px solid ${muiTheme.palette.divider}` }}>
        <AccessBVLogoLink collapsed={isSidebarOpen ? 0 : 1} />
      </DrawerHeader>

      <Stack alignItems="center" sx={{ borderRight: `1px solid ${muiTheme.palette.divider}` }}>
        {isSidebarOpen && (
        <>
          <IconButton size="small" onClick={onClickHandler}>
            {!isOpen && <KeyboardArrowDownIcon />}
          </IconButton>
          <IF condition={isOpen}>
            <TextField
              autoFocus
              autoComplete="off"
              color="primary"
              onChange={(e) => {
                handleInputChange(e.target.value);
              }}
              variant="standard"
              size="small"
              fullWidth
              sx={{
                p: 0.5,
                pr: 0,
                // '& .MuiOutlinedInput-root': { borderRadius: 1 },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton size="small" onClick={onClickHandler}>
                    {isOpen && <KeyboardArrowUpIcon />}
                  </IconButton>
                ),
              }}
            />
          </IF>
        </>
        )}
      </Stack>
      {content}
    </SideBarDrawer>
  );
};

export default Sidebar;
