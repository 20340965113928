import { combineReducers } from '@reduxjs/toolkit';
import globalDataReducer from '../slices/globalData-slice';
import authRedcer from '../slices/authSlice';
import roleSearchReducer from '../../pages/administrator/role/store/slices/roleSearchSlice';
import requestSearchReducer from '../../pages/RequestSearch/store/slices/requestSearchSlice';
import actionSearchReducer from '../../pages/ActionSearch/store/slices/ActionSearchSlice';
import verificationSearchReducer from '../../pages/Verification/store/slices/VerificationSearchSlice';
import locationSearchReducer from '../../pages/LocationSearch/store/slices/locationSearchSlice';
import dashboardSearchReducer from '../../pages/Dashboard/store/slices/DashboardSlice';
import createRequestReducer from '../../pages/CreateRequest/store/slices/CreateRequestSlice';
import regionManagementReducer from '../../pages/RegionManagement/store/slices/regionManagementSlice';
import rootCauseNameSearchReducer from '../../pages/RootCauseNameSearch/store/slices/rootCauseNameSearchSlice';
import probabilitySearchReducer from '../../pages/ProbabilitySearch/store/slices/probabilitySearchSlice';
import severitySearchReducer from '../../pages/SeverityManagement/store/slices/severitySearchSlice';
import companySearchReducer from '../../pages/CompanySearch/store/slices/companySearchSlice';
import issueTypeSearchReducer from '../../pages/IssueTypeSearch/store/slices/issueTypeSearchSlice';
import countrySearchReducer from '../../pages/CountrySearch/store/slices/countrySearchSlice';
import rootCauseCategorySearchReducer from '../../pages/RootCauseCategorySearch/store/slices/rootCauseCategorySearchSlice';
import requestCategorySourceReducer from '../../pages/RequestCategorySourceFilter/store/slices/requestCategorySourceSlice';
import emailSearchReducer from '../../pages/EmailSearch/store/slices/emailSearchSlice';
import requestCategorySearchReducer from '../../pages/RequestCategorySearch/store/slice/requestCategorySearchSlice';
import mappingSearchReducer from '../../pages/MappingSearch/store/slices/mappingSearchSlice';
import riskRankingReducer from '../../pages/RiskRanking/store/slices/riskRankingSlice';
import userSearchReducer from '../../pages/administrator/user/store/slices/userSearchSlice';
import myActionsReducer from '../../pages/MyActions/store/slices/myActionsSlice';
import listSearchReducer from '../../pages/ListSearch/store/slices/ListSearchSlice';
import objectConfigurationReducer from '../../pages/ObjectConfigurationSearch/store/slices/objectConfigurationSlice';
import announcementsReducer from '../../pages/Announcements/store/slices/AnnouncementsSlice';
import groupsSearchReducer from '../../pages/GroupsSearch/store/slices/GroupsSlice';
import myAnnouncementsSearchReducer from '../../pages/MyAnnouncements/store/slices/MyAnnouncementsSlice';

const rootReducer = combineReducers({
  globalData: globalDataReducer,
  auth: authRedcer,
  roleSearch: roleSearchReducer,
  requestSearch: requestSearchReducer,
  objectConfigSearch: objectConfigurationReducer,
  actionSearch: actionSearchReducer,
  listSearch: listSearchReducer,
  verificationSearch: verificationSearchReducer,
  locationSearch: locationSearchReducer,
  dashboardSearch: dashboardSearchReducer,
  createRequest: createRequestReducer,
  regionManagement: regionManagementReducer,
  rootCauseNameSearch: rootCauseNameSearchReducer,
  probabilitySearch: probabilitySearchReducer,
  severitySearch: severitySearchReducer,
  companySearch: companySearchReducer,
  issueTypeSearch: issueTypeSearchReducer,
  countrySearch: countrySearchReducer,
  rootCauseCategorySearch: rootCauseCategorySearchReducer,
  requestCategorySource: requestCategorySourceReducer,
  emailSearch: emailSearchReducer,
  requestCategorySearch: requestCategorySearchReducer,
  mappingSearch: mappingSearchReducer,
  riskRanking: riskRankingReducer,
  userSearch: userSearchReducer,
  myActions: myActionsReducer,
  announcementsSearch: announcementsReducer,
  groupsSearch: groupsSearchReducer,
  myAnnouncementsSearch: myAnnouncementsSearchReducer,
});
export default rootReducer;
