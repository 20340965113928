// import { CssBaseline, ThemeProvider } from '@mui/material';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
// import SettingsDrawer from "./components/ui/SettingsDrawer";
import routes from './routes';
import useSettings from './hooks/useSettings';
import { createCustomTheme } from './theme';
import RTL from './components/ui/RTL';

import './i18n/i18n';
// import useScrollReset from './hooks/useScrollReset';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialize } from './store/actions/authActions';
import AppCookieConsent from './AppCookieConsent';
import { GlobalModal } from './components/ui/dialogs/GlobalDialog';

function App() {
  const dispatch = useDispatch();
  const content = useRoutes(routes);

  const { settings } = useSettings();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  // useScrollReset();

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {/* <SettingsDrawer /> */}
        <GlobalModal>
          {content}
        </GlobalModal>
        <AppCookieConsent />
      </RTL>
    </ThemeProvider>
  );
}

export default App;
