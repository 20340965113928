import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  RootCauseCategory: '',
  RequestCategory: null,
  Active: true,
};

export const rootCauseCategorySearchSliceName = 'rootCauseCategorySearch';

const rootCauseCategorySearchSlice = createSearchPageSlice({
  sliceName: rootCauseCategorySearchSliceName,
  filterFields,
  initialFilterState: {
    RootCauseCategory: '',
    RequestCategory: null,
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'ListEntryID',
    sortDirection: SortDirection.Asc,
  },
});

export const rootCauseCategorySearchActions = rootCauseCategorySearchSlice.actions;
export default rootCauseCategorySearchSlice.reducer;
