import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const requestCategorySliceName = 'requestCategorySearch';

export const filterFields = {
  RequestCategory: 'RequestCategory',
  DateType: 'DateType',
  EmailType: 'EmailType',
  Active: 'Active',
};

const initialWorkflowDropdownData = {
  dropdownFields: [{
    Field: 'workflowActions',
    Text: 'Actions',
    Required: true,
    ReadOnly: false,
    Visible: true,
    Order: 1,
    Id: 1,
  },
  {
    Field: 'workflowTriggers',
    Text: 'Triggers',
    Required: true,
    ReadOnly: false,
    Visible: true,
    Order: 2,
    Id: 2,
  }]
};

const initialReminderDropdownData = {
  dropdownFields: [
    {
      Field: 'reminderFrequency',
      Text: 'Frequency',
      Required: true,
      ReadOnly: false,
      Visible: true,
      Order: 1,
      Id: 1,
    },
    {
      Field: 'reminderDayCount',
      Text: 'Day Count',
      Required: true,
      ReadOnly: false,
      Visible: true,
      Order: 2,
      Id: 2,
    },
    {
      Field: 'reminderDayType',
      Text: 'Day Type',
      Required: true,
      ReadOnly: false,
      Visible: true,
      Order: 3,
      Id: 3,
    },
    {
      Field: 'reminderScheduleType',
      Text: 'Schedule Type',
      Required: true,
      ReadOnly: false,
      Visible: true,
      Order: 4,
      Id: 4,
    },
    {
      Field: 'dateType',
      Text: 'Date Type',
      Required: true,
      ReadOnly: false,
      Visible: true,
      Order: 5,
      Id: 5,
    },
    {
      Field: 'recipient',
      Text: 'Recipient',
      Required: true,
      ReadOnly: false,
      Visible: true,
      Order: 6,
      Id: 6,
    },
  ]
};

const initialWorkflow = {
  tableData: []
};

const initialReminder = {
  tableData: []
};

const requestCategorySearchSlice = createSearchPageSlice({
  sliceName: requestCategorySliceName,
  filterFields,
  initialFilterState: {
    RequestCategory: null,
    DateType: null,
    EmailType: null,
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RequestCategoryID',
    sortDirection: SortDirection.Asc,
  },
  extraInitialStates: {
    workflowDropdownData: initialWorkflowDropdownData,
    workflowData: initialWorkflow,
    reminderDropdownData: initialReminderDropdownData,
    reminderData: initialReminder,
  },
  extraReducers: {
    updateWorkflowDataValue(state, action) {
      state.workflowData[action.payload.propName] = action.payload.propValue;
    },

    updateWorkflowDataValueFromAPI(state, action) {
      const fields = action.payload.workflowData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.workflowData[keys] = _data;
        } else {
          state.workflowData[keys] = fields[keys];
        }
        return '';
      });
    },

    saveWorkflowDataTableItems(state, action) {
      state.workflowData['tableData'] = [
        ...state.workflowData.tableData,
        action.payload.postArray,
      ];
    },
    updateWorkflowData(state, action) {
      state.workflowData['tableData'] = action.payload;
    },
    updateWorkflowDataTableItems(state, action) {
      state.workflowData['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },
    // RequestCategory workflowData -- Handlers ----> ENDS

    // RequestCategory ReminderData -- Handlers ---->STARTS
    updateReminderDataValue(state, action) {
      state.reminderData[action.payload.propName] = action.payload.propValue;
    },

    updateReminderDataValueFromAPI(state, action) {
      const fields = action.payload.reminderData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.reminderData[keys] = _data;
        } else {
          state.reminderData[keys] = fields[keys];
        }
        return '';
      });
    },

    saveReminderDataTableItems(state, action) {
      state.reminderData['tableData'] = [
        ...state.reminderData.tableData,
        action.payload.postArray,
      ];
    },
    updateReminderData(state, action) {
      state.reminderData['tableData'] = action.payload;
    },
    updateCustomRulesData(state, action) {
      state.reminderData['tableData'] = action.payload;
    },
    updateReminderDataTableItems(state, action) {
      state.reminderData['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },
  }
});

export const requestCategorySearchActions = requestCategorySearchSlice.actions;
export default requestCategorySearchSlice.reducer;
