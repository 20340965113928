import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

const initialFilterState = {
  CompanyName: '',
  CompanyCode: '',
  RegistrationNo: '',
  Country: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CompanyID',
  sortDirection: SortDirection.Asc,
};
export const companySearchSliceName = 'companySearch';

export const filterFields = {
  CompanyName: 'CompanyName',
  CompanyCode: 'CompanyCode',
  RegistrationNo: 'RegistrationNo',
  Country: 'Country',
  Active: 'Active',
};
const companySearchSlice = createSearchPageSlice({
  sliceName: companySearchSliceName,
  filterFields,
  initialFilterState,
  initialPageState
},);

export const companySearchActions = companySearchSlice.actions;
export default companySearchSlice.reducer;
