import { SortDirection } from '../../../../../utils/constants';
import { createSearchPageSlice } from '../../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  FirstName: 'FirstName',
  LastName: 'LastName',
  Email: 'Email',
  Role: 'Role',
  RequestCategory: 'RequestCategory',
  Country: 'Country',
  Region: 'Region',
  Location: 'Location',
  BusinessLine: 'BusinessLine',
  Active: 'Active',
};

export const userSliceName = 'userSearch';

const userSearchSlice = createSearchPageSlice({
  sliceName: userSliceName,
  filterFields,
  initialFilterState: {
    FirstName: '',
    LastName: '',
    Email: '',
    Role: [],
    RequestCategory: [],
    Country: [],
    Region: null,
    Location: [],
    BusinessLine: [],
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'UserID',
    sortDirection: SortDirection.Asc,
  },
});

export const userSearchActions = userSearchSlice.actions;
export default userSearchSlice.reducer;
