import https from 'https-browserify';
import axios from 'axios';
import { getAccessToken, parseJwt } from './jwt';
import apiConfig from '../services/apiConfig';

const refreshToken = async (token) => {
  try {
    const res = await axios.post(`${apiConfig.IAMUrl}/Auth/RefreshToken`, {
      Token: token,
    });

    return {
      accessToken: res.data.AccessToken,
    };
  } catch (error) {
    throw new Error('Refresh Token error');
  }
};

export const getAxiosInstance = ({ baseUrl, addToken = true, ignoreSSL = true }) => {
  const axiosInstance = axios.create();

  if (ignoreSSL) {
    axiosInstance.defaults.httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
  }

  if (baseUrl) {
    axiosInstance.defaults.baseURL = baseUrl;
  }

  if (addToken) {
    axiosInstance.interceptors.request.use((config) => {
      config.headers = {
        Authorization: `Bearer ${getAccessToken()}`,
        // 'Accept': 'application/json',
      };

      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        // console.log(JSON.stringify(error, null, 2));
        // console.log(JSON.stringify(originalRequest, null, 2));
        // console.log(error.response);
        if (
          (error.response.status === 401 || error.response.status === 403) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          const { accessToken } = await refreshToken(getAccessToken());
          const { exp } = parseJwt(accessToken);
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('expirationTime', exp * 1000);
          // console.log(accessToken);
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
};
