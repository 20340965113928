import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  VerificationId: 'VerificationID',
  VerificationStatus: 'VerificationStatus',
  VerificationOwner: 'VerificationOwner',
  ActionId: 'ActionId',
  ActionOwner: 'ActionOwner',
  ActionStatus: 'ActionStatus',
  Location: 'Location',
  RequestNumber: 'RequestNumber',
  RequestStatus: 'RequestStatus',
  RequestManager: 'RequestManager',
  RequestCategory: 'RequestCategory',
  VerificationDateType: 'VerificationDateType',
  From: 'From',
  To: 'To',
  BusinessLine: 'BusinessLine',
  Company: 'Company',
  Country: 'Country',
  Active: 'Active',
};

const initialFilterState = {
  VerificationId: '',
  VerificationStatus: null,
  VerificationOwner: null,
  ActionId: '',
  ActionOwner: null,
  ActionStatus: null,
  Location: null,
  RequestNumber: '',
  RequestStatus: null,
  RequestManager: null,
  RequestCategory: null,
  VerificationDateType: null,
  BusinessLine: null,
  Active: true,
  Company: '',
  Country: '',
  From: '',
  To: '',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'verificationSearch';

const verificationSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const verificationSearchActions = verificationSearchSlice.actions;
export default verificationSearchSlice.reducer;
