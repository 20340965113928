import React, { useState, createContext, useContext, useCallback } from 'react';
import ConfirmationDialog from './ConfirmationDialog';

export const DIALOG_RESPONSE = {
  YES: 'YES',
  NO: 'NO'
};

export const MODAL_TYPES = {
  CONFIRM: 'CONFIRM',
  INFO: 'INFO',
};

const initalState = {
  showModal: () => {},
  hideModal: () => {},
  store: {}
};

const GlobalDialogContext = createContext(initalState);
export const useGlobalDialogContext = () => useContext(GlobalDialogContext);

export const GlobalModal = ({ children }) => {
  const [store, setStore] = useState();
  const { modalType, modalProps } = store || {};
  // modalType is responsible for showing the dialog
  const showModal = useCallback((mType, mProps = {}) => {
    setStore({
      ...store,
      modalType: mType,
      modalProps: mProps
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideModal = useCallback(() => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {}
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    return <ConfirmationDialog modalType={modalType} isOpen={!!modalType} {...modalProps} />;
  };

  return (
    <GlobalDialogContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalDialogContext.Provider>
  );
};
