import { THEMES } from '../../utils/constants';
import useSettings from '../../hooks/useSettings';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import ThemeSwitch from '../ui/ThemeSwitch';
// import { ThemeSwitch } from "../ui/ThemeSwitch";
const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
});

const ThemeIcon = () => {
  const { settings, saveSettings } = useSettings();
  const [selectedTheme, setSelectedTheme] = useState(settings.theme || THEMES.LIGHT);
  const [values, setValues] = useState(getValues(settings));

  const isChecked = selectedTheme === THEMES.DARK;

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleSwitch = () => {
    const newTheme = selectedTheme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;

    setSelectedTheme(newTheme);

    const newValues = {
      ...values,
      theme: newTheme,
    };

    setValues(newValues);
    saveSettings(newValues);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <ThemeSwitch checked={isChecked} onChange={handleSwitch} />
      {/* <IconButton onClick={handleSwitch}>
        {selectedTheme === "LIGHT" ? (
          <Brightness3Icon fontSize="small" />
        ) : (
          <WbSunnyIcon fontSize="small" />
        )}
      </IconButton> */}
    </Box>
  );
};

export default ThemeIcon;
