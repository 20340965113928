import { createSlice } from '@reduxjs/toolkit';
import { parseJwt } from '../../utils/jwt';

const extractUserObjects = (userObjects) => {
  const userObjs = {
    assignedCountries: [],
    assignedRegions: [],
    assignedLocations: [],
    assignedBusinessLines: [],
    assignedRequestCategories: [],
  };

  if (!Array.isArray(userObjects) || userObjects.length === 0) {
    return userObjs;
  }

  const getConfigData = (objectType, valueNumber) => {
    const configData = userObjects.find((x) => x.ObjectType === objectType);
    if (configData && configData.ObjectData) {
      const arr = JSON.parse(configData.ObjectData);
      if (Array.isArray(arr)) {
        if (valueNumber) {
          return arr.map((x) => +x.ID);
        }

        return arr;
      }

      return [];
    }

    return null;
  };

  userObjs.assignedCountries = getConfigData('Country') || [];
  userObjs.assignedRegions = getConfigData('Region') || [];
  userObjs.assignedLocations = getConfigData('Location', true) || [];
  userObjs.assignedBusinessLines = getConfigData('BusinessLine') || [];
  userObjs.assignedRequestCategories = getConfigData('RequestCategory') || [];
  return userObjs;
};

const transformUserFunctionality = (userFunctionalities) => {
  const emptySet = {};
  try {
    if (!Array.isArray(userFunctionalities) || userFunctionalities.length === 0) {
      return emptySet;
    }

    return userFunctionalities.reduce((prev, { Code, Options }) => {
      if (prev) {
        prev[Code.toLowerCase()] = Options;
      }

      return prev;
    }, {});
  } catch (error) {
    console.log(error);
  }

  return emptySet;
};

const initialState = {
  isAuthenticated: false,
  loginUser: null,
  impersonatedUser: null,
  isValidatingToken: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // updateAuthData(state, action) {
    //   Object.keys(initialState).forEach((key) => {
    //     state[key] = action.payload[key];
    //   });
    // },

    updateLoginUser(state, action) {
      const {
        isAuthenticated,
        user,
        token,
        userObjects,
        dateFormat,
        dateTimeFormat,
        tenantList,
        permissions,
        customPermissions,
        roles,
        roleLevel,
      } = action.payload;

      const {
        assignedCountries,
        assignedRegions,
        assignedLocations,
        assignedBusinessLines,
        assignedRequestCategories,
      } = extractUserObjects(userObjects);

      const { uname: username, tenantId } = parseJwt(token) || {};

      state.isAuthenticated = isAuthenticated;
      const permissionSet = transformUserFunctionality(permissions);

      state.loginUser = {
        user,
        username,
        assignedCountries,
        assignedRegions,
        assignedLocations,
        assignedBusinessLines,
        assignedRequestCategories,
        dateFormat,
        dateTimeFormat,
        permissionSet,
        tenantId,
        tenantList,
        customPermissions,
        roles,
        roleLevel
      };
    },
    logout(state) {
      state.isAuthenticated = false;
      state.loginUser = null;
    },
    startImpersonating(state, action) {
      const {
        username,
        user,
        token,
        userObjects,
        dateFormat,
        dateTimeFormat,
        tenantList,
        permissions,
        customPermissions,
        roles,
        roleLevel
      } = action.payload;

      const {
        assignedCountries,
        assignedRegions,
        assignedLocations,
        assignedBusinessLines,
        assignedRequestCategories,
      } = extractUserObjects(userObjects);

      const { tenantId } = parseJwt(token) || {};

      const permissionSet = transformUserFunctionality(permissions);

      state.impersonatedUser = {
        token,
        username,
        dateFormat,
        dateTimeFormat,
        user,
        assignedCountries,
        assignedRegions,
        assignedLocations,
        assignedBusinessLines,
        assignedRequestCategories,
        permissionSet,
        tenantId,
        tenantList,
        customPermissions,
        roles,
        roleLevel
      };

      state.isImpersonateing = true;
    },
    stopImpersonating(state) {
      state.isImpersonateing = false;
      state.impersonatedUser = null;
      localStorage.removeItem('impersonateToken');
    },
    updateAccessToken(state, action) {
      const { tenantId } = parseJwt(action.payload) || {};
      state.loginUser.tenantId = tenantId;
    },
    updateProfileInfo(state, action) {
      const { user } = action.payload;
      state.loginUser.user = user;
      state.loginUser.dateFormat = user.DateFormat;
      state.loginUser.dateTimeFormat = user.DateTimeFormat;
    },

    updateValidatingToken(state, action) {
      state.isValidatingToken = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
