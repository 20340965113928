import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import AuthorizationError from '../../../pages/error/AuthorizationError';

const PermissionGuard = ({ permission, right, children }) => {
  const { isAuthorized } = useAuth();

  const isAllowed = isAuthorized({ permissionCode: permission, right });
  if (!isAllowed) {
    return <AuthorizationError />;
  }

  return <>{children}</>;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
};

export const PermissionRights = {
  Search: 'S',
  Add: 'I',
  Edit: 'M',
  Approve: 'A',
  Export: 'E',
  Delete: 'D',
};

export const PermissionCodes = {
  Impersonate: 'IMP',
  Request: 'request',
  Action: 'action',
  Role: 'role',
  User: 'user',
  Location: 'location',
  Company: 'company',
  Email: 'email',
  Country: 'country',
  IssueType: 'issueType',
  Probability: 'probability',
  Region: 'region',
  RequestCategory: 'requestCategory',
  RequestCategorySource: 'requestCategorySource',
  Risk: 'risk',
  RootCause: 'rootCause',
  RootCauseCategory: 'rootCauseCategory',
  Severity: 'severity',
  Mapping: 'mapping',
  MyActions: 'myactions',
  List: 'list',
  Verification: 'verification',
  MyAnnouncement: 'myAnnouncement',
  ObjectConfiguration: 'objectConfiguration',
  Group: 'Group',
  Announcement: 'Announcement'
};

export const CustomPermissions = {
  CanCancelAutoRequest: 'CanCancelAutoRequest',
  CanCancelOwnRequest: 'CanCancelOwnRequest',
  CanEditRequestField: 'CanEditRequestField',
  CanCancelRequest: 'CanCancelRequest',
  CanImpersonate: 'CanImpersonate',
};

export const roleLevels = {
  SystemAdmin: 10,
  GlobalAdmin: 9,
  LocalAdmin: 8
};

export default PermissionGuard;
