import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  LocationName: 'LocationName',
  LocationType: 'LocationType',
  LocationAbbreviation: 'LocationAbbreviation',
  Alias: 'Alias',
  Zone: 'Zone',
  CountryCode: 'CountryCode',
  Region: 'Region',
  Active: 'Active',
};

const initialFilterState = {
  LocationName: '',
  LocationType: null,
  LocationAbbreviation: '',
  Alias: '',
  Zone: null,
  CountryCode: null,
  Region: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'LocationID',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'locationSearch';
const locationSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const locationSearchActions = locationSearchSlice.actions;
export default locationSearchSlice.reducer;
