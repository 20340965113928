const apiConfig = {
  ApplicationID: process.env.REACT_APP_APPLICATION_ID, // '8cf5e2b1-8bd6-417a-a688-126765c709f9'
  IAMUrl: process.env.REACT_APP_IAM_API_Url,
  PortalAPIUrl: process.env.REACT_APP_Portal_API_Url,
  AppSettingAPIUrl: process.env.REACT_APP_APP_SETTING_API_Url,
  NotificationAPIUrl: process.env.REACT_APP_Notification_API_Url,
  UtilsAPIUrl: process.env.REACT_APP_Utils_API_Url,
  InspectApiUrl: process.env.REACT_APP_INSPECT_API_Url,
};

export default apiConfig;
