import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  RootCauseName: 'RootCauseName',
  RootCauseCategory: 'RootCauseCategory',
  RequestCategory: 'RequestCategory',
  Active: 'Active',
};

export const rootCauseNameSearchSliceName = 'rootCauseNameSearch';

const rootCauseNameSearchSlice = createSearchPageSlice({
  sliceName: rootCauseNameSearchSliceName,
  filterFields,
  initialFilterState: {
    RootCauseName: '',
    RequestCategory: null,
    RootCauseCategory: null,
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'RootCauseID',
    sortDirection: SortDirection.Asc,
  },
});

export const rootCauseNameSearchActions = rootCauseNameSearchSlice.actions;
export default rootCauseNameSearchSlice.reducer;
