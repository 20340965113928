import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './i18n-en';
import frTranslation from './i18n-fr';
import spanishTranslation from './i18n-sp';

const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  es: {
    translation: spanishTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
