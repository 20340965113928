import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AuthApi from '../../../services/api/AuthApi';
import { parseJwt, getAccessToken } from '../../../utils/jwt';
import { authActions } from '../../../store/slices/authSlice';

const TenantList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.auth.loginUser);
  const { tenantId, tenantList } = loginUser || {};

  const onChangeHandler = async (event) => {
    if (!event.target.value) {
      return;
    }
    try {
      const { accessToken } = await AuthApi.changeTenant({
        token: getAccessToken(),
        tenantId: event.target.value,
      });

      dispatch(authActions.updateAccessToken(accessToken));
      const { exp } = parseJwt(accessToken);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('expirationTime', exp * 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel>{t('Label_Tenant')}</InputLabel>
      <Select label={t('Label_Tenant')} value={tenantId} onChange={onChangeHandler}>
        {tenantList &&
          tenantList.map(({ TenantID, TenantName }) => (
            <MenuItem key={`Tenant-${TenantID}`} value={TenantID}>
              {TenantName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default TenantList;
