import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

const initialFilterState = {
  IssueType: '',
  Description: '',

  RequestCategory: null,
  Active: true,
};

export const filterFields = {
  IssueType: 'IssueType',
  RequestCategory: 'RequestCategory',
  Description: 'Description',
  Active: 'Active',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'IssueTypeName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'issueTypeSearch';

const issueTypeSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});
export const issueTypeSearchActions = issueTypeSearchSlice.actions;
export default issueTypeSearchSlice.reducer;
