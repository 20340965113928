import { createSlice } from '@reduxjs/toolkit';

const initialHeaderState = {
  requestTitle: '',
  requestType: null,
  requestCategory: null,
  sourceSystem: null,
  requestReportedBy: '',
  requestManagedBy: '',
  requestApprovedBy: '',
  creationMode: null,
  location: null,
  businessLine: null,
  company: '',
  reportedOn: '',
  createdOn: '',
  dueOn: '',
  approvedOn: '',
  subjectName: '',
  subjectType: null,
  subjectContact: '',
  accreditedEntityCompany: null,
  criticalLocationCompany: null,
  isReceipientPerformingCounty: false,
  isOwnerPerformingOffice: false,
  requestOrigin: null,
  receipientUser: '',
  requestDecision: '',
  scheme: null,
  schemeOther: '',
  accreditationBody: null,
  accreditationBodyOther: '',
  requestReason: null,
  jobName: '',
  businessUnit: '',
  requestActionDescription: ''
};

const initialPTPState = {
  sponsor: '',
  programId: null,
  cycleId: null,
  sampleId: null,
  testMethod: null,
  tableData: []
};

const initialNonConformanceState = {
  sampleNumber: '',
  siteName: '',
  siteCountry: '',
  samplingDate: '',
  supplierName: '',
  tableData: []
};

const initialIssuesidentification = {
  finding: '',
  rootCauseCategory: null,
  rootCauseName: null,
  probabilityRanking: null,
  severityRanking: null,
  riskRanking: ''
};

const initialActions = {
  comments: '',
  tableData: [],
  startedAction: false,
  completedAction: false,
};

const initialEffectiveness = {
  tableData: []
};

const initialLinkRequest = {
  linkData: []
};

const initialAttachment = {
  tableData: []
};

const initialReview = {
  tableData: []
};

const initialAudit = {
  tableData: []
};

const initialErrorState = {
  isError: false
};
const initialState = {
  header: initialHeaderState,
  ptpDetails: initialPTPState,
  issuesIdentification: initialIssuesidentification,
  actionsData: initialActions,
  effectiveness: initialEffectiveness,
  linkRequests: initialLinkRequest,
  attachment: initialAttachment,
  reviewData: initialReview,
  auditData: initialAudit,
  errorState: initialErrorState,
  nonConformanceState: initialNonConformanceState
};

const createRequestSlice = createSlice({
  name: 'createRequest',
  initialState,
  reducers: {

    // CreateRequest HEADER -- Handlers ----> STARTS
    updateHeaderValueFromAPI(state, action) {
      const fields = action.payload.headerData;
      Object.keys(fields).map((key) => {
        const keys = key;
        return state.header[keys] = fields[keys];
      });
    },

    updateHeaderValue(state, action) {
      state.header[action.payload.propName] = action.payload.propValue;
    },
    // CreateRequest HEADER -- Handlers ----> ENDS

    // CreateRequest PTP -- Handlers ----> STARTS
    updatePTPValue(state, action) {
      state.ptpDetails[action.payload.propName] = action.payload.propValue;
      state.nonConformanceState[action.payload.propName] = action.payload.propValue;
    },

    updatePTPDataValueFromAPI(state, action) {
      const fields = action.payload.ptpData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.ptpDetails[keys] = _data;
        } else {
          state.ptpDetails[keys] = fields[keys];
        }
        return '';
      });
    },

    savePTPTableItems(state, action) {
      state.ptpDetails['tableData'] = [...action.payload.postArray];
    },

    updatePTPTableItems(state, action) {
      state.ptpDetails['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },
    // CreateRequest PTP -- Handlers ----> ENDS

    // CreateRequest issueIdentification -- Handlers ----> STARTS
    updateissueIdentificationValueFromAPI(state, action) {
      const fields = action.payload.issueIdentificationData;
      Object.keys(fields).map((key) => {
        const keys = key;
        return state.issuesIdentification[keys] = fields[keys];
      });
    },

    updateIdentificationValue(state, action) {
      state.issuesIdentification[action.payload.propName] = action.payload.propValue;
    },
    // CreateRequest issueIdentification -- Handlers ----> ENDS

    // CreateRequest ACTION -- Handlers ---->STARTS
    updateActionValue(state, action) {
      state.actionsData[action.payload.propName] = action.payload.propValue;
    },

    updateActionValueFromAPI(state, action) {
      const fields = action.payload.actionData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.actionsData[keys] = _data;
        } else {
          state.actionsData[keys] = fields[keys];
        }
        return '';
      });
    },

    saveActionTableItems(state, action) {
      state.actionsData['tableData'] = [...state.actionsData.tableData, action.payload.postArray];
    },
    updateAction(state, action) {
      state.actionsData['tableData'] = action.payload;
    },
    updateActionTableItems(state, action) {
      state.actionsData['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },
    // CreateRequest ACTION -- Handlers ----> ENDS

    // CreateRequest IDENTIFICATION EFFECTIVENESS  -- Handlers ----> STARTS

    updateeffectivenessDataValueFromAPI(state, action) {
      const fields = action.payload.effectivenessData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.effectiveness[keys] = _data;
        } else {
          state.effectiveness[keys] = fields[keys];
        }
        return '';
      });
    },
    saveIdentificationTableItems(state, action) {
      state.effectiveness['tableData'] = [...state.effectiveness.tableData, action.payload.postArray];
    },

    updateIdentificationTableItems(state, action) {
      state.effectiveness['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },
    // CreateRequest IDENTIFICATION EFFECTIVENESS  -- Handlers ----> ENDS

    // CreateRequest ADD-LINKS  -- Handlers ----> STARTS
    addLinkRequest(state, action) {
      state.linkRequests['linkData'] = [...state.linkRequests.linkData, action.payload.requestNumber];
    },

    updateLINKValueFromAPI(state, action) {
      state.linkRequests['linkData'] = [...state.linkRequests.linkData, ...action.payload.linkData.linkRequests];
    },
    // CreateRequest ADD-LINKS  -- Handlers ----> ENDS

    // CreateRequest ATTACHMENTS  -- Handlers ----> STARTS
    updateAttachmentValueFromAPI(state, action) {
      state.attachment['tableData'] = [...state.attachment.tableData, ...action.payload.attachmentData.tableData];
    },

    updateAttachment(state, action) {
      state.attachment['tableData'] = action.payload;
    },

    saveAttachmentTableItems(state, action) {
      state.attachment['tableData'] = [...state.attachment.tableData, action.payload.postArray];
    },

    updateAttachmentTableItems(state, action) {
      state.attachment['tableData'][action.payload.currentIndex] = action.payload.postArray;
    },

    // CreateRequest ATTACHMENTS  -- Handlers ----> ENDS

    // CreateRequest REVIEW -- handlers ----> STARTS
    updateReviewValueFromAPI(state, action) {
      const fields = action.payload.reviewData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.reviewData[keys] = _data;
        }
        return '';
      });
    },
    // CreateRequest REVIEW -- handlers ----> ENDS

    // CreateRequest REVIEW -- handlers ----> STARTS
    updateAuditValueFromAPI(state, action) {
      const fields = action.payload.auditData;
      Object.keys(fields).map((key) => {
        const keys = key;
        if (Array.isArray(fields[keys])) {
          const _data = fields[keys];
          state.auditData[keys] = _data;
        }
        return '';
      });
    },

    updateErrorValue(state, action) {
      state.errorState['isError'] = action.payload.propValue;
    },
    // CreateRequest REVIEW -- handlers ----> ENDS

  },
});

export const createRequestActions = createRequestSlice.actions;
export default createRequestSlice.reducer;
